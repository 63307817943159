import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { SlReload } from "react-icons/sl";
import CommonModal from "./CommonModal";
import Cancel from "../buttons/Cancel";
import Submit from "./../buttons/Submit";
import { useSelector } from "react-redux";
import { Spinner } from "@material-tailwind/react";
import Utility from "../../Utils/Utility";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useModal } from "../../context/modalContext";

// const validationSchema = Yup.object().shape({
//   testOrderName: Yup.string().required("Field is required"),
//   testOrderCode: Yup.string().required("Field is required"),
//   testType: Yup.string().required("Field is required"),
// });

const Testcode = ({ onClose, edit }) => {
  // const { token } = useSelector((state) => state.user);
  // const [showSpin, setShowSpin] = useState(false);
  const { userID } = useModal();

  // const formik = useFormik({
  //   initialValues: {
  //     testOrderName: edit ? userID?.name : "",
  //     testOrderCode: edit ? userID?.code : "",
  //     testType: edit ? userID?.type : "",
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: edit ? handleEditSubmit : handleSubmit,
  // });

  // async function handleEditSubmit(values) {
  //   setShowSpin(true);
  //   let userData = {
  //     _id: userID?._id,
  //     code: values.testOrderCode,
  //     name: values.testOrderName,
  //     type: "SPECIMEN",
  //     status: "active",
  //     is_customer_paid: true,
  //   };
  //   try {
  //     const res = await HttpClient.apiCaller(
  //       "admin/code",
  //       "PUT",
  //       userData,
  //       token,
  //       null
  //     );
  //     console.log(res);
  //     Utility.sToast("TestCode updated successfully");
  //     onClose();
  //     formik.resetForm();
  //   } catch (err) {
  //     console.log(err);
  //     Utility.eToast("Something went wrong");
  //   } finally {
  //     setShowSpin(false);
  //   }
  // }
  // async function handleSubmit(values) {
  //   setShowSpin(true);
  //   let userData = {
  //     code: values.testOrderCode,
  //     name: values.testOrderName,
  //     type: "SPECIMEN",
  //     status: "active",
  //     is_customer_paid: true,
  //   };
  //   try {
  //     const res = await HttpClient.apiCaller(
  //       "admin/code",
  //       "POST",
  //       userData,
  //       token,
  //       null
  //     );
  //     console.log(res);
  //     Utility.sToast("Data posted successfully");
  //     onClose();
  //     // formik.resetForm();
  //   } catch (err) {
  //     console.log(err);
  //     Utility.eToast("Something went wrong");
  //   } finally {
  //     setShowSpin(false);
  //   }
  // }

  const handleReset = () => {
    // formik.resetForm();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    onClose();
  };
  return (
    <CommonModal heading={"Create Test Code"} onClose={onClose}>
      {/* <form onSubmit={formik.handleSubmit}> */}
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col mb-2">
          <label className="mb-1 poppins-medium">Test order name</label>
          <input
            type="text"
            name="testOrderName"
            placeholder="Enter test order name"
            className="w-full h-10 mb-2 rounded bg-[#ECF1F6] p-2 poppins-regular"
            // onChange={formik.handleChange}
            // value={formik.values.testOrderName}
          />
          {/* {formik.errors.testOrderName && formik.touched.testOrderName && (
            <div className="text-red-500 poppins-regular text-sm ml-1">
              {formik.errors.testOrderName}
            </div>
          )} */}
        </div>

        <div className="flex flex-col mb-2">
          <label className="mb-1 poppins-medium">Test order code</label>
          <input
            type="text"
            name="testOrderCode"
            placeholder="Enter test order code"
            className="w-full h-10 mb-2 rounded bg-[#ECF1F6] p-2 poppins-regular"
            // onChange={formik.handleChange}
            // value={formik.values.testOrderCode}
          />
          {/* {formik.errors.testOrderCode && formik.touched.testOrderCode && (
            <div className="text-red-500 poppins-regular text-sm ml-1">
              {formik.errors.testOrderCode}
            </div>
          )} */}
        </div>
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <p className="mb-1 poppins-medium">Test type</p>
          </div>
          <div className="col-span-12">
            <select
              id="countries"
              name="testType"
              // value={formik.values.testType}
              // onChange={formik.handleChange}
              className="bg-[#ECF1F6] border-none focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
            >
              <option selected className="text-[#F3F7FA] bg-[#F3F7FA]"></option>
              <option value="US">United States</option>
              <option value="CA">Canada</option>
              <option value="FR">France</option>
              <option value="DE">Germany</option>
            </select>
          </div>
        </div>
        {/* {formik.errors.testType && formik.touched.testType && (
          <div className="text-red-500 poppins-regular text-sm ml-1">
            {formik.errors.testType}
          </div>
        )} */}

        <div className="flex justify-between mt-6">
          <div>
            {!edit && (
              <button
                // onClick={formik.handleReset}
                className="flex items-center px-2 py-1  poppins-medium border-2  border-gray-400 rounded hover:opacity-80 bg-[#ECF1F6]"
              >
                <SlReload className="mr-1" />
                reset
              </button>
            )}
          </div>
          <div className="flex gap-4 ml-auto">
            <Cancel onClick={onClose} />
            <Submit
            // label={
            //   showSpin ? (
            //     <Spinner color="white" />
            //   ) : edit ? (
            //     "Save Changes"
            //   ) : (
            //     "submit"
            //   )
            // }
            />
          </div>
        </div>
      </form>
    </CommonModal>
  );
};

export default Testcode;
