import { useFormik } from "formik";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import CommonModal from "./CommonModal";
import Cancel from "../buttons/Cancel";
import Reset from "./../buttons/Reset";
import Submit from "./../buttons/Submit";
import { useSelector } from "react-redux";
import { Spinner } from "@material-tailwind/react";
import Utility from "../../Utils/Utility";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { SlReload } from "react-icons/sl";
import { useModal } from "../../context/modalContext";
import ToggleButton from "../buttons/Toggle";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Order Name is required"),
  // clinic: Yup.string().required("Type required"),
  drug_category: Yup.string().required("Code required"),
  // physician: Yup.string().required("Code required"),
});

const DrugPanel = ({ onClose, edit }) => {
  const { userID } = useModal();
  const { token } = useSelector((state) => state.user);
  const [showSpin, setShowSpin] = useState(false);

  const [getclinic, setGetClinic] = useState([]);
  const [getPhysician, setGetPhysician] = useState([]);
  const [drugCat, setDrugCat] = useState([]);

  useEffect(() => {
    clinics();
    physicians();
    categories();
  }, []);

  const clinics = async () => {
    await HttpClient.apiCaller(
      "admin/user-list?role=clinic",
      "GET",
      null,
      token,
      null
    )
      .then((res) => {
        console.log("clinics", res.data.user);
        setGetClinic(res?.data?.user);
      })
      .catch((err) => console.log(err));
  };
  const physicians = async () => {
    await HttpClient.apiCaller(
      "admin/user-list?role=mro",
      "GET",
      null,
      token,
      null
    )
      .then((res) => {
        console.log("physician", res.data.user);
        setGetPhysician(res?.data?.user);
      })
      .catch((err) => console.log(err));
  };
  const categories = async () => {
    await HttpClient.apiCaller("admin/category-get", "GET", null, token, null)
      .then((res) => {
        console.log("category", res);
        setDrugCat(res?.data?.code);
      })
      .catch((err) => console.log(err));
  };

  const formik = useFormik({
    initialValues: {
      name: edit ? userID?.name : "",
      // clinic: edit ? userID?.clinic : "",
      drug_category: edit ? userID?.drug_category : "",
      // physician: edit ? userID?.physician : "",
    },
    validationSchema: validationSchema,
    onSubmit: edit ? handleEditSubmit : handleSubmit,
  });

  async function handleSubmit(values) {
    setShowSpin(true);
    let userData = {
      name: values?.name,
      clinic: values?.clinic,
      drug_category: values?.drug_category,
      physician: values.physician,
    };
    try {
      const res = await HttpClient.apiCaller(
        "admin/drug-panel",
        "POST",
        userData,
        token,
        null
      );
      console.log(res);
      Utility.sToast("Data posted successfully");
      onClose();
      formik.resetForm();
    } catch (err) {
      console.log(err);
      Utility.eToast(err?.response?.data?.err || "Something went wrong");
    } finally {
      setShowSpin(false);
    }
  }

  async function handleEditSubmit(values) {
    setShowSpin(true);
    let userData = {
      _id: userID._id,
      name: values?.name,
      // clinic: values?.clinic,
      drug_category: values?.drug_category,
      // physician: values.physician,
    };
    try {
      const res = await HttpClient.apiCaller(
        "admin/panel-update",
        "PUT",
        userData,
        token,
        null
      );
      console.log(res);
      Utility.sToast("User Updated successfully");
      onClose();
      formik.resetForm();
    } catch (err) {
      console.log(err);
      Utility.eToast("Something went wrong");
    } finally {
      setShowSpin(false);
    }
  }

  const handleReset = () => {
    formik.resetForm();
  };

  return (
    <CommonModal
      heading={edit ? "Edit Drug Panel" : "Add Drug Panel"}
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="mt-9">
          <p className="mb-2 poppins-medium">Panel Name</p>
          <input
            type={"text"}
            placeholder="Enter panel name"
            className="w-full h-10 mb-2 rounded poppins-regular bg-[#ECF1F6] p-2"
            onChange={formik.handleChange}
            name="name"
            value={formik.values.name}
          />
          {formik.errors.name && formik.touched.name && (
            <div className="text-red-500 poppins-regular ml-1 text-sm">
              {formik.errors.name}
            </div>
          )}
          {/* <p className="mb-2 poppins-medium" style={{ marginTop: "0.8rem" }}>
            Select clinic
          </p>
          <select
            id="countries"
            className="custom-input"
            value={formik.values.clinic}
            onChange={formik.handleChange}
            name="clinic"
          >
            <option value="">Select clinic</option>
            {getclinic?.map((item, i) => (
              <>
                <option value={item?._id} key={i}>
                  {item?.clinicName}
                </option>
              </>
            ))}
          </select>
          {formik.errors.clinic && formik.touched.clinic && (
            <div className="text-red-500 poppins-regular ml-1 text-sm">
              {formik.errors.clinic}
            </div>
          )} */}

          <p className="mb-2 poppins-medium" style={{ marginTop: "0.8rem" }}>
            Select drug category
          </p>
          <select
            id="countries"
            className="custom-input"
            value={formik.values.drug_category}
            onChange={formik.handleChange}
            name="drug_category"
          >
            <option value="">Select drug_category</option>
            {drugCat?.map((item, i) => (
              <>
                <option value={item?._id} key={i}>
                  {item?.name}
                </option>
              </>
            ))}
          </select>
          {formik.errors.drug_category && formik.touched.drug_category && (
            <div className="text-red-500 poppins-regular ml-1 text-sm">
              {formik.errors.drug_category}
            </div>
          )}

          {/* <p className="mb-2 poppins-medium" style={{ marginTop: "0.8rem" }}>
            Select physician
          </p>
          <select
            id="countries"
            className="custom-input"
            value={formik.values.physician}
            onChange={formik.handleChange}
            name="physician"
          >
            <option value="">Select physician</option>
            {getPhysician?.map((item, i) => (
              <>
                <option
                  value={item?._id}
                  key={i}
                >{`${item?.first_name} ${item?.last_name}`}</option>
              </>
            ))}
          </select>
          {formik.errors.physician && formik.touched.physician && (
            <div className="text-red-500 poppins-regular ml-1 text-sm">
              {formik.errors.physician}
            </div>
          )} */}
        </div>

        {/* <div className="mt-4 mb-7">
          <ToggleButton onClick={() => { }} />
        </div> */}

        {/* Footer */}
        <div className="flex justify-between mt-3">
          <div>
            {!edit ? (
              <button
                type="button"
                onClick={handleReset}
                className="flex items-center px-2 py-1  poppins-medium border-2  border-gray-400 rounded hover:opacity-80 bg-[#ECF1F6]"
              >
                <SlReload className="mr-1" />
                Reset
              </button>
            ) : (
              <></>
            )}
          </div>
          <div className="flex gap-4 ml-auto">
            <Cancel onClick={onClose} />
            <Submit
              label={
                showSpin ? (
                  <Spinner color="white" />
                ) : edit ? (
                  "Save Changes"
                ) : (
                  "submit"
                )
              }
            />
          </div>
        </div>
      </form>
    </CommonModal>
  );
};

export default DrugPanel;
