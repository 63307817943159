import React from "react";
import { useLocation, Link } from "react-router-dom";
import { ImCancelCircle } from "react-icons/im";
import { useDrug } from "../context/DrugContext";
import { RiMedicineBottleFill } from "react-icons/ri";
import { FaAngleRight, FaClipboardList } from "react-icons/fa";
import { useForm } from "../context/formContext";
import { useForensic } from "../context/FTOContext";

const Sidebar = ({ showSidebar, onClose, data }) => {
  return (
    <div className="">
      {showSidebar ? (
        <div className="fixed top-0 left-0 bottom-0 right-0 bg-slate-600 bg-opacity-50 z-[1000]">
          <div
            className="fixed top-0 left-0 bottom-0 right-0 z[-1]"
            onClick={onClose}
          >
            <div
              className="bg-white flex flex-col items-center p-[2px] min-h-screen fixed pt-6 overflow-y-auto max-h-screen"
              onClick={(e) => e.stopPropagation()}
            >
              <ImCancelCircle
                className="size-4 ml-auto cursor-pointer my-4 mx-2 absolute -top-2 right-2"
                onClick={onClose}
              />
              <SidebarLinks data={data} />
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white mt-[1rem] w-[230px] flex flex-col items-center p-[2px] lg:pt-16 h-full z-[700] fixed xs:hidden lg:block overflow-y-auto max-h-screen">
          <SidebarLinks data={data} />
        </div>
      )}
    </div>
  );
};

const SidebarLinks = ({ data }) => {
  const location = useLocation();
  const { activate, deactivate, showAll } = useDrug();
  const { closeForm } = useForm();

  return (
    <div>
      {data?.map((item, idx) => {
        if (item.label === "drug") {
          return <Drugs key={idx} data={item.content} />;
        } else if (item.label === "Forensic Test Order" && item.content) {
          return <ForensicTestOrder key={idx} data={item.content} />;
        } else {
          return (
            <Link
              onClick={() => {
                deactivate();
                closeForm();
              }}
              to={item.link}
              key={idx}
              className={`w-full flex items-center gap-3 my-2 poppins-regular hover:bg-theme-sidebar hover:text-white tracking-[3%] px-2 py-1 rounded-md ${
                location.pathname === item.link
                  ? "bg-theme-sidebar text-white"
                  : "text-[#364351]"
              }`}
            >
              <span>{item.logo}</span>
              <span>{item.label}</span>
            </Link>
          );
        }
      })}
    </div>
  );
};

const Drugs = ({ data }) => {
  const { activate, deactivate, showAll } = useDrug();
  const { closeForm } = useForm();
  const location = useLocation();

  return (
    <>
      {!showAll ? (
        <button
          className="w-full flex items-center gap-3 my-2 poppins-regular text-gray-600 hover:bg-[#1db6fa] hover:text-white tracking-[3%] px-2 py-1 rounded-md"
          onClick={activate}
        >
          <span>
            <RiMedicineBottleFill />
          </span>
          <span>Drugs</span>
          <FaAngleRight className="ml-auto" />
        </button>
      ) : (
        <>
          <button
            className="w-full flex items-center gap-3 my-2 poppins-regular text-gray-600 tracking-[3%] px-2 py-1 rounded-md"
            onClick={deactivate}
          >
            <span>
              <RiMedicineBottleFill />
            </span>
            <span>Drugs</span>
            <FaAngleRight className="ml-auto rotate-90" />
          </button>
          {data?.map((item, idx) => (
            <Link
              to={item.link}
              key={idx}
              onClick={() => {
                activate();
                closeForm();
              }}
              className={`ml-4 w-[80%] flex items-center gap-3 my-2 poppins-regular hover:bg-theme-sidebar hover:text-white tracking-[3%] px-2 py-1 rounded-md ${
                location.pathname === item.link
                  ? "bg-theme-sidebar text-white"
                  : "text-[#364351]"
              }`}
            >
              <span>{item.logo}</span>
              <span>{item.label}</span>
            </Link>
          ))}
        </>
      )}
    </>
  );
};

const ForensicTestOrder = ({ data }) => {
  const { showForensic, activateForensic, deactivateForensic } = useForensic();
  const { closeForm } = useForm();
  const location = useLocation();

  return (
    <>
      {!showForensic ? (
        <button
          className="w-full flex items-center gap-3 my-2 poppins-regular text-gray-600 hover:bg-[#1db6fa] hover:text-white tracking-[3%] px-2 py-1 rounded-md"
          onClick={activateForensic}
        >
          <span>
            <FaClipboardList />
          </span>
          <span>Forensic Test Order</span>
          <FaAngleRight className="ml-auto" />
        </button>
      ) : (
        <>
          <button
            className="w-full flex items-center gap-3 my-2 poppins-regular text-gray-600 tracking-[3%] px-2 py-1 rounded-md"
            onClick={deactivateForensic}
          >
            <span>
              <FaClipboardList />
            </span>
            <span>Forensic Test Order</span>
            <FaAngleRight className="ml-auto rotate-90" />
          </button>
          {data?.map((item, idx) => (
            <Link
              to={item.link}
              key={idx}
              onClick={() => {
                closeForm();
              }}
              className={`ml-4 w-[80%] flex items-center gap-3 my-2 poppins-regular hover:bg-theme-sidebar hover:text-white tracking-[3%] px-2 py-1 rounded-md ${
                location.pathname === item.link
                  ? "bg-theme-sidebar text-white"
                  : "text-[#364351]"
              }`}
            >
              <span>{item.logo}</span>
              <span>{item.label}</span>
            </Link>
          ))}
        </>
      )}
    </>
  );
};

export default Sidebar;
