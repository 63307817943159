import { HttpClient } from "./HttpClient";

const userLogin = (data) => {
  let url = "admin/login";
  return HttpClient.apiCaller(url, "POST", data, null, null);
};
// const getAdvertisment = () => {
//   let url = "get-modal";
//   return HttpClient.apiCaller(url, "GET", null);
// };

export const UserController = {
  userLogin,
  // getAdvertisment,
};
