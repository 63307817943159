import React, { createContext, useContext, useState } from "react";

const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  const openForm = () => setShowForm(() => true);
  const closeForm = () => setShowForm(() => false);
  const openEditForm = () => setShowEditForm(() => true);
  const closeEditForm = () => setShowEditForm(() => false);

  return (
    <FormContext.Provider
      value={{
        showForm,
        openForm,
        closeForm,
        showEditForm,
        openEditForm,
        closeEditForm,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const useForm = () => useContext(FormContext);
