import React from "react";
import Dashboard from "../../components/Dashboard";
import { BsQrCode } from "react-icons/bs";

const SiteReferQr = () => {
  return (
    <Dashboard>
      <div className="w-full mr-2 h-full bg-white p-5 flex flex-col ">
        <div className="poppins-medium text-xl underline">
          COLLECTION SITE REFER QR CODE
        </div>
        <div className="flex justify-center items-center mt-10">
          <BsQrCode className="size-72" />
        </div>
        <div className="poppins-medium text-lg flex justify-center items-center underline my-10">
          show this qr code to your clinic/collection site
        </div>
        <button className="w-full bg-green-600 text-white py-1 rounded poppins-medium hover:bg-green-700">
          COPY LINK
        </button>
      </div>
    </Dashboard>
  );
};

export default SiteReferQr;
