import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { RiEdit2Line } from "react-icons/ri";
import { useForm } from "../context/formContext";
import { useModal } from "../context/modalContext";

const Table = ({ tableData, tableHeadData }) => {
  const columns = useMemo(() => tableHeadData, [tableHeadData]);
  const data = tableData;
  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnFilters: false,
    paginationDisplayMode: "pages",
    muiPaginationProps: {
      color: "primary",
      shape: "rounded",
      showRowsPerPage: true,
      variant: "outlined",
      rowsPerPageOptions: [5, 10, 15, 20],
    },
    defaultColumn: {
      minSize: 20, //allow columns to get smaller than default
      maxSize: 200, //allow columns to get larger than default
    },
  });

  return <MaterialReactTable table={table} />;
};

export default Table;

// Components to show Edit button inside the table

// component to show the status - Active || Inactive inside the table
export function Badge({ status = "active" }) {
  return (
    <div
      className={`rounded ${
        status === "active"
          ? "bg-green-200 text-green-700"
          : "bg-red-200 text-red-700"
      } poppins-medium text-xs w-[71px] text-center px-2 py-1`}
    >
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </div>
  );
}

export function ActionButton({ icon, action, user }) {
  const { userID, setUserID } = useModal();
  const { openEditForm } = useForm();
  const { openEditModal } = useModal();
  const onClick = () => {
    if (action === "edit") {
      openEditForm();
      openEditModal();
      setUserID(user);
    }
  };
  return (
    <button className=" rounded " onClick={onClick}>
      {/* <img src={icon} alt="icon" className="w-7 h-6" /> */}
      <span>{icon}</span>
    </button>
  );
}
