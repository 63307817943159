import React, { useEffect, useState } from "react";
import Dashboard from "../../components/Dashboard";
import { FaFileDownload, FaPlus } from "react-icons/fa";
import AddButton from "../../components/buttons/AddButton";
import Table from "../../components/Table";
import {
  getForensicTestTableData,
  forensicTestTableHeadData,
} from "../../constants/data/forensicTestData";
import { useForm } from "../../context/formContext";
import { useSelector } from "react-redux";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import ForensicOrderForm from "../../components/forms/ForensicOrderForm";
import ForensicOrderFormEdit from "../../components/forms/ForensicOrderFormEdit";
import MroForensicOrderForm from "../../components/forms/MroForensicOrderForm";

const PhysicForensicTestOrder = () => {
  const {
    showForm,
    openForm,
    closeForm,
    showEditForm,
    openEditForm,
    closeEditForm,
  } = useForm();
  const [forensicTestDetails, setForensicTestDetails] = useState([]);

  const token = useSelector((state) => state?.user?.token);
  useEffect(() => {
    getTestData();
  }, [closeForm, closeEditForm]);
  const getTestData = async () => {
    await HttpClient.apiCaller("admin/test", "GET", null, token, null)
      .then((res) => {
        console.log("//////", res);
        setForensicTestDetails(res?.data?.user);
      })
      .catch((err) => console.log(err));
  };
  return (
    <Dashboard>
      {showForm ? (
        <MroForensicOrderForm onClose={closeForm} />
      ) : showEditForm ? (
        <ForensicOrderFormEdit onClose={closeEditForm} />
      ) : (
        <>
          <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
            <div>
              <p className="poppins-medium text-lg leading-[21px]  text-theme-darkBlue mb-2">
                Manage Forenisc Form{" "}
              </p>
              <p className="poppins-medium text-xs  ">
                <span className="underline text-theme-blue cursor-pointer">
                  Test E-order
                </span>{" "}
                /{" "}
                <span className="underline text-theme-blue cursor-pointer">
                  Manage forensic form
                </span>
              </p>
            </div>
            {/*  */}
            <div className="flex gap-5 xs:flex-col md:flex-row">
              {/* show archive records */}
              <button
                className="poppins-medium ml-auto text-sm text-theme-blue border-theme-blue border-2 p-2  rounded-md flex items-center gap-2 hover:opacity-90"
                // onClick={onClick}
              >
                <FaFileDownload className="size-5 " />
                show archive records
              </button>
              <AddButton
                onClick={openForm}
                buttonName={"Add Forensic Test"}
                icon={<FaPlus className="size-5" />}
              />
            </div>
          </div>
          {/* admins table */}
          <Table
            tableData={getForensicTestTableData(forensicTestDetails)}
            tableHeadData={forensicTestTableHeadData}
          />
        </>
      )}
    </Dashboard>
  );
};

export default PhysicForensicTestOrder;
