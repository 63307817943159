import React, { useState } from "react";
import Dashboard from "../../components/Dashboard";
import { SlArrowLeft, SlReload, SlArrowDown, SlArrowUp } from "react-icons/sl";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import Onoffbutton from "../../components/buttons/ActiveButton";
import { IoIosEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";
import Reset from "../../components/buttons/Reset";
import "../../components/forms/Physician.css";
import Toggle from "../../components/buttons/Toggle";
import Submit from "../../components/buttons/Submit";
import Cancelsmall from "../../components/buttons/Cancelsmall";
// import "./Physician.css";
import Utility from "../../Utils/Utility";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useSelector } from "react-redux";
import PreviousButton from "../../components/buttons/PreviousButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner } from "@material-tailwind/react";
import Constants from "../../Utils/Constants";

const PatientForm = ({ toggle, onClose, isSignUpPage }) => {
  const { token } = useSelector((state) => state.user);
  const [showSpin, setShowSpin] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [countryid, setCountryid] = useState(233);
  const [stateid, setStateid] = useState("");

  const userDetail = useSelector(
    (state) => state?.user?.userInfo?.data?.user?._id
  );

  async function handleSubmit() {
    // e.preventDefault();
    // e.stopPropagation();
    setShowSpin(true);
    let userData = {
      // email: values.email,
      // password: values.password,
      // first_name: values.firstName,
      // last_name: values.lastName,
      // user_type: "physician",
      // mobile_number: values.mobile,
      ...formik?.values,
      user_type: "patient",
      first_name: formik?.values?.firstName,
      last_name: formik?.values?.lastName,
      mobile_number: formik.values?.mobile,
      address: formik?.values?.streetAddress,
      city: formik?.values?.city,
      faxNo: formik?.values?.faxno,
      created_by: userDetail ? userDetail : "",
    };
    console.log("<><><><><>", userData);
    try {
      const res = await HttpClient.apiCaller(
        "register",
        "POST",
        userData,
        token,
        null
      );
      console.log(res);
      Utility.sToast("Patient created successfully");
      // onClose();
      formik.resetForm();
    } catch (err) {
      console.log(err);
      Utility.eToast(err?.data?.response?.err || "Something went wrong");
    } finally {
      setShowSpin(false);
    }
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First name is required")
      .matches(/^[A-Za-z]+$/, "First name must contain only alphabets"),
    // middleName: Yup.string().matches(
    //   /^[A-Za-z]+$/,
    //   "Middle name must contain only alphabets"
    // ),
    lastName: Yup.string()
      .required("Last name is required")
      .matches(/^[A-Za-z]+$/, "Last name must contain only alphabets"),
    faxno: Yup.string().required("Fax No is required"),
    dob: Yup.date().nullable().required("Date of Birth is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    gender: Yup.string().required("Gender is required"),
    mobile: Yup.string()
      .matches(/^[0-9]+$/, "Mobile number must contain only digits")
      .min(10, "Mobile number must be at least 10 digits")
      .max(10, "Mobile number must not exceed 10 digits")
      .required("Mobile number is required"),
    streetAddress: Yup.string().required("Street address is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    // zip: Yup.string().required("ZIP code is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      // middleName: "",
      lastName: "",
      faxno: "",
      dob: "",
      email: "",
      gender: "",
      mobile: "",
      streetAddress: "",
      country: "United States",
      state: "",
      city: "",
      zip: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      await handleSubmit();
      // setSubmitting(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div
        className={`${isSignUpPage ? "" : "sm:p-5 p-2"} bg-white shadow h-full`}
      >
        <div className="flex justify-between">
          {/* heading section */}
          {!isSignUpPage && (
            <div className=" w-full items-center mb-10 flex justify-between">
              <div className="flex gap-2 items-center">
                <PreviousButton onClick={onClose} />
                <div onClick={toggle} className="">
                  <p className="text-[18px] poppins-medium">Create a patient</p>

                  <p className="poppins-medium text-xs  ">
                    <span className="underline  text-theme-blue cursor-pointer">
                      patient
                    </span>{" "}
                    /{" "}
                    <span className="underline  text-theme-blue cursor-pointer">
                      Create a patient
                    </span>
                  </p>
                </div>
              </div>
              <button
                onClick={formik.resetForm}
                className="flex items-center px-2 py-1  poppins-medium border-2  border-gray-400 rounded hover:opacity-80 bg-[#ECF1F6]"
              >
                <SlReload className="mr-1" />
                reset
              </button>
            </div>
          )}
        </div>

        <div className="grid grid-cols-12  gap-4  ">
          <div className="sm:col-span-4 col-span-12 ">
            <p className="poppins-medium">First name</p>
            <input
              type="text"
              placeholder="First name"
              className="h-11 rounded w-full border-none focus:outline-none bg-[#ECF1F6] p-2 font-normal"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.firstName}
              </div>
            )}
          </div>
          {/* <div className="sm:col-span-4 col-span-12">
            <p className="poppins-medium">Middle name</p>
            <input
              type="text"
              placeholder="Middle name"
              className="h-11 rounded w-full border-none focus:outline-none bg-[#ECF1F6] p-2 font-normal"
              name="middleName"
              value={formik.values.middleName}
              onChange={formik.handleChange}
            />
            {formik.touched.middleName && formik.errors.middleName && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.middleName}
              </div>
            )}
          </div> */}
          <div className="sm:col-span-4 col-span-12">
            <p className="poppins-medium">Last name</p>
            <input
              type="text"
              placeholder="Last name"
              className="h-11 rounded w-full border-none focus:outline-none bg-[#ECF1F6] p-2 font-normal"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.lastName}
              </div>
            )}
          </div>
          <div className="sm:col-span-4 col-span-12">
            <p className="poppins-medium">Fax No</p>
            <input
              type="text"
              placeholder="Fax No"
              className="h-11 rounded w-full border-none focus:outline-none bg-[#ECF1F6] p-2 font-normal"
              name="faxno"
              value={formik.values.faxno}
              onChange={formik.handleChange}
            />
            {formik.touched.faxno && formik.errors.faxno && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.faxno}
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="grid grid-cols-12  gap-4 mt-5 ">
            <div className="sm:col-span-3 col-span-12">
              <p className="poppins-medium">Date of birth</p>
              <input
                type="date"
                placeholder="Enter date of birth"
                className="h-11 rounded w-full border-none focus:outline-none bg-[#ECF1F6] p-2 font-normal"
                name="dob"
                value={formik.values.dob}
                onChange={formik.handleChange}
              />
              {formik.touched.dob && formik.errors.dob && (
                <div className="text-red-500 poppins-regular text-sm ml-1">
                  {formik.errors.dob}
                </div>
              )}
            </div>
            <div className="sm:col-span-5 col-span-12">
              <p className="poppins-medium">Email id</p>
              <input
                type="email"
                placeholder="Enter your email id"
                className="w-full h-11 rounded-lg border-none focus:outline-none bg-[#ECF1F6] p-2 font-normal"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="text-red-500 poppins-regular text-sm ml-1">
                  {formik.errors.email}
                </div>
              )}
            </div>
            <div className="sm:col-span-4 col-span-12 grid grid-cols-12 sm:mt-6 items-center gap-3">
              <div className="sm:col-span-5 col-span-12">
                <p className="poppins-medium sm:text-[#9CA5AE] text-black">
                  Select a gender :
                </p>
              </div>
              <div class="sm:col-span-7 col-span-12 text-center items-center sm:ml-[-4vw] ml-[-20vw]">
                <label for="male" className="inline-block mr-2 align-middle">
                  Male
                </label>
                <input
                  type="radio"
                  id="male"
                  name="gender"
                  value="male"
                  className="mr-2 align-middle"
                  onChange={formik.handleChange}
                />
                <label for="female" className="inline-block mr-2 align-middle">
                  Female
                </label>
                <input
                  type="radio"
                  id="female"
                  name="gender"
                  value="Female"
                  className="mr-2 align-middle"
                  onChange={formik.handleChange}
                />
                <label for="others" className="inline-block mr-2 align-middle">
                  Others
                </label>
                <input
                  type="radio"
                  id="others"
                  name="gender"
                  value="Others"
                  onChange={formik.handleChange}
                  className="align-middle"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 mt-5 gap-3 ">
          <div className="sm:col-span-4 col-span-12">
            <p className="poppins-medium">Phone number</p>
            <input
              type="text"
              placeholder="Enter your phone number"
              className="w-full h-11 rounded-lg border-none focus:outline-none bg-[#ECF1F6] p-2 font-normal"
              name="mobile"
              value={formik.values.mobile}
              onChange={formik.handleChange}
            />
            {formik.touched.mobile && formik.errors.mobile && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.mobile}
              </div>
            )}
          </div>
          <div className="sm:col-span-8 col-span-12">
            <p className="poppins-medium">Street Adress</p>
            <input
              type="text"
              placeholder="Enter street adress"
              className="h-11 rounded-lg w-full border-none focus:outline-none bg-[#ECF1F6] p-2 font-normal"
              name="streetAddress"
              value={formik.values.streetAddress}
              onChange={formik.handleChange}
            />
            {formik.touched.streetAddress && formik.errors.streetAddress && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.streetAddress}
              </div>
            )}
          </div>
        </div>

        {/* select country */}
        <div className="grid sm:grid-cols-12 sm:gap-[3vw] gap-2 mt-5">
          <div className="sm:col-span-4 col-span-12">
            <h6 className="poppins-medium mb-1">Country</h6>
            <div className="poppins-medium">
              <CountrySelect
                defaultValue={Constants.usaObject}
                value={formik.values.country}
                onChange={(e) => {
                  formik.setFieldValue("country", e.name);
                  setCountryid(e.id);
                }}
                placeHolder="Select Country"
                showFlag={true}
              />
            </div>
            {formik.touched.country && formik.errors.country && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.country}
              </div>
            )}
          </div>
          {/* select state */}
          <div className="sm:col-span-4 col-span-12">
            <div>
              <h6 className=" mb-1 poppins-medium">State</h6>
              <div className="poppins-medium">
                <StateSelect
                  value={formik.values.state}
                  onChange={(e) => {
                    formik.setFieldValue("state", e.name);
                    setStateid(e.id);
                  }}
                  countryid={countryid}
                  placeHolder="Select State"
                />
              </div>
              {formik.touched.state && formik.errors.state && (
                <div className="text-red-500 poppins-regular text-sm ml-1">
                  {formik.errors.state}
                </div>
              )}
            </div>
          </div>
          {/* select city */}
          <div className="sm:col-span-4 col-span-12">
            <h6 className=" mb-1 poppins-medium">City</h6>
            <div className="poppins-regular">
              <CitySelect
                value={formik.values.city}
                onChange={(e) => {
                  formik.setFieldValue("city", e.name);
                }}
                countryid={countryid}
                stateid={stateid}
                placeHolder="Select City"
              />
            </div>
            {formik.touched.city && formik.errors.city && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.city}
              </div>
            )}
          </div>
        </div>

        <div className="grid sm:grid-cols-12 mt-4 sm:gap-[4vw] mt-5 ">
          <div className="sm:col-span-6 col-span-12 ">
            <p className=" mb-1 poppins-medium">Password</p>
            <div className="flex items-center">
              <input
                type={showPassword ? "text" : "password"}
                className="w-full h-11 border-none focus:outline-none rounded bg-[#ECF1F6] p-2 poppins-regular"
                placeholder="Enter password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />

              {showPassword ? (
                <IoIosEye
                  className="relative sm:ml-[-3vw] ml-[-5vw]"
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <IoMdEyeOff
                  className="relative sm:ml-[-2vw] ml-[-5vw]"
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.password}
              </div>
            )}
          </div>

          {/* confirm password */}
          <div className="sm:col-span-6 col-span-12 ">
            <p className="  mb-1 poppins-medium">Confirm Password</p>
            <div className="flex items-center">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="w-full h-11 rounded border-none focus:outline-none bg-[#ECF1F6] p-2 poppins-regular"
                placeholder="Retry password"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
              />

              {showConfirmPassword ? (
                <IoIosEye
                  className="relative sm:ml-[-2vw] ml-[-5vw]"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              ) : (
                <IoMdEyeOff
                  className="relative sm:ml-[-2vw] ml-[-5vw]"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              )}
            </div>
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <div className="text-red-500 poppins-regular text-sm ml-1">
                  {formik.errors.confirmPassword}
                </div>
              )}
          </div>
        </div>
        {/* footer section*/}
        <div className="grid grid-cols-12 mt-4 gap-2 pb-2">
          <div className="sm:col-span-3 col-span-12 grid grid-cols-12 items-center">
            <div className="col-span-6">
              <p className=" poppins-medium">
                {/* <b className="poppins-medium">Patient is active</b> */}
              </p>
            </div>
            <div className="col-span-6">{/* <Toggle /> */}</div>
          </div>
          <div className="sm:col-span-7 none"></div>
          <div className=" mt-4 sm:gap-3 gap-6 flex sm:justify-start justify-evenly">
            <div>
              <Cancelsmall onClick={onClose} />
            </div>
            <div>
              <button
                type="submit"
                className="px-2 py-1  poppins-medium rounded bg-blue-600 hover:opacity-80 text-white"
              >
                {showSpin ? <Spinner color="white" /> : "submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PatientForm;
