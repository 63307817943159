import React from "react";
import { MdPersonAddAlt1 } from "react-icons/md";

const AddButton = ({ buttonName, onClick, icon, color }) => {
  return (
    <button
      className={`poppins-medium text-sm text-white bg-theme-blue p-2 rounded-md flex items-center gap-2 hover:opacity-90 xs:ml-auto`}
      onClick={onClick}
    >
      {icon}
      {buttonName}
    </button>
  );
};

export default AddButton;


// ${color ? `bg-${color}-400` : 'bg-theme-blue'}