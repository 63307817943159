import React, { useState, useEffect } from "react";
import { ImCancelCircle } from "react-icons/im";
import { FaAngleDown } from "react-icons/fa6";
import userImg from "../assets/user.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clear } from "../Redux/Slice/userSlice";
import UserModal from "./Modals/UserModal";
import Clock from "../components/Modals/Clock";

const Topbar = ({ onClose, showTopbar, user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showUserModal, setShowUSerModal] = useState(false);

  const currentUser = useSelector((state) => state?.user);
  // console.log("[][][][][]", currentUser);

  return (
    <>
      {showTopbar ? (
        <div className="fixed top-0 left-0 bottom-0 right-0 bg-slate-600 bg-opacity-50 z-[1000]">
          <div
            className="fixed top-0 left-0 bottom-0 right-0 z[-1]"
            onClick={onClose}
          >
            <div
              className="w-[250px] h-full z-[1000] fixed bg-white top-0 right-0 pl-2 pt-10 "
              onClick={(e) => e.stopPropagation()}
            >
              <ImCancelCircle
                className="size-4 cursor-pointer absolute top-2 left-2"
                onClick={onClose}
              />

              <div className="flex flex-col gap-5">
                {/* last login */}
                <div className="">
                  <p className="text-sm poppins-regular text-[#919AA2]">
                    Last login :
                  </p>
                  <p className="text-sm poppins-medium text-[#364351]">
                    {user?.lastLogin}
                  </p>
                </div>
                {/* profile details */}
                <div className="flex items-center gap-3">
                  <div className="">
                    <p className="text-base poppins-medium text-theme-darkBlue">
                      {currentUser?.user_type}
                    </p>
                    <p className="text-sm poppins-regular text-gray-600">
                      {currentUser?.email}
                    </p>
                  </div>
                </div>
                <button
                  className="poppins-medium bg-red-500 text-white px-2 py-1 rounded mr-5 mt-5 hover:opacity-80"
                  onClick={() => setShowUSerModal(true)}
                >
                  logout
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white flex xs:p-1 lg:p-2  z-[800] items-center w-full h-14 fixed">
          <div className="ml-auto gap-10 flex">
            <div className="xs:hidden lg:flex items-center ml-5">
              <div className="text-sm poppins-medium text-[#364351] flex gap-2">
                {/* <div className="">{currentDate} </div>
                <div className="w-[6vw]">
                  {currentHour}:{currentMinute}:{currentSecond} {ampm}
                </div> */}
                <Clock />
              </div>
            </div>
            <div
              className="items-center gap-3 xs:hidden lg:flex cursor-pointer"
              onClick={() => setShowUSerModal(true)}
            >
              {currentUser?.profile_image ? (
                <img
                  src={currentUser?.profile_image}
                  className="w-10 h-10 rounded-full"
                  alt="logo"
                />
              ) : (
                <div
                  className="w-10 h-10 rounded-full"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "500",
                    border: "2px solid grey",
                    textTransform: "uppercase",
                  }}
                >
                  {currentUser?.user_type?.slice(0, 2)}
                </div>
              )}
              {/* <img
                src={userImg}
                className="w-10 h-10 rounded-full"
                alt="logo"
              /> */}
              <div>
                <p className="text-base poppins-medium text-theme-darkBlue flex items-center">
                  {currentUser?.user_type}
                  <FaAngleDown className="ml-1" />
                </p>
                <p className="text-sm poppins-regular text-gray-600">
                  {currentUser?.email}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {showUserModal ? (
        <UserModal
          handleSubmit={() => {
            dispatch(clear());
            navigate("/");
          }}
          onClose={() => setShowUSerModal(false)}
        />
      ) : null}
    </>
  );
};

export default Topbar;
