// import { Badge } from "../../components/Table";
// import Edit from "./../../components/buttons/Edit";

// const specimenInfoDetails = [
//   {
//     testName: "Suspected Exposure to covid 19",
//     test: "Z2334",
//     status: "active",
//   },
// ];

// export const specimenInfoTableHeadData = [
//   {
//     accessorKey: "testName",
//     header: "Test Name",
//     size: 200,
//   },
//   {
//     accessorKey: "test",
//     header: "Test",
//     size: 200,
//   },
//   {
//     accessorKey: "status",
//     header: "Status",
//     size: 150,
//   },
//   {
//     accessorKey: "action",
//     header: "Action",
//     size: 150,
//   },
// ];

// export const specimenInfoTableData = specimenInfoDetails.map((item) => {
//   return {
//     testName: item.testName,
//     test: item.test,
//     status: <Badge status={item.status} />,
//     action: <Edit />,
//   };
// });

import { Badge } from "../../components/Table";
import Edit from "./../../components/buttons/Edit";

export const specimenInfoTableHeadData = [
  {
    accessorKey: "sl",
    header: "Sl ",
    size: 50,
  },
  {
    accessorKey: "testName",
    header: "Test Name",
    size: 200,
  },
  {
    accessorKey: "test",
    header: "Test",
    size: 200,
  },
  {
    accessorKey: "status",
    header: "Status",
    size: 150,
  },
  {
    accessorKey: "action",
    header: "Action",
    size: 150,
  },
];

export const getSpecimenInfoTableData = (dataSet) => {
  const tableData = dataSet?.map((item) => {
    return {
      testName: item?.name,
      test: item?.code,
      status: <Badge status={item?.status} />,
      action: <Edit user={item} />,
    };
  });
  return tableData;
};
