import React from "react";

const Submit = ({ onClick, label = "submit" }) => {
  return (
    <button
      type="submit"
      className="px-2 py-1  poppins-medium rounded bg-blue-600 hover:opacity-80 text-white "
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default Submit;
