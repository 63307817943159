import React, { createContext, useContext, useState } from "react";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userID, setUserID] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const openModal = () => setShowModal((prev) => true);
  const closeModal = () => setShowModal((prev) => false);
  const openEditModal = () => setShowEditModal((prev) => true);
  const closeEditModal = () => setShowEditModal((prev) => false);
  const openDeleteModal = () => setShowDeleteModal((prev) => true);
  const closeDeleteModal = () => setShowDeleteModal((prev) => false);

  return (
    <ModalContext.Provider
      value={{
        showEditModal,
        showModal,
        userID,
        openModal,
        openEditModal,
        closeModal,
        closeEditModal,
        setUserID,
        openDeleteModal,
        closeDeleteModal,
        showDeleteModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
