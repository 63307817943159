const testEOrderDetails = [
  {
    patientFirstName: "John",
    patientLastName: "Doe",
    email: "john.doe@example.com",
    createdOn: "Oct 04, 2024 3:05PM",
    collectionDate: "Oct 04, 2024 3:05PM",
    organization: "Hospital A",
    clinic: "Clinic X",
  },
  {
    patientFirstName: "Alice",
    patientLastName: "Smith",
    email: "alice.smith@example.com",
    createdOn: "Oct 05, 2024 3:05PM",
    collectionDate: "Oct 05, 2024 3:05PM",
    organization: "Hospital B",
    clinic: "Clinic Y",
  },
  {
    patientFirstName: "Emma",
    patientLastName: "Johnson",
    email: "emma.johnson@example.com",
    createdOn: "Oct 06, 2024 3:05PM",
    collectionDate: "Oct 06, 2024 3:05PM",
    organization: "Hospital C",
    clinic: "Clinic Z",
  },
  {
    patientFirstName: "Michael",
    patientLastName: "Brown",
    email: "michael.brown@example.com",
    createdOn: "Oct 07, 2024 3:05PM",
    collectionDate: "Oct 07, 2024 3:05PM",
    organization: "Hospital D",
    clinic: "Clinic W",
  },
  {
    patientFirstName: "Sophia",
    patientLastName: "Lee",
    email: "sophia.lee@example.com",
    createdOn: "Oct 08, 2024 3:05PM",
    collectionDate: "Oct 08, 2024 3:05PM",
    organization: "Hospital E",
    clinic: "Clinic V",
  },
];

export const testEorderTableHeadData = [
  {
    accessorKey: "patientFirstName",
    header: "Patient First name",
    size: 100,
  },
  {
    accessorKey: "patientLastName",
    header: "Patient Last name",
    size: 100,
  },
  {
    accessorKey: "email",
    header: "Eamil id",
    size: 250,
  },
  {
    accessorKey: "createdOn",
    header: "Created on",
    size: 100,
  },
  {
    accessorKey: "collectionDate",
    header: "Collection Date",
    size: 100,
  },
  {
    accessorKey: "organization",
    header: "Organization",
    size: 100,
  },
  {
    accessorKey: "clinic",
    header: "Clinic",
    size: 100,
  },
];

export const testEOrderTableData = testEOrderDetails.map((item, idx) => {
  return {
    patientFirstName: item.patientFirstName,
    patientLastName: item.patientLastName,
    email: item.email,
    createdOn: item.createdOn,
    collectionDate: item.collectionDate,
    organization: item.organization,
    clinic: item.clinic,
  };
});
