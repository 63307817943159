import { FaClinicMedical, FaListAlt, FaUserAlt } from "react-icons/fa";
import { RiMedicineBottleFill } from "react-icons/ri";
import {
  MdSpaceDashboard,
  MdMapsHomeWork,
  MdOutlineSchedule,
  MdOutlineQrCodeScanner,
  MdAddHomeWork,
  MdHomeWork,
  MdBloodtype,
  MdOutlineList,
} from "react-icons/md";
import { FaBriefcaseMedical, FaFilePdf } from "react-icons/fa6";
import { PiSuitcaseSimpleThin } from "react-icons/pi";
// import { FaBriefcaseMedical } from "react-icons/fa";
import { FaClipboardList } from "react-icons/fa";
import { FaRegListAlt } from "react-icons/fa";
import { CgNotes } from "react-icons/cg";
import { FaWheelchair } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { FaPersonWalking } from "react-icons/fa6";
import { BsQrCode } from "react-icons/bs";
import { FaPrescriptionBottleAlt } from "react-icons/fa";
import { IoIosWalk } from "react-icons/io";
import { CiViewList, CiMedicalCase } from "react-icons/ci";
import { MdQrCode } from "react-icons/md";
import { GoOrganization } from "react-icons/go";
// import { GoFileSubmodule } from "react-icons/go";
import { FaWalking } from "react-icons/fa";
import { FaPersonWalkingWithCane } from "react-icons/fa6";
import { FaFireFlameCurved } from "react-icons/fa6";
import { RiContrastDrop2Fill } from "react-icons/ri";
import { FiberManualRecordSharp, NoteAddOutlined } from "@mui/icons-material";

export const adminSidebarData = [
  {
    label: "Dashboard",
    link: "/dashboard",
    logo: <MdSpaceDashboard />,
  },
  {
    label: "Admins",
    link: "/admins",
    logo: <FaUserAlt />,
  },
  {
    label: "Technicians",
    link: "/technicians",
    logo: <FaUserAlt />,
  },
  // {
  //   label: "Clinical Scientists",
  //   link: "/clinical-scientists",
  //   logo: <FaUserAlt />,
  // },
  {
    label: "Medical Review Officer",
    link: "/physicians",
    logo: <FaUserAlt />,
  },
  {
    label: "Patients",
    link: "/patients",
    logo: <FaWheelchair />,
  },

  {
    label: "Clinics",
    link: "/clinic",
    logo: <FaClinicMedical />,
  },
  {
    label: "Organizations",
    link: "/organization",
    logo: <MdHomeWork />,
  },
  {
    label: "Clinical Scientists",
    link: "/scientist",
    logo: <FaUserAlt />,
  },
  {
    label: "Collection Site",
    link: "/collection-site",
    logo: <FaPersonWalking />,
  },
  {
    label: "Collector",
    link: "/collector",
    logo: <FaUserAlt />,
  },
  {
    label: "Forensic Test Order",
    content: [
      {
        label: "Non Federal",
        link: "/forensic-test-order",
        logo: <FaClipboardList />,
      },
      {
        label: "Federal",
        link: "/federal-forensic-test-order",
        logo: <FaClipboardList />,
      },
    ],
  },
  // {
  //   label: "Forensic Test Order",
  //   link: "/forensic-test-order",
  //   logo: <FaClipboardList />,
  // },
  {
    label: "drug",
    content: [
      {
        label: "Drug Codes",
        link: "/drug-code",
        logo: <RiMedicineBottleFill />,
      },
      {
        label: "Drug Category",
        link: "/drug-category",
        logo: <RiMedicineBottleFill />,
      },
      {
        label: "Drug Panel",
        link: "/drug-panel",
        logo: <RiMedicineBottleFill />,
      },
    ],
  },
  // {
  //   label: "Test Code",
  //   link: "/test-code",
  //   logo: <BsQrCode />,
  // },
  {
    label: "Specimen Information",
    link: "/specimen-info",
    logo: <FaPrescriptionBottleAlt />,
  },

  // {
  //   label: "Diagnosis ICD-10 Code",
  //   link: "/diagnosis-icd",
  //   logo: <FaPrescriptionBottleAlt />,
  // },
];
export const physicianSidebarData = [
  {
    label: "Dashboard",
    link: "/phyic-dashboard",
    logo: <MdSpaceDashboard />,
  },
  // {
  //   label: "My Forensic QR",
  //   link: "/physic-forensicQR",
  //   logo: <BsQrCode />,
  // },
  {
    label: "Clinic",
    link: "/physic-clinic",
    logo: <FaClinicMedical />,
  },
  {
    label: "Organization",
    link: "/physic-organization",
    logo: <MdHomeWork />,
  },
  // {
  //   label: "Collection Site",
  //   link: "/physic-collection-site",
  //   logo: <FaPersonWalking />,
  // },
  // {
  //   label: "Collector",
  //   link: "/physician-collector",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Technician",
  //   link: "/physician-technician",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Clinical Scientist",
  //   link: "/physician-scientist",
  //   logo: <FaUserAlt />,
  // },
  {
    label: "Patient",
    link: "/physician-patient",
    logo: <FaWheelchair />,
  },
  // {
  //   label: "Test Order",
  //   link: "/physic-test-order",
  //   logo: <FaListAlt />,
  // },
  {
    label: "Forensic Test Order",
    content: [
      {
        label: "Non Federal",
        link: "/mroftop",
        logo: <FaListAlt />,
      },
      {
        label: "Federal",
        link: "/federal-mroftop",
        logo: <FaListAlt />,
      },
    ],
  },
  {
    label: "Forensic Test Order",
    // link: "/physic-forensic-test-order",
    link: "/mroftop",
    logo: <FaListAlt />,
  },
  // {
  //   label: "Schedule New Test",
  //   link: "/physic-schedule-new-test",
  //   logo: <MdOutlineSchedule />,
  // },
];
export const patientSidebarData = [
  {
    label: "Dashboard",
    link: "/patient-dashboard",
    logo: <MdSpaceDashboard />,
  },
  // {
  //   label: "My Result Report",
  //   link: "/patient-result-report",
  //   logo: <FaFilePdf />,
  // },

  // {
  //   label: "Schedule New Test",
  //   link: "/patient-schedule-new-test",
  //   logo: <MdOutlineSchedule />,
  // },
  // {
  //   label: "Demographics Detail QR",
  //   link: "/patient-demographic-qr",
  //   logo: <BsQrCode />,
  // },
  {
    label: "My Test QR Deatils",
    link: "/patient-test-qr-details",
    logo: <MdOutlineQrCodeScanner />,
  },
  // {
  //   label: "My Scheduled Tests",
  //   link: "/patient-scheduled-tests",
  //   logo: <CiViewList />,
  // },
  // {
  //   label: "My DemoGraphics",
  //   link: "/patient-demographic",
  //   logo: <MdOutlineList />,
  // },
  // {
  //   label: "Demographics Detail ",
  //   link: "/patient-demographic",
  //   logo: <MdSpaceDashboard />,
  // },
];
export const clinicSidebarData = [
  {
    label: "Dashboard",
    link: "/clinic-dashboard",
    logo: <MdSpaceDashboard />,
  },
  // {
  //   label: "My Clinc Test QR",
  //   link: "/clinic-test-qr",
  //   logo: <BsQrCode />,
  // },
  // {
  //   label: "My Forensic QR",
  //   link: "/clinic-forensic-qr",
  //   logo: <MdOutlineQrCodeScanner />,
  // },
  // {
  //   label: "My Clinc Refer QR",
  //   link: "/clinic-refer-qr",
  //   logo: <BsQrCode />,
  // },
  // {
  //   label: "Manage Clinic Updated Record",
  //   link: "/clinic-upadated-record",
  //   logo: <MdAddHomeWork />,
  // },
  // {
  //   label: "Custom Drug Panel",
  //   link: "/clinic-custom-drug-panel",
  //   logo: <MdHomeWork />,
  // },
  {
    label: "Medical Review Officer",
    link: "/clinic-manage-mro",
    logo: <FaUserAlt />,
  },
  {
    label: "Patient",
    link: "/clinic-manage-patient",
    logo: <FaWheelchair />,
  },
  {
    label: "Collection Site",
    link: "/clinic-manage-site",
    logo: <FaPersonWalking />,
  },
  // {
  //   label: "Collector",
  //   link: "/clinic-manage-collector",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Colection Site",
  //   link: "/clinic-collection-site",
  //   logo: <IoIosWalk />,
  // },
  // {
  //   label: "Collector",
  //   link: "/clinic-collector",
  //   logo: <IoIosWalk />,
  // },
  // {
  //   label: "Patient",
  //   link: "/clinic-patient",
  //   logo: <FaClipboardList />,
  // },
  // {
  //   label: "Test Eorder",
  //   link: "/clinic-test-eorder",
  //   logo: <FaClipboardList />,
  // },
  // {
  //   label: "Molecular Test Order",
  //   link: "/clinic-molecular-test-order",
  //   logo: <FaClipboardList />,
  // },
  // {
  //   label: "Toxicology Test Order",
  //   link: "/clinic-toxicology-test-order",
  //   logo: <FaClipboardList />,
  // },
  {
    label: "Forensic Test Order",
    content: [
      {
        label: "Non Federal",
        link: "/clinic-forensic-test-order",
        logo: <FaClipboardList />,
      },
      {
        label: "Federal",
        link: "/federal-clinic-forensic-test-order",
        logo: <FaClipboardList />,
      },
    ],
  },
  // {
  //   label: "Forensic Test Order",
  //   link: "/clinic-forensic-test-order",
  //   logo: <FaClipboardList />,
  // },
  // {
  //   label: "Test Center",
  //   link: "/clinic-test-center",
  //   logo: <MdBloodtype />,
  // },
  // {
  //   label: "All Appointments",
  //   link: "/clinic-appointments",
  //   logo: <FaClipboardList />,
  // },
  // {
  //   label: "Schedule New Test",
  //   link: "/clinic-schedule-test",
  //   logo: <MdOutlineSchedule />,
  // },
];
export const collectionSiteSidebarData = [
  {
    label: "Dashboard",
    link: "/site-dashboard",
    logo: <MdSpaceDashboard />,
  },
  // {
  //   label: "My Collection Site Refer QR",
  //   link: "/site-refer-qr",
  //   logo: <BsQrCode />,
  // },
  {
    label: "Patient",
    link: "/site-patient",
    logo: <FaWheelchair />,
  },
  {
    label: "Collector",
    link: "/site-collector",
    logo: <IoIosWalk />,
  },
  // {
  //   label: "Test Center",
  //   link: "/site-test-center",
  //   logo: <MdBloodtype />,
  // },
  // {
  //   label: "All Appointements",
  //   link: "/site-appointments",
  //   logo: <BsQrCode />,
  // },
  // {
  //   label: "Test Order",
  //   link: "/site-test-order",
  //   logo: <FaListAlt />,
  // },
  {
    label: "Forensic Test Order",
    content: [
      {
        label: "Non Federal",
        link: "/site-forensic-test-order",
        logo: <FaListAlt />,
      },
      {
        label: "Federal",
        link: "/federal-site-forensic-test-order",
        logo: <FaListAlt />,
      },
    ],
  },
  // {
  //   label: "Forensic Test Order",
  //   link: "/site-forensic-test-order",
  //   logo: <FaListAlt />,
  // },
  {
    label: "Manuals",
    link: "/manual",
    logo: <NoteAddOutlined />,
  },
  // {
  //   label: "Schedule New Test",
  //   link: "/site-schedule-test",
  //   logo: <MdOutlineSchedule />,
  // },
];

export const collectorSidebarData = [
  {
    label: "Dashboard",
    link: "/collector-dashboard",
    logo: <MdDashboard />,
  },
  // {
  //   label: "Collector Refer Qr",
  //   link: "/collector-refer-qr",
  //   logo: <MdQrCode />,
  // },
  // {
  //   label: "Patient",
  //   link: "/collector-patient",
  //   logo: <FaWheelchair />,
  // },
  {
    label: "Collection site",
    link: "/collector-collection-site",
    logo: <FaPersonWalking />,
  },
  // {
  //   label: "Medical review Officer",
  //   link: "/collector-medical-officer",
  //   logo: <FaUserAlt />,
  // },
  {
    label: "Forensic Test Order",
    content: [
      {
        label: "Non Federal",
        link: "/collector-foreignsic-test-order",
        logo: <FaListAlt />,
      },
      {
        label: "Federal",
        link: "/federal-collector-foreignsic-test-order",
        logo: <FaListAlt />,
      },
    ],
  },
  // {
  //   label: "Forensic Test Order",
  //   link: "/collector-foreignsic-test-order",
  //   logo: <CgNotes />,
  // },
];

export const organizationSidebarData = [
  {
    label: "Dashboard",
    link: "/organization-dashboard",
    logo: <MdSpaceDashboard />,
  },
  // {
  //   label: "My Forensic QR",
  //   link: "/organization-forensic-qr",
  //   logo: <MdOutlineQrCodeScanner />,
  // },
  // {
  //   label: "My Clinc Refer QR",
  //   link: "/organization-refer-qr",
  //   logo: <BsQrCode />,
  // },
  {
    label: "Medical Review Officer",
    link: "/clinic-manage-mro",
    logo: <FaUserAlt />,
  },
  {
    label: "Patient",
    link: "/organization-manage-patient",
    logo: <FaUserAlt />,
  },
  {
    label: "Collection Site",
    link: "/organization-manage-site",
    logo: <FaPersonWalking />,
  },
  // {
  //   label: "Collector",
  //   link: "/organization-manage-collector",
  //   logo: <FaUserAlt />,
  // },
  {
    label: "Forensic Test Order",
    content: [
      {
        label: "Non Federal",
        link: "/organization-forensic-test-order",
        logo: <FaListAlt />,
      },
      {
        label: "Federal",
        link: "/federal-organization-forensic-test-order",
        logo: <FaListAlt />,
      },
    ],
  },
  {
    label: "Manuals",
    link: "/manual",
    logo: <NoteAddOutlined />,
  },
  // {
  //   label: "Forensic Test Order",
  //   link: "/organization-forensic-test-order",
  //   logo: <FaClipboardList />,
  // },
];

export const techniciansSidebarData = [
  {
    label: "Dashboard",
    link: "/technician-dashboard",
    logo: <MdSpaceDashboard />,
  },
  {
    label: "Medical Review Officer",
    link: "/technician-physician",
    // link: /physicians,
    logo: <FaBriefcaseMedical />,
  },
  {
    label: "Patients",
    link: "/technician-patients",
    logo: <FaWheelchair />,
  },
  // {
  //   label: "Organizations",
  //   link: "/technician-organizations",
  //   logo: <GoOrganization />,
  // },
  // {
  //   label: "Clinics",
  //   link: "/technician-clinics",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Employer",
  //   link: "/technician-organizations",
  //   logo: <GoOrganization />,
  // },
  // {
  //   label: "Collection Sites",
  //   link: "/technician-collection-site",
  //   logo: <FaWalking />,
  // },
  // {
  //   label: "Collector",
  //   link: "/technician-collector",
  //   logo: <FaPersonWalkingWithCane />,
  // },

  {
    label: "Forensic Test Order",
    content: [
      {
        label: "Non Federal",
        link: "/ttechnician-forensic-test-order",
        logo: <FaListAlt />,
      },
      {
        label: "Federal",
        link: "/federal-ttechnician-forensic-test-order",
        logo: <FaListAlt />,
      },
    ],
  },
  // {
  //   label: "Forensic Test Order",
  //   link: "/ttechnician-forensic-test-order",
  //   logo: <FaClipboardList />,
  // },
  // {
  //   label: "Deleted Test Order",
  //   link: "/technician-deleted-test-order",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Pending Test Order",
  //   link: "/technician-pending-test-order",
  //   logo: <FaUserAlt />,
  // },
  // //////////////////////////////////////////////////
  // {
  //   label: "Organization Requested Test",
  //   link: "/technician-organization-requested-test",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Physician Requested Test",
  //   link: "/technician-physician-requested-test",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Clinic Requested Test",
  //   link: "/technician-clinic-requested-test",
  //   logo: <FaUserAlt />,
  // },
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Deleted Test Order",
  //   link: "/technician-deleted-test-order",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Pending Test Order",
  //   link: "/technician-pending-test-order",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Scheduler",
  //   link: "/technician-scheduler",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Organization Requested Test",
  //   link: "/technician-organization-requested-test",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Physician Requested Test",
  //   link: "/technician-physician-requested-test",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Clinic Requested Test",
  //   link: "/technician-clinic-requested-test",
  //   logo: <FaUserAlt />,
  // },
  // ///////////////////////////////////////////////
  // {
  //   label: "Export Record",
  //   link: "/technician-export-record",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Excel Record Update",
  //   link: "/technician-excel-record-update",
  //   logo: <FaUserAlt />,
  // },
];

export const scientistSidebarData = [
  {
    label: "Dashboard",
    link: "/scientist-dashboard",
    logo: <MdSpaceDashboard />,
  },
  // {
  //   label: "Medical Review Officer",
  //   link: "/scientist-physician",
  //   logo: <FaBriefcaseMedical />,
  // },
  // {
  //   label: "Patients",
  //   link: "/scientist-patients",
  //   logo: <FaWheelchair />,
  // },
  // {
  //   label: "Organizations",
  //   link: "/scientist-organizations",
  //   logo: <GoOrganization />,
  // },
  // {
  //   label: "Clinics",
  //   link: "/scientist-clinics",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Collection Sites",
  //   link: "/scientist-collection-site",
  //   logo: <FaPersonWalking />,
  // },
  // {
  //   label: "Collector",
  //   link: "/scientist-collector",
  //   logo: <FaPersonWalkingWithCane />,
  // },
  // {
  //   label: "Performed In Office",
  //   link: "/scientist-performed-in-office",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Diagnosis, ICD-10 Code",
  //   link: "/scientist-diagnosis",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Test Code",
  //   link: "/scientist-test-code",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "DRUGS",
  //   link: "/scientist-drugs",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Specimen Information",
  //   link: "/scientist-specimen-information",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Insurance Information",
  //   link: "/scientist-insurance-information",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Result Report PDF",
  //   link: "/scientist-result-report-pdf",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Test EOrder",
  //   link: "/scientist-test-eorder",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Molecular Test Order",
  //   link: "/scientist-molecular-test-order",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Toxicology Test Order",
  //   link: "/scientist-toxicology-test-order",
  //   logo: <FaUserAlt />,
  // },
  {
    label: "Forensic Test Order",
    content: [
      {
        label: "Non Federal",
        link: "/scientist-forensic-test-order",
        logo: <FaListAlt />,
      },
      {
        label: "Federal",
        link: "/federal-scientist-forensic-test-order",
        logo: <FaListAlt />,
      },
    ],
  },
  // {
  //   label: "Forensic Test Order",
  //   link: "/scientist-forensic-test-order",
  //   logo: <FaClipboardList />,
  // },
  // {
  //   label: "Deleted Test Order",
  //   link: "/scientist-deleted-test-order",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Pending Test Order",
  //   link: "/scientist-pending-test-order",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Scheduler",
  //   link: "/scientist-scheduler",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Organization Requested Test",
  //   link: "/scientist-organization-requested-test",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Physician Requested Test",
  //   link: "/scientist-physician-requested-test",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Clinic Requested Test",
  //   link: "/scientist-clinic-requested-test",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Export Record",
  //   link: "/scientist-export-record",
  //   logo: <FaUserAlt />,
  // },
  // {
  //   label: "Excel Record Update",
  //   link: "/scientist-excel-record-update",
  //   logo: <FaUserAlt />,
  // },
];
