import React, { useEffect, useState } from "react";
import Dashboard from "../../components/Dashboard";
import { SlArrowLeft, SlReload, SlArrowDown, SlArrowUp } from "react-icons/sl";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import Onoffbutton from "../buttons/ActiveButton";
import { IoIosEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";
import Reset from "../buttons/Reset";
import Toggle from "../buttons/Toggle";
import Submit from "../buttons/Submit";
import Cancelsmall from "../buttons/Cancelsmall";
import "./Physician.css";
import Utility from "../../Utils/Utility";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useSelector } from "react-redux";
import PreviousButton from "./../buttons/PreviousButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner } from "@material-tailwind/react";
import ToggleComp from "../buttons/Toggle";
import Multiselect from "multiselect-react-dropdown";
import { useNavigate } from "react-router-dom";
import Constants from "../../Utils/Constants";

const PhysicianForm = ({ toggle, onClose, isSignUpPage }) => {
  const { token, user_id } = useSelector((state) => state.user);
  const [showSpin, setShowSpin] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [countryid, setCountryid] = useState(233);
  const [stateid, setStateid] = useState("");
  const [isToggled, setToggled] = useState(false);
  const [clinicData, setClinicData] = useState([]);
  const [selectedClinics, setSelectedClinics] = useState([]);

  const [orgaData, setOrgaData] = useState([]);
  const [selectedOrga, setSelectedOrga] = useState([]);

  const [assoWith, setAssoWith] = useState("clinic");

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setShowSpin(true);
  //     try {
  //       const { data } = await HttpClient.apiCaller(
  //         "admin/user-list?role=clinic",
  //         "GET",
  //         null,
  //         token ? token : null,
  //         null
  //       );
  //       console.log("fetched data", data);
  //       setClinicData(data.user);
  //     } catch (error) {
  //       console.log(".....", error);
  //     } finally {
  //       setShowSpin(false);
  //     }
  //   };

  //   fetchData();

  //   return () => {};
  // }, []);

  const userr = useSelector((state) => state?.user?.user_type);
  // console.log("finalllll", userr);

  useEffect(() => {
    HttpClient.apiCaller("admin/user-list?role=clinic", "GET", null, null, null)
      .then((res) => {
        // console.log(res);
        setClinicData(res?.data?.user);
      })
      .catch((err) => console.log(err));

    HttpClient.apiCaller(
      "admin/user-list?role=organization",
      "GET",
      null,
      null,
      null
    )
      .then((res) => {
        // console.log("NNNNNNNNN", res);
        setOrgaData(res?.data?.user);
      })
      .catch((err) => console.log(err));
  }, [isSignUpPage]);

  const newClinicData = clinicData?.map((item, i) => ({
    clinicName: item?.clinicName,
    id: item?._id,
  }));

  const newOrgaData = orgaData?.map((item, i) => ({
    organizationName: item?.organizationName,
    id: item?._id,
  }));
  // console.log("[][][]", newClinicData);

  const handleToggle = () => {
    setToggled((prev) => !prev);
  };

  const handleSelect = (selectedOption) => {
    const selectedIds = selectedOption.map((item) => item?.id);
    // console.log(">>>>>", selectedIds);
    setSelectedClinics(selectedIds);
  };

  const handleSelect2 = (selectedOption2) => {
    const selectedIds = selectedOption2.map((item) => item?.id);
    console.log(">>>>>", selectedIds);
    setSelectedOrga(selectedIds);
  };

  const userDetails = useSelector(
    (state) => state?.user?.userInfo?.data?.user?._id
  );
  // console.log(">>>>>>>>>>>>>", userDetails);
  const navigate = useNavigate();

  async function handleSubmit(values) {
    setShowSpin(true);
    let userData = {
      ...values,
      user_type: "mro",
      // status: isSignUpPage ? "inactive" : isToggled ? "active" : "inactive",
      // employers: selectedClinics || selectedOrga,
      employers: assoWith === "organization" ? selectedOrga : selectedClinics,
      first_name: values?.firstName,
      last_name: values?.lastName,
      mobile_number: values?.mobile,
      address: values?.streetAddress,
      country: values?.country,
      state: values?.state,
      city: values?.city,
      email: values?.email,
      postal_code: values?.zip,
      password: values?.password,
      npi: values?.npi,
      faxNo: values?.faxNo,
      created_by: userDetails ? userDetails : "",
    };
    console.log("????????", userData);

    try {
      const res = await HttpClient.apiCaller(
        "register",
        "POST",
        userData,
        token ? token : null,
        null
      );
      // console.log("---------->", res);
      Utility.sToast("MRO created successfully");
      // onClose();
      formik.resetForm();
      if (res && window.location.pathname === "/signup") {
        navigate("/");
      }
    } catch (err) {
      console.log(err);
      Utility.eToast(err?.response?.data?.err || "Something went wrong");
    } finally {
      setShowSpin(false);
    }
  }

  const validationSchema = Yup.object().shape({
    npi: Yup.string().required("NPI number / Employer code is required"),
    streetAddress: Yup.string().required("Street address is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    zip: Yup.string()
      .required("Zip code is required")
      .matches(/^\d{5}$/, "Zip code must be exactly 5 digits"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    mobile: Yup.string().required("Mobile number is required").min(10).max(10),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    faxNo: Yup.string().required("Fax No is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const formik = useFormik({
    initialValues: {
      npi: "",
      streetAddress: "",
      country: "United States",
      state: "",
      city: "",
      zip: "",
      email: "",
      mobile: "",
      firstName: "",
      lastName: "",
      faxNo: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });
  return (
    <form
      onSubmit={formik.handleSubmit}
      className={`${isSignUpPage ? "-mt-3" : ""}`}
    >
      <div
        className={`${
          isSignUpPage ? "" : "sm:p-5 p-2"
        }  bg-white shadow h-full`}
      >
        {/* heading section */}
        {!isSignUpPage && (
          <div className="flex justify-between">
            <div>
              <div className="flex gap-2 items-center">
                <PreviousButton onClick={onClose} />
                <div onClick={toggle} className="">
                  <p className="text-[18px] poppins-medium">
                    Create Medical Review Officer
                  </p>

                  <p className="poppins-medium text-xs  ">
                    <span className="underline  text-theme-blue cursor-pointer">
                      Medical Review Officer
                    </span>{" "}
                    /{" "}
                    <span className="underline  text-theme-blue cursor-pointer">
                      Create Medical Review Officer
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <Reset onClick={() => formik.resetForm()} />
            </div>
          </div>
        )}

        {userr === null || userr === "technician" || userr === "admin" ? (
          <>
            <div style={{ marginBottom: "0.5rem" }}>
              Are you associated with{" "}
              <span style={{ margin: "0 2rem" }}>
                <input
                  type="radio"
                  id="huey"
                  name="drone"
                  value="clinic"
                  onChange={(e) => setAssoWith(e.target.value)}
                  checked={assoWith === "clinic"}
                />
                <label for="huey">Clinic</label>
              </span>
              <span style={{ margin: "0 2rem" }}>
                <input
                  type="radio"
                  id="huey"
                  name="drone"
                  value="organization"
                  onChange={(e) => setAssoWith(e.target.value)}
                  checked={assoWith === "organization"}
                />
                <label for="huey">Organization</label>
              </span>
            </div>
          </>
        ) : null}

        {userr === "organization" ? (
          <div
            className={`grid grid-cols-12 sm:gap-[4vw] gap-3 ${
              isSignUpPage ? "" : "mt-3"
            }`}
          >
            <div className="sm:col-span-8 col-span-12">
              <p className="mb-1 poppins-medium">Organization</p>
              <Multiselect
                options={newOrgaData}
                displayValue="organizationName"
                placeholder="Select Employer"
                onSelect={handleSelect2}
              />
            </div>

            {/* select npi number */}
            <div className="sm:col-span-4 relative col-span-12">
              <p className="mb-1 poppins-medium">Employer Code</p>
              <input
                type="text"
                placeholder="Enter Employer Code"
                className="w-full rounded-md p-2 border-none focus:outline-none bg-[#ECF1F6] poppins-regular"
                name="npi"
                value={formik.values.npi}
                onChange={formik.handleChange}
              />
              {formik.touched.npi && formik.errors.npi && (
                <div className="text-red-500 poppins-regular text-sm ml-1">
                  {formik.errors.npi}
                </div>
              )}
            </div>
          </div>
        ) : assoWith === "organization" ? (
          <div
            className={`grid grid-cols-12 sm:gap-[4vw] gap-3 ${
              isSignUpPage ? "" : "mt-3"
            }`}
          >
            <div className="sm:col-span-8 col-span-12">
              <p className="mb-1 poppins-medium">Organization</p>
              <Multiselect
                options={newOrgaData}
                displayValue="organizationName"
                placeholder="Select Employer"
                onSelect={handleSelect2}
              />
            </div>

            {/* select npi number */}
            <div className="sm:col-span-4 relative col-span-12">
              <p className="mb-1 poppins-medium">Employer Code</p>
              <input
                type="text"
                placeholder="Enter Employer Code"
                className="w-full rounded-md p-2 border-none focus:outline-none bg-[#ECF1F6] poppins-regular"
                name="npi"
                value={formik.values.npi}
                onChange={formik.handleChange}
              />
              {formik.touched.npi && formik.errors.npi && (
                <div className="text-red-500 poppins-regular text-sm ml-1">
                  {formik.errors.npi}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            className={`grid grid-cols-12 sm:gap-[4vw] gap-3 ${
              isSignUpPage ? "" : "mt-3"
            }`}
          >
            <div className="sm:col-span-8 col-span-12">
              <p className="mb-1 poppins-medium">Clinic</p>
              <Multiselect
                options={newClinicData}
                displayValue="clinicName"
                placeholder="Select Employer"
                onSelect={handleSelect}
              />
            </div>

            {/* select npi number */}
            <div className="sm:col-span-4 relative col-span-12">
              <p className="mb-1 poppins-medium">NPI number</p>
              <input
                type="text"
                placeholder="Enter Npi number"
                className="w-full rounded-md p-2 border-none focus:outline-none bg-[#ECF1F6] poppins-regular"
                name="npi"
                value={formik.values.npi}
                onChange={formik.handleChange}
              />
              {formik.touched.npi && formik.errors.npi && (
                <div className="text-red-500 poppins-regular text-sm ml-1">
                  {formik.errors.npi}
                </div>
              )}
            </div>
          </div>
        )}

        <div className="grid sm:grid-cols-12  sm:gap-[4vw] gap-2 mt-1">
          <div className="sm:col-span-6 col-span-12 ">
            <p className=" mb-1 poppins-medium">First Name</p>
            <div className="flex items-center">
              <input
                type="text"
                className="w-full h-11 rounded border-none focus:outline-none bg-[#ECF1F6] p-2 poppins-regular"
                placeholder="Enter First name"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
              />
            </div>
            {formik.touched.firstName && formik.errors.firstName && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.firstName}
              </div>
            )}
          </div>
          {/* last name */}
          <div className="sm:col-span-6 col-span-12 ">
            <p className=" mb-1 poppins-medium">Last Name</p>
            <div className="flex items-center">
              <input
                type="text"
                className="w-full h-11 rounded border-none focus:outline-none bg-[#ECF1F6] p-2 poppins-regular"
                placeholder="Enter Last name"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />
            </div>
            {formik.touched.lastName && formik.errors.lastName && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.lastName}
              </div>
            )}
          </div>
        </div>

        {/* select street address */}
        <div className="mt-1">
          <p className=" mb-1 poppins-medium">Street address</p>
          <textarea
            className="w-full p-1 rounded-lg border-none focus:outline-none mt-22 bg-[#ECF1F6] poppins-regular"
            id=""
            cols="15"
            rows="2"
            placeholder="Add your address"
            name="streetAddress"
            value={formik.values.streetAddress}
            onChange={formik.handleChange}
          ></textarea>
          {formik.touched.streetAddress && formik.errors.streetAddress && (
            <div className="text-red-500 poppins-regular text-sm ml-1">
              {formik.errors.streetAddress}
            </div>
          )}
        </div>

        {/* select country */}
        <div className="grid sm:grid-cols-12 sm:gap-[3vw] gap-2 mt-1">
          <div className="sm:col-span-4 col-span-12">
            <h6 className="poppins-medium mb-1">Country</h6>
            <div className="poppins-medium">
              <CountrySelect
                defaultValue={Constants.usaObject}
                value={formik.values.country}
                onChange={(e) => {
                  formik.setFieldValue("country", e.name);
                  setCountryid(e.id);
                }}
                placeHolder="Select Country"
                showFlag={true}
              />
            </div>
            {formik.touched.country && formik.errors.country && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.country}
              </div>
            )}
          </div>

          {/* select state */}
          <div className="sm:col-span-4 col-span-12">
            <div>
              <h6 className=" mb-1 poppins-medium">State</h6>
              <div className="poppins-medium">
                {/* <StateSelect
                  value={formik.values.state}
                  onChange={(e) => {
                    formik.setFieldValue("state", e.name);
                    setStateid(e.id);
                  }}
                  countryid={countryid}
                  placeHolder="Select State"
                /> */}
                <input
                  type="text"
                  value={formik.values.state}
                  onChange={(e) => {
                    formik.setFieldValue("state", e.target.value);
                  }}
                  placeHolder="Select State"
                  className="h-11 w-full rounded border-none focus:outline-none bg-[#ECF1F6] p-2 poppins-regular"
                />
              </div>
              {formik.touched.state && formik.errors.state && (
                <div className="text-red-500 poppins-regular text-sm ml-1">
                  {formik.errors.state}
                </div>
              )}
            </div>
          </div>
          {/* select city */}
          <div className="sm:col-span-4 col-span-12">
            <h6 className=" mb-1 poppins-medium">City</h6>
            <div className="poppins-regular">
              {/* <CitySelect
                value={formik.values.city}
                onChange={(e) => {
                  formik.setFieldValue("city", e.name);
                }}
                countryid={countryid}
                stateid={stateid}
                placeHolder="Select City"
              /> */}
              <input
                type="text"
                value={formik.values.city}
                onChange={(e) => {
                  formik.setFieldValue("city", e.target.value);
                }}
                placeHolder="Select City"
                className="h-11 w-full rounded border-none focus:outline-none bg-[#ECF1F6] p-2 poppins-regular"
              />
            </div>
            {formik.touched.city && formik.errors.city && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.city}
              </div>
            )}
          </div>
        </div>

        {/* first name */}

        {/* select zip code */}
        <div className=" grid sm:grid-cols-12  sm:gap-[2vw] gap-4 mt-1">
          <div className="sm:col-span-4 col-span-12 ">
            <p className=" mb-1 poppins-medium">Zip or postal code</p>
            <input
              type="text"
              placeholder="Zip or postal code"
              className="h-11 w-full rounded border-none focus:outline-none bg-[#ECF1F6] p-2 poppins-regular"
              name="zip"
              value={formik.values.zip}
              onChange={formik.handleChange}
            />
            {formik.touched.zip && formik.errors.zip && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.zip}
              </div>
            )}
          </div>

          {/* mobile no */}
          <div className="sm:col-span-4 col-span-12 ">
            <p className=" mb-1 poppins-medium">Phone</p>
            <input
              type="text"
              className="w-full h-11 border-none focus:outline-none rounded bg-[#ECF1F6] p-2 poppins-regular"
              placeholder="Enter your phone number"
              name="mobile"
              value={formik.values.mobile}
              onChange={formik.handleChange}
            />
            {formik.touched.mobile && formik.errors.mobile && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.mobile}
              </div>
            )}
          </div>
          <div className="sm:col-span-4 col-span-12 ">
            <p className=" mb-1 poppins-medium">Fax No</p>
            <div className="flex items-center">
              <input
                type="text"
                className="w-full h-11 rounded border-none focus:outline-none bg-[#ECF1F6] p-2 poppins-regular"
                placeholder="Enter Fax No"
                name="faxNo"
                value={formik.values.faxNo}
                onChange={formik.handleChange}
              />
            </div>
            {formik.touched.faxNo && formik.errors.faxNo && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.faxNo}
              </div>
            )}
          </div>
        </div>

        <div className="grid sm:grid-cols-12  sm:gap-[2vw] gap-4 mt-1">
          {/* email */}
          <div className="sm:col-span-4 col-span-12 ">
            <p className=" mb-1 poppins-medium">Email</p>
            <input
              type="email"
              className="w-full h-11 border-none focus:outline-none rounded bg-[#ECF1F6] p-2 poppins-regular"
              placeholder="Enter your email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.email}
              </div>
            )}
          </div>

          <div className="sm:col-span-4 col-span-12 ">
            <p className=" mb-1 poppins-medium">Password</p>
            <div className="flex items-center">
              <input
                type={showPassword ? "text" : "password"}
                className="w-full h-11 border-none focus:outline-none rounded bg-[#ECF1F6] p-2 poppins-regular"
                placeholder="Enter password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />

              {showPassword ? (
                <IoIosEye
                  className="relative sm:ml-[-3vw] ml-[-5vw]"
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <IoMdEyeOff
                  className="relative sm:ml-[-2vw] ml-[-5vw]"
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.password}
              </div>
            )}
          </div>

          {/* confirm password */}
          <div className="sm:col-span-4 col-span-12 ">
            <p className="  mb-1 poppins-medium">Confirm Password</p>
            <div className="flex items-center">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="w-full h-11 rounded border-none focus:outline-none bg-[#ECF1F6] p-2 poppins-regular"
                placeholder="Retry password"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
              />

              {showConfirmPassword ? (
                <IoIosEye
                  className="relative sm:ml-[-2vw] ml-[-5vw]"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              ) : (
                <IoMdEyeOff
                  className="relative sm:ml-[-2vw] ml-[-5vw]"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              )}
            </div>
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <div className="text-red-500 poppins-regular text-sm ml-1">
                  {formik.errors.confirmPassword}
                </div>
              )}
          </div>
        </div>

        {/* footer section*/}
        <div className="grid grid-cols-12  gap-2 ">
          {!isSignUpPage && (
            <div className="sm:col-span-6 col-span-12 grid grid-cols-12 items-center">
              <div className="sm:col-span-4 col-span-6">
                <p className=" poppins-medium">
                  <b className="poppins-medium">
                    {/* Physician is {isToggled ? "active" : "not active"} */}
                  </b>
                </p>
              </div>
              <div className="sm:col-span-4 col-span-6">
                {/* <ToggleComp isToggled={isToggled} handleToggle={handleToggle} /> */}
              </div>
            </div>
          )}

          {isSignUpPage ? (
            <div className=" mt-2 flex justify-end gap-2 mb-2 col-span-12">
              <div>
                <Cancelsmall onClick={onClose} />
              </div>
              <div className="col-span-6">
                <button
                  type="submit"
                  className="px-2 py-1  poppins-medium rounded bg-blue-600 hover:opacity-80 text-white"
                >
                  {showSpin ? <Spinner color="white" /> : "submit"}
                </button>
              </div>
            </div>
          ) : (
            <div className=" mt-4  gap-4 flex sm:justify-end justify-around sm:col-span-6 col-span-12">
              <div>
                <Cancelsmall onClick={onClose} />
              </div>
              <div>
                <button
                  type="submit"
                  className="px-2 py-1  poppins-medium rounded bg-blue-600 hover:opacity-80 text-white"
                >
                  {showSpin ? <Spinner color="white" /> : "submit"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default PhysicianForm;
