import React from "react";
import { SlReload } from "react-icons/sl";

const Reset = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center px-2 py-1  poppins-medium border-2  border-gray-400 rounded hover:opacity-80 bg-[#ECF1F6]"
    >
      <SlReload className="mr-1" />
      reset
    </button>
  );
};

export default Reset;
