import AddButton from "../../components/buttons/AddButton";
import Dashboard from "../../components/Dashboard";
import Table, { Badge } from "../../components/Table";
import { MdPersonAddAlt1 } from "react-icons/md";
import {
  // getClinicTableData,
  clinicTableHeadData,
} from "../../constants/data/clinicData";
import AddClinic from "../../components/forms/AddClinic";
import { useForm } from "../../context/formContext";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ClinicModal from "../../components/Modals/ClinicModal";
import { useModal } from "../../context/modalContext";
import { Spinner } from "@material-tailwind/react";
import Edit from "../../components/buttons/Edit";
import Delete from "../../components/buttons/DeleteButton";
import DeleteUserModal from "../../components/Modals/DeleteUserModal";

const ClinicPage = () => {
  const { showForm, openForm, closeForm } = useForm();
  const {
    showEditModal,
    showModal,
    openModal,
    closeModal,
    closeEditModal,
    openDeleteModal,
    closeDeleteModal,
    showDeleteModal,
  } = useModal();
  const [showSpin, setShowSpin] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [clinicTableData, setClinicTableData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      setShowSpin(true);
      try {
        const { data } = await HttpClient.apiCaller(
          "admin/user-list?role=clinic",
          "GET",
          null,
          token,
          null
        );
        // console.log("//////////////////", data.user);
        setClinicTableData(data.user);
      } catch (error) {
        console.log(error);
      } finally {
        setShowSpin(false);
      }
    };

    fetchData();

    return () => {};
  }, [closeForm, closeEditModal]);
  const getClinicTableData = (dataSet) => {
    const tableData = dataSet.map((item, idx) => {
      return {
        no: idx + 1,
        uid: item?.uid,
        clinicName: item?.clinicName,
        email: item?.email,
        mobile: item?.mobile_number,
        npi: item?.npi || "N/A",
        // status: <Badge status={item?.status} />,
        action: (
          <div className="flex gap-3">
            <Edit user={item} />
            <Delete user={item} />
          </div>
        ),
      };
    });
    return tableData;
  };
  return (
    <Dashboard>
      {showForm ? (
        <AddClinic onClose={closeForm} />
      ) : (
        <>
          {" "}
          <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
            <div>
              <p className="poppins-medium  leading-[21px]  text-gray-700 mb-2">
                List of Clinics
              </p>
              <p className="poppins-regular text-xs text-[#B5B5C3] ">
                {clinicTableData?.length} available Clinics
              </p>
            </div>
            {/*  */}
            <AddButton
              onClick={openForm}
              buttonName={"Add New Clinic"}
              icon={<MdPersonAddAlt1 className="size-5" />}
            />
          </div>
          {/*  table */}
          {showSpin ? (
            <div className="w-full mt-20 flex items-center justify-center">
              <Spinner className="h-12 w-12" />{" "}
            </div>
          ) : (
            <Table
              tableData={getClinicTableData(clinicTableData)}
              tableHeadData={clinicTableHeadData}
            />
          )}
        </>
      )}
      {/*Edit modal */}
      {showEditModal ? (
        <ClinicModal edit={true} onClose={closeEditModal} />
      ) : (
        <></>
      )}
      {/* delete modal */}
      {showDeleteModal && <DeleteUserModal onClose={closeDeleteModal} />}
    </Dashboard>
  );
};

export default ClinicPage;
