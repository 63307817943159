// import React, { useState } from "react";
// import { SlArrowDown } from "react-icons/sl";
// import ToggleButton from "../buttons/ToggleButton";
// import Reset from "../buttons/Reset";
// import Cancel from "../buttons/Cancel";
// import Submit from "../buttons/Submit";
// import CustomerPaid from "../buttons/CustomerPaid";
// import CommonModal from "./CommonModal";
// import { Spinner } from "@material-tailwind/react";

// const Specimencode = ({ onClose, edit }) => {
//   const [showSpin, setShowSpin] = useState(false);
//   const [input, setInput] = useState({
//     ordername: "",
//     ordercode: "",
//   });
//   const [data, setData] = useState([]);
//   const [showhide, setShowhide] = useState(false);
//   const handleshowhide = () => {
//     setShowhide(!showhide);
//   };
//   const handlechange = (e) => {
//     setInput({ ...input, [e.target.name]: e.target.value }); // Spread previous state and update property
//   };

//   const handleSubmit = () => {
//     const newData = [...data, input];
//     setData(newData);
//     console.log(newData);
//   };

//   return (
//     <CommonModal
//       onClose={onClose}
//       edit={edit}
//       handleSubmit={handleSubmit}
//       heading={"Create Specimen code"}
//     >
//       <div className="mt-9">
//         <p className="mb-2 poppins-regular">Specimen code name</p>
//         <input
//           type={showhide ? "password" : "text"}
//           placeholder="Enter specimen code name"
//           className="w-full h-10 mb-2 rounded poppins-regular bg-[#ECF1F6] p-2"
//           onChange={handlechange}
//           name="ordername" // Add name attribute
//           value={input.ordername}
//         />
//         <p className="mb-2 poppins-regular">Test code</p>
//         <input
//           type={showhide ? "password" : "text"}
//           placeholder="Enter test name"
//           className="w-full h-10 mb-2 rounded poppins-regular bg-[#ECF1F6] p-2"
//           onChange={handlechange}
//           name="ordername" // Add name attribute
//           value={input.ordercode}
//         />
//       </div>

//       <div className="mt-4 mb-7">
//         <ToggleButton onClick={() => handleshowhide()} />
//       </div>
//       <div className="flex justify-between mt-3">
//         <div>{!edit ? <Reset /> : <></>}</div>
//         <div className="flex gap-4 ml-auto">
//           <Cancel onClick={onClose} />
//           <Submit
//             label={
//               edit ? (
//                 "Save Changes"
//               ) : showSpin ? (
//                 <Spinner color="white" />
//               ) : (
//                 "Submit"
//               )
//             }
//           />
//         </div>
//       </div>
//     </CommonModal>
//   );
// };

// export default Specimencode;
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import CommonModal from "./CommonModal";
import Cancel from "../buttons/Cancel";
import Reset from "./../buttons/Reset";
import Submit from "./../buttons/Submit";
import { useSelector } from "react-redux";
import { Spinner } from "@material-tailwind/react";
import Utility from "../../Utils/Utility";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { SlReload } from "react-icons/sl";
import { useModal } from "../../context/modalContext";
import ToggleButton from "../buttons/Toggle";

const validationSchema = Yup.object().shape({
  orderName: Yup.string()
    .required("Specimein code is required"),
  orderCode: Yup.string().required("Test code is required"),
});

const Specimencode = ({ onClose, edit }) => {
  const { userID } = useModal();

  const { token } = useSelector((state) => state.user);
  const [showSpin, setShowSpin] = useState(false);

  const formik = useFormik({
    initialValues: {
      orderName: edit ? userID?.name : "",
      orderCode: edit ? userID?.code : "",
    },
    validationSchema: validationSchema,
    onSubmit: edit ? handleEditSubmit : handleSubmit,
  });

  async function handleSubmit(values) {
    setShowSpin(true);
    let userData = {
      name: values?.orderName,
      code: values?.orderCode,
      type: "SPECIMEN",
    };
    try {
      const res = await HttpClient.apiCaller(
        "admin/code",
        "POST",
        userData,
        token,
        null
      );
      console.log(res);
      Utility.sToast("Data posted successfully");
      onClose();
      formik.resetForm();
    } catch (err) {
      console.log(err);
      Utility.eToast(err?.response?.data?.err || "Something went wrong");
    } finally {
      setShowSpin(false);
    }
  }

  async function handleEditSubmit(values) {
    setShowSpin(true);
    let userData = {
      _id: userID._id,
      name: values?.orderName,
      code: values?.orderCode,
    };
    try {
      const res = await HttpClient.apiCaller(
        "admin/code",
        "PUT",
        userData,
        token,
        null
      );
      console.log(res);
      Utility.sToast("User Updated successfully");
      onClose();
      formik.resetForm();
    } catch (err) {
      console.log(err);
      Utility.eToast("Something went wrong");
    } finally {
      setShowSpin(false);
    }
  }

  const handleReset = () => {
    formik.resetForm();
  };

  return (
    <CommonModal
      heading={edit ? "Edit Specimen" : "Create a Specimen Code"}
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="mt-9">
          <p className="mb-2 poppins-medium">Specimen code name</p>
          <input
            type={"text"}
            placeholder="Enter specimen code name"
            className="w-full h-10 mb-2 rounded poppins-regular bg-[#ECF1F6] p-2"
            onChange={formik.handleChange}
            name="orderName"
            value={formik.values.orderName}
          />
          {formik.errors.orderName && formik.touched.orderName && (
            <div className="text-red-500 poppins-regular ml-1 text-sm">
              {formik.errors.orderName}
            </div>
          )}
          <p className="mb-2 poppins-medium">Test code</p>
          <input
            type={"text"}
            placeholder="Enter test name"
            className="w-full h-10 mb-2 rounded poppins-regular bg-[#ECF1F6] p-2"
            onChange={formik.handleChange}
            name="orderCode"
            value={formik.values.orderCode}
          />
          {formik.errors.orderCode && formik.touched.orderCode && (
            <div className="text-red-500 poppins-regular ml-1 text-sm">
              {formik.errors.orderCode}
            </div>
          )}
        </div>

        {/* <div className="mt-4 mb-7">
          <ToggleButton onClick={() => { }} />
        </div> */}

        {/* Footer */}
        <div className="flex justify-between mt-3">
          <div>
            {!edit ? (
              <button
                onClick={formik.handleReset}
                className="flex items-center px-2 py-1  poppins-medium border-2  border-gray-400 rounded hover:opacity-80 bg-[#ECF1F6]"
              >
                <SlReload className="mr-1" />
                reset
              </button>
            ) : (
              <></>
            )}
          </div>
          <div className="flex gap-4 ml-auto">
            <Cancel onClick={onClose} />
            <Submit
              label={
                showSpin ? (
                  <Spinner color="white" />
                ) : edit ? (
                  "Save Changes"
                ) : (
                  "submit"
                )
              }
            />
          </div>
        </div>
      </form>
    </CommonModal>
  );
};

export default Specimencode;
