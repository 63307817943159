import React, { useEffect, useState } from "react";
import Dashboard from "../../components/Dashboard";
import AddButton from "../../components/buttons/AddButton";
import { IoMdAdd } from "react-icons/io";
import {
  getPatientTableData,
  patientTableHeadData,
} from "../../constants/data/patientData";
import Table from "../../components/Table";
import { Spinner } from "@material-tailwind/react";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useForm } from "../../context/formContext";
import { useSelector } from "react-redux";
import { useModal } from "../../context/modalContext";

const SiteTestOrder = () => {
  const { showForm, openForm, closeForm } = useForm();
  const [showSpin, setShowSpin] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [patientTableData, setPatientTableData] = useState([]);
  const { showEditModal, closeEditModal } = useModal();
  useEffect(() => {
    const fetchData = async () => {
      setShowSpin(true);
      try {
        const { data } = await HttpClient.apiCaller(
          "admin/user-list?role=patient",
          "GET",
          null,
          token,
          null
        );
        console.log(data?.user);
        setPatientTableData(data?.user);
      } catch (error) {
        console.log(error);
      } finally {
        setShowSpin(false);
      }
    };

    fetchData();

    return () => {};
  }, [closeForm, closeEditModal]);
  return (
    <Dashboard>
      <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
        <div>
          <p className="poppins-medium text-lg leading-[21px]  text-theme-darkBlue mb-2">
            Manage Test Order
          </p>
          <p className="poppins-medium text-xs  ">
            <span className="underline  text-theme-blue cursor-pointer">
              Dashboard
            </span>{" "}
            /{" "}
            <span className="underline  text-theme-blue cursor-pointer">
              Manage Test Order
            </span>
          </p>
        </div>
        {/*  */}

        <AddButton
          //   onClick={openForm}
          buttonName={"Request New Test"}
          icon={<IoMdAdd className="size-5" />}
        />
      </div>
      {/*  table */}
      {showSpin ? (
        <div className="w-full mt-20 flex items-center justify-center">
          <Spinner className="h-12 w-12" />{" "}
        </div>
      ) : (
        <Table
          tableData={getPatientTableData(patientTableData)}
          tableHeadData={patientTableHeadData}
        />
      )}
    </Dashboard>
  );
};

export default SiteTestOrder;
