import React, { useEffect, useState } from "react";
import Card from "../../components/Card";
import Chart from "../../components/Chart";
import Table from "../../components/Table";
import Dashboard from "../../components/Dashboard";
import { Spinner } from "@material-tailwind/react";
import { useModal } from "../../context/modalContext";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { getAdminTableData } from "../../constants/data/adminsData";
import { adminTableHeadData } from "./../../constants/data/adminsData";
import { useSelector } from "react-redux";
import Admin from "../../components/Modals/Admin";
import UseAllUsersHook from "../../components/UseAllUsersHook";
import dashboardData from "../../constants/data/dashboardData";
import { useNavigate } from "react-router-dom";
// import { cardData } from "../../constants/data/dashboardData";

// const monthList = [
//   "JAN",
//   "FEB",
//   "MAR",
//   "APR",
//   "MAY",
//   "JUN",
//   "JUL",
//   "AUG",
//   "SEP",
//   "OCT",
//   "NOV",
// ];

const DashboardPage = () => {
  const { showEditModal, closeEditModal } = useModal();
  const [showSpin, setShowSpin] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [adminTableData, setAdminTableData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setShowSpin(true);
      try {
        const { data } = await HttpClient.apiCaller(
          "admin/user-list?role=admin",
          "GET",
          null,
          token,
          null
        );
        // console.log("admin data", data.user);
        setAdminTableData(data.user);
      } catch (error) {
        console.log(error);
      } finally {
        setShowSpin(false);
      }
    };

    fetchData();

    return () => {};
  }, [closeEditModal]);

  const { spin, setSpin } = UseAllUsersHook();
  const cardData = dashboardData();

  const handleCardClick = (label) => {
    if (label === "All Admin") {
      navigate("/admins");
    } else if (label === "All Patient") {
      navigate("/patients");
    } else if (label === "All Organization") {
      navigate("/organization");
    } else if (label === "All Clinics") {
      navigate("/clinic");
    } else if (label === "All Collectors") {
      navigate("/collector");
    } else if (label === "All Collection Site ") {
      navigate("/collection-site");
    } else if (label === "All Technician") {
      navigate("/technicians");
    } else if (label === "All Clinical Scientist") {
      navigate("/scientist");
    } else if (label === "All Medical Review Officer") {
      navigate("/physicians");
    } else if (label === "All Forensic Test Order") {
      navigate("/forensic-test-order");
    }
  };

  return (
    <Dashboard>
      {spin ? (
        <div
          style={{
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <svg
            aria-hidden="true"
            class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </div>
      ) : (
        <>
          <div
            className="grid lg:grid-cols-4 xs:grid-cols-2 sm:grid-cols-3 w-full gap-2"
            // className="xs:flex xs:flex-wrap  md:grid-cols-3 md:gap-2  lg:grid-cols-4 lg:gap-3   "
          >
            {cardData &&
              cardData.map((item, idx) => {
                return (
                  <Card
                    key={idx}
                    count={item.count}
                    label={item.label}
                    img={item.icon}
                    // onClick={
                    //   item?.label === "All Admin"
                    //     ? () => navigate("/dashboard")
                    //     : ""
                    // }
                    onClick={() => handleCardClick(item?.label)}
                  />
                );
              })}
          </div>
          {/* graphs */}
          <div className="grid   mt-5 xs:grid-rows-2  xs:gap-4 md:grid-cols-2 md:grid-rows-1 md:gap-2">
            {/*active patients graph  */}
            {/* <Chart
          content={"Active patients graph"}
          labels={monthList}
          label1={"New Patients"}
          label2={"Active Patients"}
          data1={[10, 20, 30, 10, 20, 30, 10, 20, 30, 10, 20, 30]}
          data2={[10, 12, 15, 17, 20, 22.5, 25, 27, 30, 30, 27, 25]}
        /> */}

            {/* satff attendance graph */}
            {/* <Chart
          content={"Staff attendance graph"}
          labels={monthList}
          label1={"Clinical Scientist"}
          label2={"Technicians"}
          data1={[10, 20, 30, 10, 20, 30, 10, 20, 30, 10, 20, 30]}
          data2={[10, 12, 15, 17, 20, 22.5, 25, 27, 30, 30, 27, 25]}
        /> */}
          </div>
          {/* Recently  added admins */}
          <div className="m-1 mt-5 p-2 bg-white rounded-[10px]">
            <div className="text-theme-darkBlue poppins-semibold text-[16px] ">
              Recently added admins
            </div>

            {/*  table */}
            {showSpin ? (
              <div className="w-full mt-20 flex items-center justify-center">
                <Spinner className="h-12 w-12" />{" "}
              </div>
            ) : (
              <Table
                tableData={getAdminTableData(adminTableData)}
                tableHeadData={adminTableHeadData}
              />
            )}
          </div>
          {showEditModal ? (
            <Admin edit={true} onClose={closeEditModal} />
          ) : (
            <></>
          )}
        </>
      )}
    </Dashboard>
  );
};

export default DashboardPage;
