// import React from "react";
// import CommonModal from "./CommonModal";
// import { HttpClient } from "../../Redux/controllers/HttpClient";
// import Utility from "../../Utils/Utility";

// const DeleteModal = async ({ onClose, item }) => {
//   // console.log(">>>>", item);
//   const deleteData = async () =>
//     await HttpClient.apiCaller(
//       `admin/delete-test/${item?._id}`,
//       "DELETE",
//       null,
//       null,
//       null
//     )
//       .then((res) => Utility.sToast("Deleted Successfully"))
//       .catch((err) => Utility.eToast("Something Went wrong!"));
//   return (
//     <CommonModal
//       heading={"Are you sure, you want to delete ?"}
//       onClose={onClose}
//     >
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <button
//           style={{
//             padding: "0rem 1.5rem",
//             borderRadius: "0.2rem",
//             marginRight: "1rem",
//             background: "red",
//             color: "white",
//           }}
//           onClick={deleteData}
//         >
//           Yes
//         </button>
//         <button
//           style={{
//             padding: "0rem 1.5rem",
//             borderRadius: "0.2rem",
//             background: "green",
//             color: "white",
//           }}
//         >
//           No
//         </button>
//       </div>
//     </CommonModal>
//   );
// };

// export default DeleteModal;

import React, { useState } from "react";
import CommonModal from "./CommonModal";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import Utility from "../../Utils/Utility";
import axios from "axios";

const FederalDeleteModal = ({ onClose, item }) => {
  const [deleting, setDeleting] = useState(false);

  // console.log(">>>>>>>>>>>", item?._id);

  const deleteData = async () => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${HttpClient.API_BASE_URL}admin/federal-delete-test/${item?._id}`,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        Utility.sToast("Deleted Successfully");
        onClose();
      })
      .catch((error) => {
        console.log(error);
        Utility.eToast("Something Went wrong!");
      })
      .finally(() => setDeleting(false));
  };

  return (
    <CommonModal
      heading={"Are you sure, you want to delete ?"}
      onClose={onClose}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            padding: "0rem 1.5rem",
            borderRadius: "0.2rem",
            marginRight: "1rem",
            background: "red",
            color: "white",
          }}
          onClick={deleteData}
          disabled={deleting} // Disable the button while deletion is in progress
        >
          {deleting ? "Deleting..." : "Yes"}
        </button>
        <button
          style={{
            padding: "0rem 1.5rem",
            borderRadius: "0.2rem",
            background: "green",
            color: "white",
          }}
          onClick={onClose} // Simply close the modal without deletion
          disabled={deleting} // Disable the button while deletion is in progress
        >
          No
        </button>
      </div>
    </CommonModal>
  );
};

export default FederalDeleteModal;
