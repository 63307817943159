import React from "react";
import Dashboard from "../../components/Dashboard";
import useSpecifiUsersHook from "../../components/UseSpecificUsersHook";
import { FaWheelchair } from "react-icons/fa6";
import { Spinner } from "@material-tailwind/react";
import { CardWithIcon } from "../../components/Card";
import { FaUserAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const SiteDashboard = () => {
  const {
    mro,
    patient,
    collectionSite,
    collector,
    employer,
    technician,
    spin,
    setSpin,
    fto,
  } = useSpecifiUsersHook();
  const cardsData = [
    {
      label: "Active Patient",
      icon: <FaWheelchair />,
      count: patient,
      path: "/site-patient",
    },

    {
      label: "Active Collector",
      icon: <FaUserAlt />,
      count: collector,
      path: "/site-collector",
    },
    {
      label: "Forensic Test Order",
      icon: <FaUserAlt />,
      count: fto,
      path: "/site-forensic-test-order",
    },
  ];
  const navigate = useNavigate();
  return (
    <Dashboard>
      {spin ? (
        <div className="w-full mt-20 flex items-center justify-center">
          <Spinner className="h-12 w-12" />{" "}
        </div>
      ) : (
        <div className="grid  xs:grid-cols-2 sm:grid-cols-3 w-full gap-2 cursor-pointer">
          {cardsData?.map((item) => {
            return (
              <CardWithIcon item={item} onClick={() => navigate(item?.path)} />
            );
          })}
        </div>
      )}
    </Dashboard>
  );
};

export default SiteDashboard;
