// import { Badge } from "../../components/Table";
// import Edit from "./../../components/buttons/Edit";

// const diagonisisICD10Details = [
//   {
//     codeName: "Suspected Exposure to covid 19",
//     code: "Z2334",
//     status: "active",
//   },
// ];

// export const diagonisisICD10TableHeadData = [
//   {
//     accessorKey: "codeName",
//     header: "Code Name",
//     size: 200,
//   },
//   {
//     accessorKey: "code",
//     header: "Code",
//     size: 200,
//   },
//   {
//     accessorKey: "status",
//     header: "Status",
//     size: 150,
//   },
//   {
//     accessorKey: "action",
//     header: "Action",
//     size: 150,
//   },
// ];

// export const diagonisisICD10TableData = diagonisisICD10Details.map((item) => {
//   return {
//     codeName: item.codeName,
//     code: item.code,
//     status: <Badge status={item.status} />,
//     action: <Edit />,
//   };
// });

import { Badge } from "../../components/Table";
import Edit from "./../../components/buttons/Edit";

export const diagonisisICD10TableHeadData = [
  {
    accessorKey: "codeName",
    header: "Code Name",
    size: 200,
  },
  {
    accessorKey: "code",
    header: "Code",
    size: 200,
  },
  {
    accessorKey: "status",
    header: "Status",
    size: 150,
  },
  {
    accessorKey: "action",
    header: "Action",
    size: 150,
  },
];

export const getDiagonisisICD10TableData = (dataSet) => {
  const tableData = dataSet?.map((item) => {
    return {
      codeName: item?.name || "N/A",
      code: item?.code || "N/A",
      status: <Badge status={item?.status} />,
      action: <Edit user={item} />,
    };
  });
  return tableData;
};
