import { RiDeleteBin6Line } from "react-icons/ri";
import { useModal } from "../../context/modalContext";
function Delete({ user }) {
  const { openDeleteModal, closeDeleteModal, setUserID, showDeleteModal } =
    useModal();
  return (
    <div
      className="flex  items-center gap-2 text-red-500 poppins-bold underline cursor-pointer font-semibold"
      onClick={() => {
        setUserID(user);
        openDeleteModal();
      }}
    >
      <RiDeleteBin6Line />
    </div>
  );
}

export default Delete;
