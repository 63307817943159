import { Badge } from "../../components/Table";
import LoginAsClinic from "../../components/buttons/LoginAsClinic";
import Toggle from "../../components/buttons/Toggle";
import Edit from "./../../components/buttons/Edit";

export const clinicTableHeadData = [
  {
    accessorKey: "no",
    header: "Sl No",
    size: 50,
  },
  {
    accessorKey: "uid",
    header: "UID",
    size: 50,
  },
  // {
  //   accessorKey: "uid",
  //   header: "UID",
  //   size: 100,
  // },
  {
    accessorKey: "clinicName",
    header: "Clinic Name",
    size: 150,
  },
  {
    accessorKey: "email",
    header: "Email",
    size: 150,
  },
  {
    accessorKey: "mobile",
    header: "Phone",
    size: 150,
  },
  {
    accessorKey: "npi",
    header: "NPI No",
    size: 100,
  },
  // {
  //   accessorKey: "visibilityOfClinic",
  //   header: "Visibility of clinic",
  //   size: 300,
  // },
  // {
  //   accessorKey: "status",
  //   header: "Status",
  //   size: 50,
  // },
  {
    accessorKey: "action",
    header: "Action",
    size: 50,
  },
  // {
  //   accessorKey: "login",
  //   header: "",
  //   enableSorting: false,
  //   enableColumnActions: false,
  // },
];
export const clinicTableHeadDataTwo = [
  {
    accessorKey: "no",
    header: "Sl No",
    size: 50,
  },
  {
    accessorKey: "uid",
    header: "UID",
    size: 50,
  },
  // {
  //   accessorKey: "uid",
  //   header: "UID",
  //   size: 100,
  // },
  {
    accessorKey: "clinicName",
    header: "Clinic Name",
    size: 150,
  },
  {
    accessorKey: "email",
    header: "Email",
    size: 150,
  },
  {
    accessorKey: "mobile",
    header: "Phone",
    size: 150,
  },
  {
    accessorKey: "npi",
    header: "NPI No",
    size: 100,
  },
  // {
  //   accessorKey: "visibilityOfClinic",
  //   header: "Visibility of clinic",
  //   size: 300,
  // },
  // {
  //   accessorKey: "status",
  //   header: "Status",
  //   size: 50,
  // },
  // {
  //   accessorKey: "action",
  //   header: "Action",
  //   size: 50,
  // },
  // {
  //   accessorKey: "login",
  //   header: "",
  //   enableSorting: false,
  //   enableColumnActions: false,
  // },
];

export const getClinicTableData = (dataSet) => {
  const tableData = dataSet.map((item, idx) => {
    return {
      no: idx + 1,
      uid: item?.uid,
      clinicName: item?.clinicName,
      email: item?.email,
      mobile: item?.mobile_number,
      npi: item?.npi || "N/A",
      // visibilityOfClinic: (
      //   <div className="flex items-center gap-2">
      //     <Toggle visibility={item?.login} />
      //   </div>
      // ),
      status: <Badge status={item?.status} />,
      action: <Edit user={item} />,
      // login: <LoginAsClinic />,
    };
  });
  return tableData;
};
export const getMroAssociateTableDataforClinic = (dataSet) => {
  const tableData = dataSet.map((item, idx) => {
    return {
      no: idx + 1,
      uid: item?.uid,
      clinicName: item?.clinicName,
      email: item?.email,
      mobile: item?.mobile_number,
      npi: item?.npi || "N/A",
      // visibilityOfClinic: (
      //   <div className="flex items-center gap-2">
      //     <Toggle visibility={item?.login} />
      //   </div>
      // ),
      // status: <Badge status={item?.status} />,
      // action: <Edit user={item} />,
      // login: <LoginAsClinic />,
    };
  });
  return tableData;
};
