import { Badge } from "../../components/Table";
import Edit from "./../../components/buttons/Edit";

const testCodeDetails = [
  {
    testName: "SAR-CoV-2 RT PCR",
    testCode: "U834743",
    status: "active",
  },
  {
    testName: "Influenza A/B Test",
    testCode: "U982371",
    status: "active",
  },
  {
    testName: "HIV Test",
    testCode: "U764532",
    status: "inactive",
  },
  {
    testName: "Hepatitis B Surface Antigen Test",
    testCode: "U642891",
    status: "active",
  },
  {
    testName: "Thyroid Function Test",
    testCode: "U536492",
    status: "inactive",
  },
];

export const testCodeTableHeadData = [
  {
    accessorKey: "testName",
    header: "Test Name",
    size: 200,
  },
  {
    accessorKey: "testCode",
    header: "Test Code",
    size: 200,
  },
  {
    accessorKey: "status",
    header: "Status",
    size: 150,
  },
  {
    accessorKey: "action",
    header: "Action",
    size: 150,
  },
];

export const getTestCodeTableData = (dataSet) => {
  const tableData = dataSet?.map((item, idx) => {
    return {
      testName: item?.name || "N/A",
      testCode: item?.code || "N/A",
      status: <Badge status={item?.status} />,
      action: <Edit user={item} />,
    };
  });
  return tableData;
};
