import axios from "axios";

// const API_BASE_URL = "http://localhost:7090/api/";
// const API_BASE_URL = "https://genpace-backend.onrender.com/api/";
// const API_BASE_URL = "http://3.88.220.128:7090/api/";
// const API_BASE_URL = "http://54.90.71.140:7090/api/";

const API_BASE_URL = "https://api.ustoxlab.com/api/";

const apiCaller = (
  uri,
  method = "GET",
  data = {},
  token,
  contentType = null
) => {
  return new Promise((resolve, reject) => {
    var config = {
      method: method,
      url: API_BASE_URL + uri,
      headers: {
        "Content-Type": contentType ? contentType : "application/json",
        accept: "*/*",
        Authorization: "Bearer " + token,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const HttpClient = { apiCaller, API_BASE_URL };
