import { FaChevronLeft } from "react-icons/fa6";
const PreviousButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="size-11 hover:opacity-80 rounded  flex items-center justify-center text-white"
      style={{ background: "#1db6fa" }}
    >
      <FaChevronLeft />
    </button>
  );
};

export default PreviousButton;
