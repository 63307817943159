import React, { createContext, useContext, useState } from "react";

const DrugContext = createContext();

export const DrugProvider = ({ children }) => {
  const [showAll, setShowAll] = useState();

  const activate = () => setShowAll((prev) => true);
  const deactivate = () => setShowAll((prev) => false);

  return (
    <DrugContext.Provider value={{ showAll, activate, deactivate }}>
      {children}
    </DrugContext.Provider>
  );
};

export const useDrug = () => useContext(DrugContext);
