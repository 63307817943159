import card1 from "../../assets/card1.png";
import card2 from "../../assets/card2.png";
import card3 from "../../assets/card3.png";
import card4 from "../../assets/card4.png";
import card5 from "../../assets/card5.png";
import card6 from "../../assets/card6.png";
import card7 from "../../assets/card7.png";
import card8 from "../../assets/card8.png";
import card9 from "../../assets/card9.png";
import card10 from "../../assets/card10.png";
import card11 from "../../assets/card11.png";
import UseAllUsersHook from "../../components/UseAllUsersHook";

import React from "react";

export default function dashboardData() {
  const {
    admin,
    physician,
    patient,
    clinic,
    collectionSite,
    collector,
    organization,
    technician,
    clinicalScientist,
    fto,
  } = UseAllUsersHook();

  const cardData = [
    {
      label: "All Admin",
      count: admin,
      icon: card1,
    },
    {
      label: "All Patient",
      count: patient,
      icon: card2,
    },
    {
      label: "All Organization",
      count: organization,
      icon: card3,
    },
    {
      label: "All Clinics",
      count: clinic,
      icon: card4,
    },
    {
      label: "All Collectors",
      count: collector,
      icon: card5,
    },
    {
      label: "All Collection site",
      count: collectionSite,
      icon: card6,
    },
    {
      label: "All Technician",
      count: technician,
      icon: card7,
    },
    {
      label: "All Clinical Scientist",
      count: clinicalScientist,
      icon: card8,
    },
    {
      label: "All Medical Review Officer",
      count: physician,
      icon: card9,
    },
    // {
    //   label: "All Toxicology Order",
    //   count: 0,
    //   icon: card10,
    // },
    {
      label: "All Forensic Test Order",
      count: fto,
      icon: card11,
    },
  ];

  return cardData;
}
