import { ActionButton, Badge, Edit } from "../../components/Table";
import penIcon from "../../assets/table/penIcon.png";
import qrIcon from "../../assets/table/qrIcon.png";

export const patientTableHeadData = [
  {
    accessorKey: "no",
    header: "No",
    size: 30,
  },
  {
    accessorKey: "uid",
    header: "UID",
    size: 30,
  },
  {
    accessorKey: "firstName",
    header: "First Name",
    size: 100,
  },
  {
    accessorKey: "lastName",
    header: "Last Name",
    size: 100,
  },
  {
    accessorKey: "gender",
    header: "Gender",
    size: 100,
  },
  {
    accessorKey: "emailId",
    header: "Email",
    size: 200,
  },
  {
    accessorKey: "mobile",
    header: "Phone",
    size: 150,
  },
  // {
  //   accessorKey: "status",
  //   header: "Status",
  //   size: 100,
  // },
  {
    accessorKey: "action",
    header: "Action",
    size: 150,
  },
];

export const getPatientTableData = (dataSet) => {
  let tableData = dataSet?.map((item, idx) => {
    return {
      no: idx + 1,
      uid: item?.uid,
      firstName: item?.first_name,
      lastName: item?.last_name,
      gender: item?.gender,
      emailId: item?.email,
      mobile: item?.mobile_number,
      status: <Badge status={item?.status} />,
      action: (
        <div className="flex gap-3">
          {/* pen */}
          <ActionButton
            action="edit"
            user={item}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="frn"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                />
              </svg>
            }
          />
          {/* <ActionButton icon={qrIcon} /> */}
        </div>
      ),
    };
  });
  return tableData;
};
