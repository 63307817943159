import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export default function AuthRoute() {
  const token = useSelector((state) => state?.user?.token);
  const user = useSelector((state) => state?.user);
  return token ? (
    user?.user_type === "clinic" ? (
      <Navigate to="/clinic-dashboard" />
    ) : user?.user_type === "organization" ? (
      <Navigate to="/organization-dashboard" />
    ) : user?.user_type === "mro" ? (
      <Navigate to="/phyic-dashboard" />
    ) : user?.user_type === "patient" ? (
      <Navigate to="/patient-dashboard" />
    ) : user?.user_type === "collectionsite" ? (
      <Navigate to="/site-dashboard" />
    ) : user?.user_type === "collector" ? (
      <Navigate to="/collector-dashboard" />
    ) : user?.user_type === "technician" ? (
      <Navigate to="/technician-dashboard" />
    ) : user?.user_type === "scientist" ? (
      <Navigate to="/scientist-dashboard" />
    ) : (
      <Navigate to="/dashboard" />
    )
  ) : (
    <Outlet />
  );
}
