import React, { useState, useEffect } from "react";
import Dashboard from "../../components/Dashboard";
import { FaPlus } from "react-icons/fa";
import AddButton from "../../components/buttons/AddButton";
import Table, { ActionButton } from "../../components/Table";
import AddManualModal from "../../components/Modals/AddManual";
import { toast } from "react-hot-toast";
import moment from "moment";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import Qrmodal from "../../components/Modals/Qrmodal";
import { useSelector } from "react-redux";

const manualHeadData = [
  {
    accessorKey: "slno",
    header: "Sl No",
    size: 100,
  },
  {
    accessorKey: "title",
    header: "Title",
    size: 150,
  },
  {
    accessorKey: "note",
    header: "Note",
    size: 150,
  },
  {
    accessorKey: "created",
    header: "Created On",
    size: 150,
  },
  {
    accessorKey: "action",
    header: "Action",
    size: 300,
  },
];

const Manual = () => {
  const [showModal, setShowModal] = useState(false);
  const [manuals, setManuals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(true);
  const [selectedManual, setSelectedManual] = useState(null);
  const [showQr, setShowQr] = useState(false);
  const [qrItem, setQrItem] = useState([]);
  const [isAllowed, setIsAllowed] = useState(false);

  const { user_type, _id } = useSelector(
    (state) => state.user.userInfo.data.user
  );

  const getMyProfile = async () => {
    try {
      setProfileLoading(true);
      const response = await HttpClient.apiCaller(`profile/${_id}`, "GET");
      if (response?.data?.data?.isManualUploadAllowed === true) {
        setIsAllowed(true);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setProfileLoading(false);
    }
  };

  const fetchManuals = async () => {
    try {
      setLoading(true);
      const response = await HttpClient.apiCaller(`manuals/${_id}`, "GET");

      console.log("resssss", response);
      if (response?.data?.success) {
        setManuals(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching manuals:", error);
      toast.error("Failed to fetch manuals");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMyProfile();
    fetchManuals();
  }, []);

  // Rest of the component code remains the same...
  const handleSubmit = async (formData) => {
    try {
      const response = await HttpClient.apiCaller("manuals", "POST", formData);

      console.log("resssss", response);
      if (response?.data?.success) {
        fetchManuals();
        setShowModal(false);
      }
    } catch (error) {
      console.error("Error adding manual:", error);
      toast.error("Failed to add manual");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this manual?")) {
      try {
        const response = await HttpClient.apiCaller(`manuals/${id}`, "DELETE");
        if (response?.data?.success) {
          toast.success("Manual deleted successfully");
          fetchManuals();
        }
      } catch (error) {
        console.error("Error deleting manual:", error);
        toast.error("Failed to delete manual");
      }
    }
  };

  const handleViewPdf = (url) => {
    window.open(url, "_blank");
  };

  const manualData = manuals.map((item, idx) => {
    return {
      slno: idx + 1,
      title: item.title,
      note: item.note || "-",
      created: moment(item.createdAt).format("MM/DD/YYYY"),
      action: (
        <div className="flex gap-1">
          <ActionButton
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="frn"
                onClick={() => {
                  setShowQr(true);
                  setQrItem(item?.doc);
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 3.75 9.375v-4.5ZM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 0 1-1.125-1.125v-4.5ZM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 13.5 9.375v-4.5Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 6.75h.75v.75h-.75v-.75ZM6.75 16.5h.75v.75h-.75v-.75ZM16.5 6.75h.75v.75h-.75v-.75ZM13.5 13.5h.75v.75h-.75v-.75ZM13.5 19.5h.75v.75h-.75v-.75ZM19.5 13.5h.75v.75h-.75v-.75ZM19.5 19.5h.75v.75h-.75v-.75ZM16.5 16.5h.75v.75h-.75v-.75Z"
                />
              </svg>
            }
          />

          <ActionButton
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="frn"
                onClick={() => handleViewPdf(item.doc)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                />
              </svg>
            }
          />
          {(user_type === "collectionsite" || user_type === "organization") && (
            <ActionButton
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="frn"
                  onClick={() => {
                    handleDelete(item._id);
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              }
            />
          )}
        </div>
      ),
    };
  });

  if (profileLoading) {
    return (
      <Dashboard>
        <div className="min-h-[80vh] flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-theme-darkBlue"></div>
        </div>
      </Dashboard>
    );
  }

  if (
    user_type !== "collectionsite" &&
    (user_type !== "organization" || !isAllowed)
  ) {
    return (
      <Dashboard>
        <div className="min-h-[80vh] flex flex-col items-center justify-center bg-gray-50 p-4">
          <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full text-center">
            <div className="mb-6">
              <svg
                className="mx-auto h-12 w-12 text-red-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            </div>

            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              Access Denied
            </h2>

            <p className="text-gray-600 mb-6">
              Sorry, you don't have permission to access this page.
            </p>
          </div>
        </div>
      </Dashboard>
    );
  }

  return (
    <Dashboard>
      <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
        <div>
          <p className="poppins-medium text-lg leading-[21px] text-theme-darkBlue mb-2">
            Manuals
          </p>
        </div>
        <div className="flex gap-5 xs:flex-col md:flex-row">
          <AddButton
            onClick={() => {
              setSelectedManual(null);
              setShowModal(true);
            }}
            buttonName={"Add New Manual"}
            icon={<FaPlus className="size-5" />}
          />
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-48">
          <p>Loading...</p>
        </div>
      ) : (
        <Table
          tableData={manualData}
          tableHeadData={manualHeadData}
          emptyMessage="No manuals found"
        />
      )}

      <AddManualModal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectedManual(null);
        }}
        onSubmit={handleSubmit}
        editData={selectedManual}
      />
      {showQr && <Qrmodal item={qrItem} onClose={() => setShowQr(false)} />}
    </Dashboard>
  );
};

export default Manual;
