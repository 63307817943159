import { createContext, useContext, useState } from "react";

const ForensicContext = createContext();

export const ForensicProvider = ({ children }) => {
  const [showForensic, setShowForensic] = useState(false);

  const activateForensic = () => setShowForensic(true);
  const deactivateForensic = () => setShowForensic(false);

  return (
    <ForensicContext.Provider
      value={{
        showForensic,
        activateForensic,
        deactivateForensic,
      }}
    >
      {children}
    </ForensicContext.Provider>
  );
};

export const useForensic = () => useContext(ForensicContext);
