import React from "react";
import CommonModal from "./CommonModal";
import Submit from "../buttons/Submit";
import Cancel from "../buttons/Cancel";
import { Spinner } from "@material-tailwind/react";
import { SlReload } from "react-icons/sl";
import { useState } from "react";

const PhysicUploadPatient = ({ onClose }) => {
  const [showSpin, setShowSpin] = useState(false);
  const [doc, setDoc] = useState();

  return (
    <CommonModal heading={"Upload Any Document"} onClose={onClose}>
      {/* input file */}
      <div className="flex flex-col mb-2">
        <label className="mb-1 poppins-medium">Document : </label>
        <input
          value={doc}
          //   onChange={(e) => setDoc(e.target.files[0])}
          type="file"
          className="w-full h-10  rounded bg-[#ECF1F6] p-2 poppins-regular text-sm"
        />
      </div>

      <div className="flex justify-between mt-3">
        <div>
          <button
            // onClick={formik.handleReset}
            className="flex items-center px-2 py-1  poppins-medium border-2  border-gray-400 rounded hover:opacity-80 bg-[#ECF1F6]"
          >
            <SlReload className="mr-1" />
            reset
          </button>
        </div>
        <div className="flex gap-4 ml-auto">
          <Cancel onClick={onClose} />
          <Submit label={showSpin ? <Spinner color="white" /> : "submit"} />
        </div>
      </div>
    </CommonModal>
  );
};

export default PhysicUploadPatient;
