import React from "react";

const Cancel = ({ onClick }) => {
  return (
    <div>
      <button
        onClick={onClick}
        className="px-2 py-1 poppins-medium border-2  border-gray-400 rounded hover:opacity-80 bg-[#ECF1F6]"
      >
        cancel
      </button>
    </div>
  );
};

export default Cancel;
