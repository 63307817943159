import { MdPersonAddAlt1 } from "react-icons/md";
import AddButton from "../../components/buttons/AddButton";
import Dashboard from "../../components/Dashboard";
import Table, { Badge } from "../../components/Table";
import {
  // getAdminTableData,
  adminTableHeadData,
} from "../../constants/data/adminsData";

import Admin from "./../../components/Modals/Admin";
import { useModal } from "../../context/modalContext";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { Spinner } from "@material-tailwind/react";
import Delete from "../../components/buttons/DeleteButton";
import Edit from "../../components/buttons/Edit";
import DeleteUserModal from "../../components/Modals/DeleteUserModal";

const AdminsPage = () => {
  const {
    showEditModal,
    showModal,
    openModal,
    closeModal,
    closeEditModal,
    openDeleteModal,
    closeDeleteModal,
    showDeleteModal,
  } = useModal();
  const [showSpin, setShowSpin] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [adminTableData, setAdminTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setShowSpin(true);
      try {
        const { data } = await HttpClient.apiCaller(
          "admin/user-list?role=admin",
          "GET",
          null,
          token,
          null
        );
        console.log(data.user);
        setAdminTableData(data.user);
      } catch (error) {
        console.log(error);
      } finally {
        setShowSpin(false);
      }
    };

    fetchData();

    return () => {};
  }, [closeModal, closeEditModal]);
  const getAdminTableData = (dataSet) => {
    const tableData = dataSet?.map((item, idx) => {
      return {
        no: idx + 1,
        // firstName: item?.first_name || "N/A",
        // lastName: item?.last_name || "N/A",
        uid: item?.uid,
        email: item?.email || "N/A",
        phone: item?.phone_number || "N/A",
        // status: <Badge status={item?.status} />,
        action: (
          <div className="flex gap-3">
            <Edit user={item} />
            <Delete user={item} />
          </div>
        ),
      };
    });
    return tableData;
  };
  return (
    <Dashboard>
      <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
        <div className="">
          <p className="poppins-medium  leading-[21px]  text-gray-700 mb-2">
            List of Admins
          </p>
          <p className="poppins-regular text-xs text-[#B5B5C3] ">
            {adminTableData?.length} available admins
          </p>
        </div>
        {/*  */}
        <AddButton
          onClick={openModal}
          buttonName={"Add New Admin"}
          icon={<MdPersonAddAlt1 className="size-5" />}
        />
      </div>
      {/*  table */}
      {showSpin ? (
        <div className="w-full mt-20 flex items-center justify-center">
          <Spinner className="h-12 w-12" />{" "}
        </div>
      ) : (
        <Table
          tableData={getAdminTableData(adminTableData)}
          tableHeadData={adminTableHeadData}
        />
      )}

      {/*Add modal */}
      {showModal ? <Admin edit={false} onClose={closeModal} /> : <></>}
      {/*Edit modal */}
      {showEditModal ? <Admin edit={true} onClose={closeEditModal} /> : <></>}
      {/* delete modal */}
      {showDeleteModal && <DeleteUserModal onClose={closeDeleteModal} />}
    </Dashboard>
  );
};

export default AdminsPage;
