import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HttpClient } from "../Redux/controllers/HttpClient";

export default function useSpecifiUsersHook() {
  const [mro, setMro] = useState([]);
  const [patient, setPatient] = useState([]);
  const [collectionSite, setCollectionSite] = useState([]);
  const [collector, setCollector] = useState([]);
  const [technician, setTechnician] = useState([]);
  const [employer, setEmployer] = useState([]);
  const [clinic, setClinic] = useState([]);
  const [organization, setOrganization] = useState([]);
  const [fto, setFto] = useState([]);
  const [fto2, setFto2] = useState([]);
  const [spin, setSpin] = useState(false);

  const token = useSelector((state) => state?.user?.token);
  const userDetail = useSelector(
    (state) => state?.user?.userInfo?.data?.user?._id
  );
  const userType = useSelector(
    (state) => state?.user?.userInfo?.data?.user?.user_type
  );
  console.log(">>>>>>>>>>", userType);

  useEffect(() => {
    AllUsers();
  }, []);

  const AllUsers = async () => {
    try {
      setSpin(true);

      // await HttpClient.apiCaller(
      //   `admin/get-users-createdby/${userDetail}?user_type=technician`,
      //   "GET",
      //   null,
      //   token,
      //   null
      // )
      //   .then((res) => setTechnician(res?.data?.user?.length))
      //   .catch((err) => console.log(err));

      // await HttpClient.apiCaller(
      //   `admin/get-users-createdby/${userDetail}?user_type=employer`,
      //   "GET",
      //   null,
      //   token,
      //   null
      // )
      //   .then((res) => setEmployer(res?.data?.user?.length))
      //   .catch((err) => console.log(err));

      // await HttpClient.apiCaller(
      //   `admin/get-users-createdby/${userDetail}?user_type=collector`,
      //   "GET",
      //   null,
      //   token,
      //   null
      // )
      //   .then((res) => setCollector(res?.data?.user?.length))
      //   .catch((err) => console.log(err));

      // await HttpClient.apiCaller(
      //   `admin/get-users-createdby/${userDetail}?user_type=clinic`,
      //   "GET",
      //   null,
      //   token,
      //   null
      // )
      //   .then((res) => setClinic(res?.data?.user?.length))
      //   .catch((err) => console.log(err));

      // await HttpClient.apiCaller(
      //   `admin/get-users-createdby/${userDetail}?user_type=organization`,
      //   "GET",
      //   null,
      //   token,
      //   null
      // )
      //   .then((res) => setOrganization(res?.data?.user?.length))
      //   .catch((err) => console.log(err));

      if (userType === "technician") {
        await HttpClient.apiCaller(
          `admin/get-users-createdby/${userDetail}?user_type=mro`,
          "GET",
          null,
          token,
          null
        )
          .then((res) => setMro(res?.data?.user?.length))
          .catch((err) => console.log(err));

        await HttpClient.apiCaller(
          `admin/get-users-createdby/${userDetail}?user_type=patient`,
          "GET",
          null,
          token,
          null
        )
          .then((res) => setPatient(res?.data?.user?.length))
          .catch((err) => console.log(err));

        await HttpClient.apiCaller(
          // `admin/test`,
          `admin/get-test-by-accessor-tech/${userDetail}`,
          "GET",
          null,
          token,
          null
        )
          .then((res) => setFto2(res?.data?.test?.length))
          .catch((err) => console.log(err));
      }

      // await HttpClient.apiCaller(
      //   `admin/get-users-createdby/${userDetail}?user_type=collectionsite`,
      //   "GET",
      //   null,
      //   token,
      //   null
      // )
      //   .then((res) => setCollectionSite(res?.data?.user?.length))
      //   .catch((err) => console.log(err));

      await HttpClient.apiCaller(
        // `admin/test`,
        `admin/get-test-by-accessor/${userDetail}`,
        "GET",
        null,
        token,
        null
      )
        .then((res) => setFto(res?.data?.test?.length))
        .catch((err) => console.log(err));
    } catch (e) {
      console.log(e);
    } finally {
      setSpin(false);
    }
  };

  return {
    mro,
    patient,

    collectionSite,
    collector,
    employer,
    technician,
    clinic,
    organization,
    spin,
    setSpin,
    fto,
    fto2,
  };
}
