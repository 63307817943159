import React, { useState, useRef, useEffect } from "react";
import mask from "../../assets/Mask group.jpg";
import "./Form.css";
import { LuPencil } from "react-icons/lu";
import SignatureCanvas from "react-signature-canvas";
import PreviousButton from "../buttons/PreviousButton";
import Cancelsmall from "../buttons/Cancelsmall";
import Utility from "../../Utils/Utility";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";

const ClinicForensicOrderForm = ({ onClose }) => {
  const token = useSelector((state) => state?.user?.token);
  const userInfo = useSelector((state) => state?.user?.userInfo?.data?.user);
  // console.log("userrrrrrr", userInfo);

  const [panelData, setPanelData] = useState([]);
  const [drugdata, setDrugData] = useState([]);

  const [custom_drug_test, set_custom_drug_test] = useState([]);
  const [additional_drug_test, set_additional_drug_test] = useState([]);

  const [rep, setRep] = useState([]);
  const [repMro, setRepMro] = useState([]);

  const [phyMisc, setPhyMisc] = useState([]);

  useEffect(() => {
    getPanelData();
    getDrugData();
    getRep();
  }, []);

  const [logoo, setLogoo] = useState("");

  useEffect(() => {
    getLogo();
  }, []);
  const getLogo = async () =>
    await HttpClient.apiCaller(
      `get-logo/${userInfo?._id}`,
      "GET",
      null,
      null,
      null
    )
      .then((res) => {
        setLogoo(res?.data?.exisLog?.img_path);
      })
      .catch((err) => console.log(err));

  const getPanelData = async () => {
    await HttpClient.apiCaller("admin/panel-get", "GET", null, token, null)
      .then((res) => {
        // console.log("1<><><>", res);
        setPanelData(res?.data?.code);
      })
      .catch((err) => console.log(err));
  };
  const getDrugData = async () => {
    await HttpClient.apiCaller("admin/get-drug-code", "GET", null, token, null)
      .then((res) => {
        // console.log("2<><><>", res);
        setDrugData(res?.data?.code);
      })
      .catch((err) => console.log(err));
  };
  const getRep = async () => {
    await HttpClient.apiCaller(
      // "admin/user-list?role=physician",
      `admin/get-users-createdby/${userInfo?._id}?user_type=mro`,
      "GET",
      null,
      token,
      null
    )
      .then((res) => {
        // console.log("?????????", res?.data?.user);
        setRep(res?.data?.user);
      })
      .catch((err) => console.log(err));

    await HttpClient.apiCaller(
      // "admin/user-list?role=physician",
      `admin/get-mros-under/${userInfo?._id}`,
      "GET",
      null,
      token,
      null
    )
      .then((res) => {
        // console.log("?????????", res?.data?.users);
        setRepMro(res?.data?.users);
      })
      .catch((err) => console.log(err));
  };

  const finalData = [...rep, ...repMro];

  const drugPanelData = panelData?.map((item, i) => ({
    panelName: item?.name,
    id: item?._id,
  }));

  const drugNameData = drugdata?.map((item, i) => ({
    drugName: item?.name,
    id: item?._id,
  }));

  const handleSelect = (selectedOption) => {
    // const selectedIds = selectedOption.map((option) => option?.id);
    const selectedIds = selectedOption.map((option) => option?.panelName);
    set_custom_drug_test(selectedIds);
  };
  // console.log("__________>", custom_drug_test);
  const handleSelectt = (SelectOption) => {
    // const selectedIdss = SelectOption.map((option) => option?.id);
    const selectedIdss = SelectOption.map((option) => option?.drugName);
    set_additional_drug_test(selectedIdss);
  };
  // console.log("2__________>", additional_drug_test);

  const currentDateTime = new Date();
  const formattedDate = currentDateTime.toISOString().slice(0, 10);
  const formattedTime = currentDateTime.toTimeString().slice(0, 5);

  const handleStep4Date = () => {
    set_collection_date(formattedDate);
    set_collection_time(formattedTime);
  };
  const handleStepLabDate = () => {
    set_r_date(formattedDate);
  };
  const handleSplitExpDate = () => {
    set_primary_specimen_expiration(formattedDate);
    set_split_specimen_expiration(formattedDate);
  };
  const handleDonorDate = () => {
    set_donor_date(formattedDate);
  };

  const [specimen_id, set_specimen_id] = useState("");
  const [accession_no, set_accession_no] = useState("");
  const [employer_name, set_employer_name] = useState(
    userInfo?.clinicName || userInfo?.organizationName
  );
  const [employer_code, set_employer_code] = useState(
    userInfo?.employer_code || ""
  );
  const [employer_address, set_employer_address] = useState(userInfo?.address);
  const [employer_id, set_employer_id] = useState(userInfo?.npi);
  //mro
  const [mro_name, set_mro_name] = useState("");
  const [mro_name1, set_mro_name1] = useState("");
  useEffect(() => {
    getPhysicianById();
  }, [mro_name]);

  const getPhysicianById = async () => {
    await HttpClient.apiCaller(
      `admin/get-user-by-id/${mro_name}`,
      "GET",
      null,
      token,
      null
    )
      .then((res) => {
        // console.log("@@@@@@@2", res?.data?.user[0]);
        setPhyMisc(res?.data?.user[0]);
        set_mro_phone(res?.data?.user[0]?.mobile_number);
        set_mro_fax(res?.data?.user[0]?.faxNo);
        set_mro_addr(res?.data?.user[0]?.address);
        set_mro_name1(
          `${res?.data?.user[0]?.first_name} ${res?.data?.user[0]?.last_name}`
        );
      })
      .catch((err) => console.log(err));
  };

  const [mro_phone, set_mro_phone] = useState("");
  const [mro_fax, set_mro_fax] = useState("");
  const [mro_addr, set_mro_addr] = useState("");

  //donor
  const [donor_first_name, set_donor_first_name] = useState("");
  const [donor_dob, set_donor_dob] = useState("");
  const [donor_sign, set_donor_sign] = useState(""); //signature image
  const [donor_day_no, set_donor_day_no] = useState("");
  const [donor_eve_no, set_donor_eve_no] = useState("");
  const [donor_last_name, set_donor_last_name] = useState("");
  const [donor_id, set_donor_id] = useState("");
  const [donor_date, set_donor_date] = useState("");
  //donor verification
  const [photo_id, set_photo_id] = useState("");
  // const [employer, set_employer] = useState("");
  // const [representive, set_representive] = useState("");
  const [id_type, set_id_type] = useState(false);
  const [emp_Id_type, set_emp_id_type] = useState(false);
  // const handleId = (e) => set_id_type(e.target.value);
  const handleId = () => {
    let checkbox = document.getElementById("photoid");
    var isChecked = checkbox.checked;
    if (isChecked) {
      set_id_type(true);
    } else {
      set_id_type(false);
    }
  };
  const handleIdd = () => {
    let checkbox = document.getElementById("employer");
    var isChecked = checkbox.checked;
    if (isChecked) {
      set_emp_id_type(true);
    } else {
      set_emp_id_type(false);
    }
  };

  const [test_reason, set_test_reason] = useState("");
  const testReason = (e) => set_test_reason(e.target.value);

  const [reason_remark, set_reason_remark] = useState("");

  // const [custom_drug_test, set_custom_drug_test] = useState("");
  // const [additional_drug_test, set_additional_drug_test] = useState("");
  //site details

  const [site_name, set_site_name] = useState("");
  // const [site_name1, set_site_name1] = useState("");
  // useEffect(() => {
  //   sitesUnderEmp();
  //   siteUnderEmp();
  // }, [site_name]);
  // const [sites, setSites] = useState([]);
  // const [sitesDtl, setSitesDtl] = useState([]);
  // const sitesUnderEmp = async () => {
  //   await HttpClient.apiCaller(
  //     `admin/get-users-createdby/${userInfo?._id}?user_type=collectionsite`,
  //     "GET",
  //     null,
  //     null,
  //     null
  //   )
  //     .then((res) => {
  //       setSites(res?.data?.user);
  //     })
  //     .catch((err) => console.log(err));
  // };
  // const siteUnderEmp = async () => {
  //   await HttpClient.apiCaller(
  //     `admin/get-user-by-id/${site_name}`,
  //     "GET",
  //     null,
  //     null,
  //     null
  //   )
  //     .then((res) => {
  //       // console.log("siteee", res?.data?.user[0]);
  //       setSitesDtl(res?.data?.user[0]);
  //       set_site_phone(res?.data?.user[0]?.mobile_number);
  //       set_site_fax(res?.data?.user[0]?.faxNo);
  //       set_site_addr(res?.data?.user[0]?.address);
  //       set_site_pin(res?.data?.user[0]?.postal_code);
  //       set_site_name1(res?.data?.user[0]?.first_name);
  //     })
  //     .catch((err) => console.log(err));
  // };

  const [site_phone, set_site_phone] = useState("");
  const [site_fax, set_site_fax] = useState("");
  const [site_addr, set_site_addr] = useState("");
  const [site_pin, set_site_pin] = useState("");

  const [test_type, set_test_type] = useState("blood");
  const testType = (e) => set_test_type(e.target.value);

  //collections
  const [collection_type, set_collection_type] = useState("");
  const collectionType = (e) => set_collection_type(e.target.value);

  const [split_type, set_split_type] = useState("");
  const splitType = (e) => set_split_type(e.target.value);

  const [expiration_date, set_expiration_date] = useState(false);
  const expired = (e) => set_expiration_date(e.target.value);

  const [urine_temperature, set_urine_temperature] = useState("");
  const urine = (e) => set_urine_temperature(e.target.value);

  const [volume_indicator, set_volume_indicator] = useState(false);
  const volumeIndicator = (e) => set_volume_indicator(e.target.value);

  const [remark, set_remark] = useState("");
  //custody
  const [collector_sign, set_collector_sign] = useState(""); //image
  const [collector_name, set_collector_name] = useState("");
  const [collection_date, set_collection_date] = useState("");
  const [collection_time, set_collection_time] = useState("");

  const [released_to, set_released_to] = useState("");
  const releasedTo = (e) => set_released_to(e.target.value);

  //received at
  const [r_collector_sign, set_r_collector_sign] = useState(""); //image
  const [r_collector_name, set_r_collector_name] = useState("");
  const [r_date, set_r_date] = useState("");
  const [r_time, set_r_time] = useState("");

  const [r_seal, set_r_seal] = useState("");
  const sealed = (e) => set_r_seal(e.target.value);

  const [r_remark, set_r_remark] = useState("");
  const [r_released_to, set_r_released_to] = useState("");
  const [primary_specimen_expiration, set_primary_specimen_expiration] =
    useState("");
  const [split_specimen_expiration, set_split_specimen_expiration] =
    useState("");

  const [spin, setSpin] = useState(false);
  const [load, setLoad] = useState(false);
  // signature refs
  const collectorSignRef = useRef();
  const collectionSiteSignRef = useRef();
  const accessionerSignRef = useRef();
  const certifyingSignRef = useRef();
  const medicalReviewSignRef = useRef();
  const medicalReviewOfficerSignRef = useRef();
  const [collectionSiteSign, setCollectionSiteSign] = useState(null);
  const [accessionerSign, setAccessionerSign] = useState(null);
  const [certifyingSign, setCertifyingSign] = useState(null);
  const [medicalReviewSign, setMedicalReviewSign] = useState(null);
  const [medicalReviewOfficerSign, setMedicalReviewOfficerSign] =
    useState(null);
  const [otherCourier, setOtherCourier] = useState("");

  const handleCollectorSignClear = () => {
    collectorSignRef.current.clear();
    set_collector_sign(null);
  };
  const handleCollectionSiteSignClear = () => {
    collectionSiteSignRef.current.clear();
    setCollectionSiteSign(null);
  };
  const handleAccessionerSignClear = () => {
    accessionerSignRef.current.clear();
    setAccessionerSign(null);
  };
  const handleCertifyingSignClear = () => {
    certifyingSignRef.current.clear();
    setCertifyingSign(null);
  };
  const handleMedicalReviewSignClear = () => {
    medicalReviewSignRef.current.clear();
    setMedicalReviewSign(null);
  };
  const handleMedicalReviewOfficerSignClear = () => {
    medicalReviewOfficerSignRef.current.clear();
    setMedicalReviewOfficerSign(null);
  };

  let custody_sign, receiver_accessioner, donor_accessioner;

  const handleCollectorSignSave = async () => {
    if (collectorSignRef.current.isEmpty()) {
      alert("Please provide a collector signature.");
      return;
    } else {
      setLoad(true);
      const dataUrl = collectorSignRef.current.toDataURL();
      const blob = await fetch(dataUrl).then((res) => res.blob());
      const file = new File([blob], "image.png", { type: "image/png" });
      let formData = new FormData();
      formData.append("img", file);
      await HttpClient.apiCaller(
        "upload/single-upload",
        "POST",
        formData,
        null,
        "multipart/form-data"
      )
        .then((res) => {
          console.log(res);
          // set_collector_sign(res?.data?.img);
          custody_sign = res?.data?.img;
        })
        .catch((err) => console.log(err))
        .finally(() => setLoad(false));
    }
  };

  const handleCollectionSiteSignSave = async () => {
    if (collectionSiteSignRef.current.isEmpty()) {
      alert("Please provide a collection site signature.");
      return;
    } else {
      setLoad(true);
      const dataUrl = collectionSiteSignRef.current.toDataURL();
      const blob = await fetch(dataUrl).then((res) => res.blob());
      const file = new File([blob], "image.png", { type: "image/png" });
      let formData = new FormData();
      formData.append("img", file);
      await HttpClient.apiCaller(
        "upload/single-upload",
        "POST",
        formData,
        null,
        "multipart/form-data"
      )
        .then((res) => {
          console.log(res);
          // set_r_collector_sign(res?.data?.img);
          receiver_accessioner = res?.data?.img;
        })
        .catch((err) => console.log(err))
        .finally(() => setLoad(false));
    }
  };

  const handleAccessionerSignSave = async () => {
    if (accessionerSignRef.current.isEmpty()) {
      alert("Please provide a Accessioner signature.");
      return;
    } else {
      setLoad(true);
      const dataUrl = accessionerSignRef.current.toDataURL();
      const blob = await fetch(dataUrl).then((res) => res.blob());
      const file = new File([blob], "image.png", { type: "image/png" });
      let formData = new FormData();
      formData.append("img", file);
      await HttpClient.apiCaller(
        "upload/single-upload",
        "POST",
        formData,
        null,
        "multipart/form-data"
      )
        .then((res) => {
          console.log(res);
          // set_donor_sign(res?.data?.img);
          donor_accessioner = res?.data?.img;
        })
        .catch((err) => console.log(err))
        .finally(() => setLoad(false));
    }
  };
  const handleCertifyingSignSave = () => {
    if (certifyingSignRef.current.isEmpty()) {
      alert("Please provide a certifying signature.");
    } else {
      const dataUrl = certifyingSignRef.current.toDataURL();
      certifyingSign(dataUrl);
    }
  };
  const handleMedicalReviewSignSave = () => {
    if (medicalReviewSignRef.current.isEmpty()) {
      alert("Please provide a Medical Review signature.");
    } else {
      const dataUrl = medicalReviewSignRef.current.toDataURL();
      setMedicalReviewSign(dataUrl);
    }
  };
  const handleMedicalReviewOfficerSignSave = () => {
    if (medicalReviewOfficerSignRef.current.isEmpty()) {
      alert("Please provide a medical officer signature.");
    } else {
      const dataUrl = medicalReviewOfficerSignRef.current.toDataURL();
      setMedicalReviewOfficerSign(dataUrl);
    }
  };

  const validation = () => {
    if (specimen_id === "" || specimen_id === undefined) {
      Utility.eToast("Specimen Id Required!");
      return false;
    }
    // else if (accession_no === "" || accession_no === undefined) {
    //   Utility.eToast("Accession No required!");
    //   return false;
    // }
    else if (employer_name === "" || employer_name === undefined) {
      Utility.eToast("Employer name required!");
      return false;
    }
    // else if (employer_code === "" || employer_code === undefined) {
    //   Utility.eToast("Employer code required!");
    //   return false;
    // }
    // else if (employer_address === "" || employer_address === undefined) {
    //   Utility.eToast("Employer Address required!");
    //   return false;
    // }
    // else if (employer_id === "" || employer_id === undefined) {
    //   Utility.eToast("Employer Id required!");
    //   return false;
    // }
    else if (mro_name === "" || mro_name === undefined) {
      Utility.eToast(" Mro namerequired!");
      return false;
    }
    // else if (mro_phone === "" || mro_phone === undefined) {
    //   Utility.eToast("Mro Phone required!");
    //   return false;
    // } else if (mro_addr === "" || mro_addr === undefined) {
    //   Utility.eToast("Mro Addr required!");
    //   return false;
    // }
    else if (donor_first_name === "" || donor_first_name === undefined) {
      Utility.eToast("Donor first name required!");
      return false;
    } else if (donor_dob === "" || donor_dob === undefined) {
      Utility.eToast("Donor dob required!");
      return false;
    } else if (donor_day_no === "" || donor_day_no === undefined) {
      Utility.eToast(" Donor day no required!");
      return false;
    }
    // else if (donor_eve_no === "" || donor_eve_no === undefined) {
    //   Utility.eToast("Donor evening no required!");
    //   return false;
    // }
    else if (donor_last_name === "" || donor_last_name === undefined) {
      Utility.eToast("Donor last name required!");
      return false;
    } else if (donor_id === "" || donor_id === undefined) {
      Utility.eToast(" Donor id required!");
      return false;
    } else if (donor_date === "" || donor_date === undefined) {
      Utility.eToast("Donor date required");
      return false;
    }
    // else if (photo_id === "" || photo_id === undefined) {
    //   Utility.eToast("Photo id required!");
    //   return false;
    // }
    // else if (id_type === "" || id_type === undefined) {
    //   Utility.eToast("Id type required!");
    //   return false;
    // }
    else if (test_reason === "" || test_reason === undefined) {
      Utility.eToast(" Test Reason required!");
      return false;
    } else if (custom_drug_test === "" || custom_drug_test === undefined) {
      Utility.eToast("Custom drug test required!");
      return false;
    } else if (
      additional_drug_test === "" ||
      additional_drug_test === undefined
    ) {
      Utility.eToast("Additional drug test required!");
      return false;
      // } else if (site_name === "" || site_name === undefined) {
      //   Utility.eToast("Site name required!");
      //   return false;
    }
    // else if (site_phone === "" || site_phone === undefined) {
    //   Utility.eToast("Site phone required!");
    //   return false;
    // } else if (site_addr === "" || site_addr === undefined) {
    //   Utility.eToast("Site addr required!");
    //   return false;
    // } else if (site_pin === "" || site_pin === undefined) {
    //   Utility.eToast("Site pin required!");
    //   return false;
    // }
    else if (test_type === "" || test_type === undefined) {
      Utility.eToast("Test type required!");
      return false;
    } else if (collection_type === "" || collection_type === undefined) {
      Utility.eToast("Collection type required!");
      return false;
    }
    // else if (split_type === "" || split_type === undefined) {
    //   Utility.eToast("Split type required!");
    //   return false;
    // }
    // else if (expiration_date === "" || expiration_date === undefined) {
    //   Utility.eToast("Expiration date required!");
    //   return false;
    // }
    // else if (volume_indicator === "" || volume_indicator === undefined) {
    //   Utility.eToast("Volume indicator required!");
    //   return false;
    // }
    // else if (remark === "" || remark === undefined) {
    //   Utility.eToast("remark required!");
    //   return false;
    // }
    else if (collector_name === "" || collector_name === undefined) {
      Utility.eToast("Collector name required!");
      return false;
    } else if (collection_date === "" || collection_date === undefined) {
      Utility.eToast("Collection date required!");
      return false;
    } else if (collection_time === "" || collection_time === undefined) {
      Utility.eToast("Collection time required!");
      return false;
    } else if (released_to === "" || released_to === undefined) {
      Utility.eToast(" Released to required!");
      return false;
    }
    // else if (r_collector_name === "" || r_collector_name === undefined) {
    //   Utility.eToast("r_collector_name required!");
    //   return false;
    // }
    // else if (r_date === "" || r_date === undefined) {
    //   Utility.eToast("Collection date required!");
    //   return false;
    // }
    // else if (r_time === "" || r_time === undefined) {
    //   Utility.eToast("collection time required!");
    //   return false;
    // }
    // else if (r_seal === "" || r_seal === undefined) {
    //   Utility.eToast("seal required!");
    //   return false;
    // }
    // else if (r_remark === "" || r_remark === undefined) {
    //   Utility.eToast("Seal remark required!");
    //   return false;
    // }
    // else if (r_released_to === "" || r_released_to === undefined) {
    //   Utility.eToast("Released to required!");
    //   return false;
    // }
    // else if (
    //   primary_specimen_expiration === "" ||
    //   primary_specimen_expiration === undefined
    // ) {
    //   Utility.eToast("Primary specimen expiration required!");
    //   return false;
    // } else if (
    //   split_specimen_expiration === "" ||
    //   split_specimen_expiration === undefined
    // ) {
    //   Utility.eToast("Split specimen expiration required!");
    //   return false;
    // }
    else {
      return true;
    }
  };

  // console.log("-=-=-=--=", window.innerWidth <= 650 ? "Hello" : "hi");

  const forensicDrugFromSave = async () => {
    await handleCollectorSignSave();
    // await handleCollectionSiteSignSave();
    await handleAccessionerSignSave();
    if (validation()) {
      setSpin(true);
      const postData = {
        test_created_by: userInfo?._id,
        specimen_id: specimen_id,
        accession_no: accession_no,
        employer_name: employer_name,
        employer_code: employer_code,
        employer_address: employer_address,
        employer_id: employer_id,
        mro: {
          // name: mro_name,
          name: mro_name1,
          phone_number: mro_phone,
          fax_number: mro_fax,
          address: mro_addr,
        },
        donor_first_name: donor_first_name,
        donor_dob: donor_dob,
        // donor_sign: donor_sign,
        donor_sign: donor_accessioner,
        donor_day_no: donor_day_no,
        donor_eve_no: donor_eve_no,
        donor_last_name: donor_last_name,
        donor_date: donor_date,
        donor_id: donor_id,
        donor_verification: {
          photo_id: photo_id,
          employer: emp_Id_type,
          representative: id_type,
        },
        test_reason: test_reason,
        reason_remark: reason_remark,
        custom_drug_test: custom_drug_test,
        additional_drug_test: additional_drug_test,
        site_details: {
          name: site_name,
          // name: site_name1,
          phone_number: site_phone,
          fax_number: site_fax || "",
          address: site_addr,
          postal_code: site_pin,
        },
        test_type: test_type,
        collections: {
          collection_type: collection_type,
          split_type: split_type,
          expiration_date: expiration_date,
          urine_temperature: urine_temperature,
          volume_indicator: volume_indicator,
          remark: remark,
        },
        custody: {
          // collector_sign: collector_sign,
          collector_sign: custody_sign,
          collector_name: collector_name,
          date: collection_date,
          time: collection_time,
          released_to: released_to,
          other: otherCourier,
        },
        // received_at: {
        //   // collector_sign: r_collector_sign,
        //   collector_sign: receiver_accessioner,
        //   collector_name: r_collector_name,
        //   date: r_date,
        //   time: r_time,
        //   seal: r_seal,
        //   remark: r_remark,
        //   released_to: r_released_to,
        //   primary_specimen_expiration: primary_specimen_expiration,
        //   split_specimen_expiration: split_specimen_expiration,
        // },
      };
      console.log(">>>>>>>>>", postData);
      await HttpClient.apiCaller("admin/test", "POST", postData, token, null)
        .then((res) => {
          // console.log("<><><>", res);
          Utility.sToast("Report saved successfully");
          onClose();
        })
        .catch((err) => {
          Utility.eToast(err?.response?.data?.error);
          console.log("?????", err);
        })
        .finally(() => setSpin(false));
    }
  };

  return (
    <>
      <div className="">
        <div className=" ">
          <div className="p-5 ml-3 bg-white shadow h-fit">
            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-2" onClick={onClose}>
                <PreviousButton />
              </div>
              <div
                className="col-span-10"
                style={{ fontSize: "1.5rem", fontWeight: "500" }}
              >
                NON-FEDERAL DRUG TESTING CUSTODY AND CONTROL FORM
              </div>

              <div className="col-span-2">
                <img src={logoo} alt="" className="h-12" />
              </div>
              <div
                className={
                  window.innerWidth <= 600 ? "col-span-5" : "col-span-3"
                }
              >
                <div style={{ color: "rgb(156 165 174)" }}>
                  REQUISITION SPECIMEN ID NO.
                </div>
                <div style={{ position: "relative" }}>
                  <input
                    type="text"
                    className="border-2 focus:outline-none rounded-lg w-full "
                    value={specimen_id}
                    onChange={(e) => {
                      set_specimen_id(e.target.value);
                    }}
                  />
                  {/* <LuPencil
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                  /> */}
                </div>
              </div>
              <div
                className={
                  window.innerWidth <= 600 ? "col-span-5" : "col-span-3"
                }
              >
                <div style={{ color: "rgb(156 165 174)" }}>ACCESSION NO.</div>
                <div style={{ position: "relative" }}>
                  <input
                    type="text"
                    className="border-2 focus:outline-none rounded-lg w-full "
                    value={accession_no}
                    onChange={(e) => {
                      set_accession_no(e.target.value);
                    }}
                    disabled
                  />
                  {/* <LuPencil
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* step-1 containt */}
        <div className=" mt-1">
          <div className="p-6 ml-3 bg-white shadow h-fit">
            {/* A and B section */}
            <p className="poppins-medium text-[18px] mb-3">
              Step 1: Completed By Collector Or Employer Representative
            </p>
            <div className="border border-blue-200 p-4 rounded-lg">
              <div className="grid sm:grid-cols-12 sm:gap-12 gap-4">
                <div className="sm:col-span-6 col-span-6">
                  <h1 className="text-[#919AA3] poppins-medium">A. Employer</h1>
                  <div>
                    <p className="poppins-medium">Employer name</p>
                    <input
                      type="text"
                      placeholder="Enter Employer name"
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={employer_name}
                      onChange={(e) => {
                        set_employer_name(e.target.value);
                      }}
                    />
                    <p className="font-medium">Employer code</p>
                    <input
                      type="text"
                      placeholder="Enter Employer code"
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={employer_code}
                      onChange={(e) => set_employer_code(e.target.value)}
                    />
                    <p className="font-medium">Address</p>
                    <input
                      type="text"
                      placeholder="Enter address"
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={employer_address}
                      onChange={(e) => set_employer_address(e.target.value)}
                    />
                    <p className="font-medium">ID No</p>
                    <input
                      type="text"
                      placeholder="Enter ID No"
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={employer_id}
                      onChange={(e) => set_employer_id(e.target.value)}
                    />
                  </div>
                </div>
                <div className="sm:col-span-6 col-span-6">
                  <h1 className="text-[#919AA3] font-semibold font-Poppins">
                    B. MRO
                  </h1>
                  <div>
                    <p className="font-medium mb-3">Select MRO</p>
                    <select
                      id="countries"
                      className="custom-input"
                      // className="bg-[#ECF1F6] h-10 border-none focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                      value={mro_name}
                      onChange={(e) => set_mro_name(e.target.value)}
                    >
                      <option
                        selected
                        // className="text-[#919AA3] font-semibold font-Poppins w-[5rem]"
                        style={{}}
                      >
                        MRO
                      </option>
                      {finalData?.map((item, i) => (
                        <>
                          <option
                            value={item?._id}
                            key={i}
                          >{`${item?.first_name} ${item?.last_name}`}</option>
                        </>
                      ))}
                    </select>

                    <p className="font-medium mt-2">Address</p>
                    <input
                      type="text"
                      placeholder="Enter Address"
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={mro_addr}
                      // value={phyMisc?.address}
                      onChange={(e) => set_mro_addr(e.target.value)}
                    />
                    <p className="font-medium">Phone</p>
                    <input
                      type="text"
                      placeholder="Enter Phone Number"
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={mro_phone}
                      // value={phyMisc?.mobile_number}
                      onChange={(e) => set_mro_phone(e.target.value)}
                    />
                    <p className="font-medium">Fax No.</p>
                    <input
                      type="text"
                      placeholder="Enter Fax No."
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={mro_fax}
                      // value={phyMisc?.faxNo}
                      onChange={(e) => set_mro_fax(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="dotted-line mt-5"></div>

              {/* C section */}
              <div className="mt-3">
                <h1 className="text-[#919AA3] font-semibold font-Poppins">
                  C. Donor
                </h1>
                <div className="grid grid-cols-12 sm:gap-5 gap-3 mt-3">
                  <div className="sm:col-span-6 col-span-12">
                    <p className="font-medium">First Name</p>
                    <input
                      type="text"
                      placeholder="Enter First Name"
                      className="w-full mt-3 h-11 mb-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={donor_first_name}
                      onChange={(e) => set_donor_first_name(e.target.value)}
                    />
                  </div>
                  <div className="sm:col-span-6 col-span-12">
                    <p className="font-medium">Last Name</p>
                    <input
                      type="text"
                      placeholder="Enter Last Name"
                      className="w-full mt-3 h-11 mb-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={donor_last_name}
                      onChange={(e) => set_donor_last_name(e.target.value)}
                    />
                  </div>
                  <div className="sm:col-span-6 col-span-12">
                    <p className="font-medium">Donor I.D No.</p>
                    <input
                      type="text"
                      placeholder="Enter ID or Number"
                      className="w-full mt-3 h-11 mb-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={donor_id}
                      onChange={(e) => set_donor_id(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="dotted-line mt-5"></div>

              {/* D section */}

              <div className="bg-[#F4F6F9] p-3 mt-3 ">
                <h1 className="text-[#919AA3] font-semibold font-Poppins">
                  D. Donor ID Verification
                </h1>
                <div className="mt-2 grid grid-cols-12 gap-4 mb-3 items-center">
                  <div className="sm:col-span-2 col-span-12">
                    <input
                      type="checkbox"
                      id="photoid"
                      name="photoid"
                      value="true"
                      className="mr-2 p-1"
                      onChange={handleId}
                    />
                    <label
                      htmlFor="photoid"
                      className="font-normal font-Poppins text-[#919AA2]"
                    >
                      Photo ID
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-2 col-span-12">
                    <input
                      type="checkbox"
                      id="employer"
                      name="employer"
                      value="true"
                      className="mr-2 p-1"
                      onChange={handleIdd}
                    />
                    <label
                      htmlFor="employer"
                      className="font-normal font-Poppins text-[#919AA2]"
                    >
                      Employer
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-6 col-span-12">
                    <input
                      type="text"
                      placeholder="Representative"
                      className="w-full mt-3 h-11 border-none focus:outline-none mb-2 rounded-lg bg-[#E8EEF3] p-2"
                      value={photo_id}
                      onChange={(e) => set_photo_id(e.target.value)}
                    />
                    <span style={{ fontSize: "0.8rem", color: "blue" }}></span>
                  </div>
                </div>
              </div>

              {/*E section */}

              <div className="bg-[#ECF1F6] p-3 mt-5 ">
                <h1 className="text-[#919AA3] font-semibold font-Poppins">
                  E. Reason For Test :
                </h1>
                <div className="grid grid-cols-12 gap-4 mt-4">
                  <div className="sm:col-span-3 col-span-12 gap-3">
                    <input
                      type="radio"
                      id="pre_employment"
                      name="test_reason"
                      value="pre_employment"
                      checked={test_reason === "pre_employment"}
                      onChange={testReason}
                    />
                    <label htmlFor="pre_employment" className="ml-2">
                      Pre-Employement(1)
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12 gap-3">
                    <input
                      type="radio"
                      id="random"
                      name="test_reason"
                      value="random"
                      checked={test_reason === "random"}
                      onChange={testReason}
                    />
                    <label htmlFor="random" className="ml-2">
                      Random(3)
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12 gap-3">
                    <input
                      type="radio"
                      id="resonable_suspicion"
                      name="test_reason"
                      value="resonable_suspicion"
                      checked={test_reason === "resonable_suspicion"}
                      onChange={testReason}
                    />
                    <label htmlFor="resonable_suspicion" className="ml-2">
                      Resonable Suspicion/clause(5)
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12 gap-3">
                    <input
                      type="radio"
                      id="post_accident"
                      name="test_reason"
                      value="post_accident"
                      checked={test_reason === "post_accident"}
                      onChange={testReason}
                    />
                    <label htmlFor="post_accident" className="ml-2">
                      Post-Accident(2)
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12 gap-3 items-center">
                    <input
                      type="radio"
                      id="promotion"
                      name="test_reason"
                      value="promotion"
                      checked={test_reason === "promotion"}
                      onChange={testReason}
                    />
                    <label htmlFor="promotion" className="ml-2">
                      Promotion(22)
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12 gap-3 items-center">
                    <input
                      type="radio"
                      id="return_duty"
                      name="test_reason"
                      value="return_duty"
                      checked={test_reason === "return_duty"}
                      onChange={testReason}
                    />
                    <label htmlFor="return_duty" className="ml-2">
                      Return to Duty(6)
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12 gap-3 items-center">
                    <input
                      type="radio"
                      id="follow_up"
                      name="test_reason"
                      value="follow_up"
                      checked={test_reason === "follow_up"}
                      onChange={testReason}
                    />
                    <label htmlFor="follow_up" className="ml-2">
                      Follow-up(23)
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="text"
                      placeholder="Other Specificity(99)"
                      className="w-full  h-10 mb-2 rounded-lg border-none focus:outline-none bg-[#D7E0EE] p-2"
                      value={reason_remark}
                      onChange={(e) => set_reason_remark(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              {/* f section  */}

              <div className="bg-[#F4F6F9] p-3 mt-5 ">
                <h1 className="text-[#919AA3] font-semibold font-Poppins">
                  F. Drug Test To Be Performed
                </h1>
                <div className=" w-full flex items-center p-3 rounded-md mt-5 h-10 mb-2 bg-[#1db6fa] text-white ">
                  <h1>DRUG TEST REQUEST</h1>
                </div>
                <div className="grid grid-cols-12 gap-4 mt-4">
                  <div className="sm:col-span-6 col-span-12">
                    <p className="font-medium">Select A Pannel Name</p>
                    {/* <select
                      id="countries"
                      className="bg-[#E8EEF3] mt-4 border-none focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                      value={custom_drug_test}
                      onChange={(e) => set_custom_drug_test(e.target.value)}
                    >
                      <option selected className="text-[#F3F7FA] bg-[#9facb6] ">
                        Please select a custom drug test
                      </option>
                      <option value="US">United States</option>
                      <option value="CA">Canada</option>
                      <option value="FR">France</option>
                      <option value="DE">Germany</option>
                    </select> */}
                    <Multiselect
                      options={drugPanelData}
                      displayValue="panelName"
                      placeholder="Select panel name"
                      onSelect={handleSelect}
                    />
                  </div>
                  <div className="sm:col-span-6 col-span-12">
                    <p className="font-medium">Additional Drug Test</p>
                    {/* <select
                      id="countries"
                      className="bg-[#E8EEF3] mt-4 border-none focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                      value={additional_drug_test}
                      onChange={(e) => set_additional_drug_test(e.target.value)}
                    >
                      <option selected className="text-[#F3F7FA] bg-[#F3F7FA]">
                        Please select a custom panel name
                      </option>
                      <option value="US">United States</option>
                      <option value="CA">Canada</option>
                      <option value="FR">France</option>
                      <option value="DE">Germany</option>
                    </select> */}
                    <Multiselect
                      options={drugNameData}
                      displayValue="drugName"
                      placeholder="Select additional drug test"
                      onSelect={handleSelectt}
                    />
                  </div>
                </div>
                {/* <textarea
                  name=""
                  id=""
                  rows="3"
                  className="mt-6 w-full bg-[#E8EEF3] p-3 border-none focus:outline-none"
                ></textarea> */}
              </div>

              {/* G section  */}

              <h1 className="text-[#919AA3] mt-4 font-semibold font-Poppins">
                G. Collection Site
              </h1>

              <div className="grid grid-cols-12 mt-4 sm:gap-5 gap-2">
                <div className="sm:col-span-6 col-span-12">
                  <p className="font-medium">Collection Site Name</p>
                  <input
                    type="text"
                    placeholder="Enter collection site name"
                    className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
                    value={site_name}
                    onChange={(e) => set_site_name(e.target.value)}
                  />
                  {/* <select
                    name="mro"
                    id="mro"
                    value={site_name}
                    onChange={(e) => set_site_name(e.target.value)}
                    className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
                  >
                    <option value="">Select Collectionsite</option>
                    {sites?.map((item, i) => (
                      <option value={item?._id} key={i}>
                        {item?.first_name}
                      </option>
                    ))}
                  </select> */}
                </div>
                <div className="sm:col-span-6 col-span-12">
                  <p className="font-medium">Collection Site Phone</p>
                  <input
                    type="text"
                    placeholder="Enter collection site phone number"
                    className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
                    value={site_phone}
                    onChange={(e) => set_site_phone(e.target.value)}
                  />
                </div>
                <div className="sm:col-span-6 col-span-12">
                  <p className="font-medium">Address</p>
                  <input
                    type="text"
                    placeholder="Enter address"
                    className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
                    value={site_addr}
                    onChange={(e) => set_site_addr(e.target.value)}
                  />
                </div>
                <div className="sm:col-span-6 col-span-12">
                  <p className="font-medium">Collection site fax No.</p>
                  <input
                    type="text"
                    placeholder="Enter collection site fax no."
                    className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
                    value={site_fax}
                    onChange={(e) => set_site_fax(e.target.value)}
                  />
                </div>
                <div className="sm:col-span-6 col-span-12">
                  <p className="font-medium">
                    City, Provinance And Postal Code
                  </p>
                  <input
                    type="text"
                    placeholder="Enter city, provinance and postal code"
                    className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
                    value={site_pin}
                    onChange={(e) => set_site_pin(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* step 2 */}
            <p className=" mt-4 poppins-bold text-[18px]">
              Step 2 : Completed By Collector
            </p>
            <div className="flex flex-row gap-4 mt-4">
              <div className="sm:col-span-1 col-span-6">
                <input
                  type="radio"
                  id="blood"
                  value="blood"
                  name="testType"
                  checked={test_type === "blood"}
                  onChange={testType}
                  className="mr-2"
                />
                <label htmlFor="blood" className="poppins-medium">
                  Blood
                </label>
              </div>

              <div className="sm:col-span-1 col-span-6">
                <input
                  type="radio"
                  id="urin"
                  value="urine"
                  name="testType"
                  checked={test_type === "urine"}
                  onChange={testType}
                  className="mr-2"
                />
                <label htmlFor="urin" className="poppins-medium">
                  Urine
                </label>
              </div>

              <div className="sm:col-span-1 col-span-6">
                <input
                  type="radio"
                  id="oral_fluid"
                  value="oral_fluid"
                  name="testType"
                  checked={test_type === "oral_fluid"}
                  onChange={testType}
                  className="mr-2"
                />
                <label htmlFor="oral_fluid" className="poppins-medium">
                  Oral Fluid
                </label>
              </div>
            </div>
            {/* changeable part */}
            {test_type === "blood" && (
              <div className="border border-blue-200 p-4 rounded-lg mt-5">
                <div className=" w-full flex items-center p-3 rounded-md mt-4 h-10 mb-3 bg-[#1db6fa] text-white ">
                  <h1 className="poppins-medium">Collections :</h1>
                </div>
                <div className="grid grid-cols-12 mt-4 ">
                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="single"
                      value="single"
                      checked={collection_type === "single"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label htmlFor="single" className="ml-2 poppins-regular">
                      Single
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="split"
                      value="split"
                      checked={collection_type === "split"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label htmlFor="split" className="ml-2 poppins-regular">
                      Split
                    </label>
                  </div>

                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="radio"
                      id="non-provided"
                      value="non-provided"
                      checked={collection_type === "non-provided"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label
                      htmlFor="non-provided"
                      className="ml-2 poppins-regular"
                    >
                      Non-Provided
                    </label>
                  </div>

                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="radio"
                      id="observed"
                      value="observed"
                      checked={collection_type === "observed"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label htmlFor="observed" className="ml-2 poppins-regular">
                      Observed
                    </label>
                  </div>
                </div>

                <div className="grid grid-cols-12 mt-3 gap-3">
                  <div className="sm:col-span-6 col-span-12">
                    <div className=" col-span-12  w-full flex items-center sm:p-5 p-[7vw] rounded-md mt-3 h-10 mb-3 bg-[#1db6fa] text-white poppins-medium ">
                      <h1>Each Device Within Expiration Date ?</h1>
                    </div>
                    <div className="grid grid-cols-12 ">
                      <div className="sm:col-span-2 col-span-6">
                        <input
                          type="radio"
                          id="true"
                          value="true"
                          checked={expiration_date === "true"}
                          onChange={expired}
                          name="expDt"
                        />
                        <label htmlFor="true" className="ml-2 poppins-regular">
                          Yes
                        </label>
                      </div>

                      <div className="sm:col-span-4 col-span-6">
                        <input
                          type="radio"
                          id="false"
                          value="false"
                          checked={expiration_date === "false"}
                          onChange={expired}
                          name="expDt"
                        />
                        <label htmlFor="false" className="ml-2 poppins-regular">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="sm:col-span-6 col-span-12">
                    <div>
                      <div className=" w-full flex items-center sm:p-3 p-[7vw] rounded-md mt-3 h-10 mb-3 bg-[#1db6fa] text-white poppins-medium">
                        <h1>Volume Indicator(s) Observed</h1>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="true"
                          value="true"
                          checked={volume_indicator === "true"}
                          onChange={volumeIndicator}
                          name="volumeindicator"
                        />
                        <label htmlFor="true" className="poppins-regular mr-2">
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <textarea
                  name=" "
                  id=""
                  rows="3"
                  placeholder="Remark"
                  className="w-full bg-[#E8EEF3] mt-5 rounded-md p-3 border-none focus:outline-none poppins-regular"
                  value={remark}
                  onChange={(e) => set_remark(e.target.value)}
                ></textarea>
              </div>
            )}
            {test_type === "urine" && (
              <div className="border border-blue-200 p-4 rounded-lg mt-5">
                <div
                  className=" w-full flex items-center p-3 rounded-md mt-4 h-10 mb-3 bg-[#1075EC] text-white "
                  style={{ background: "#1db6fa" }}
                >
                  <h1 className="poppins-medium">Collections :</h1>
                </div>
                <div className="grid grid-cols-12 mt-4 ">
                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="single"
                      value="single"
                      checked={collection_type === "single"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label htmlFor="single" className="ml-2 poppins-regular">
                      Single
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="split"
                      value="split"
                      checked={collection_type === "split"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label htmlFor="split" className="ml-2 poppins-regular">
                      Split
                    </label>
                  </div>

                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="radio"
                      id="non-provided"
                      value="non-provided"
                      checked={collection_type === "non-provided"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label
                      htmlFor="non-provided"
                      className="ml-2 poppins-regular"
                    >
                      Non-Provided
                    </label>
                  </div>

                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="radio"
                      id="observed"
                      value="observed"
                      checked={collection_type === "observed"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label htmlFor="observed" className="ml-2 poppins-regular">
                      Observed
                    </label>
                  </div>
                </div>
                <div
                  className=" w-full flex items-center p-3 rounded-md mt-5 h-10 mb-3 bg-[#1075EC] text-white "
                  style={{ background: "#1db6fa" }}
                >
                  <h1 className="poppins-medium">
                    Urine : Read Specimen Temperature With 4 Minutes. Is
                    Temperature Between 32°C And 38°C?
                  </h1>
                </div>
                <div className="grid grid-cols-12 mt-5 gap-3">
                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="yes"
                      value="yes"
                      name="urinetemp"
                      checked={urine_temperature === "yes"}
                      onChange={urine}
                      className="mr-2"
                    />
                    <label htmlFor="yes" className="ml-2 poppins-regular">
                      Yes
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="no"
                      checked={urine_temperature === "no"}
                      value="no"
                      onChange={urine}
                      name="urinetemp"
                      className="mr-2"
                    />
                    <label htmlFor="no" className="ml-2 poppins-regular">
                      No
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="observed"
                      checked={urine_temperature === "observed"}
                      value="observed"
                      onChange={urine}
                      name="urinetemp"
                      className="mr-2"
                    />
                    <label htmlFor="observed" className="ml-2 poppins-regular">
                      Observed
                    </label>
                  </div>
                </div>

                <textarea
                  name=" "
                  id=""
                  rows="3"
                  placeholder="Remark"
                  className="w-full bg-[#E8EEF3] mt-5 rounded-md p-3 border-none focus:outline-none poppins-regular"
                  value={remark}
                  onChange={(e) => set_remark(e.target.value)}
                ></textarea>
              </div>
            )}
            {test_type === "oral_fluid" && (
              <div className="border border-blue-200 p-4 rounded-lg mt-5">
                <div
                  className=" w-full flex items-center p-3 rounded-md mt-4 h-10 mb-3 bg-[#1075EC] text-white "
                  style={{ background: "#1db6fa" }}
                >
                  <h1 className="poppins-medium">Collections :</h1>
                </div>
                <div className="grid grid-cols-12 mt-4 ">
                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="single"
                      value="single"
                      checked={collection_type === "single"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label htmlFor="single" className="ml-2 poppins-regular">
                      Single
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="split"
                      value="split"
                      checked={collection_type === "split"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label htmlFor="split" className="ml-2 poppins-regular">
                      Split
                    </label>
                  </div>

                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="radio"
                      id="non-provided"
                      value="non-provided"
                      checked={collection_type === "non-provided"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label
                      htmlFor="non-provided"
                      className="ml-2 poppins-regular"
                    >
                      Non-Provided (Enter Remark)
                    </label>
                  </div>

                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="radio"
                      id="observed"
                      value="observed"
                      checked={collection_type === "observed"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label htmlFor="observed" className="ml-2 poppins-regular">
                      Observed (Enter Remark)
                    </label>
                  </div>
                </div>
                <div
                  className=" w-full flex items-center p-3 rounded-md mt-5 h-10 mb-3 bg-[#1075EC] text-white "
                  style={{ background: "#1db6fa" }}
                >
                  <h1 className="poppins-medium">Oral fluid: Split Type:</h1>
                </div>
                <div className="grid grid-cols-12 mt-5 gap-3">
                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="Serial"
                      value="Serial"
                      checked={split_type === "Serial"}
                      onChange={splitType}
                      name="oralfluid"
                    />
                    <label htmlFor="Serial" className="ml-2 poppins-regular">
                      Serial
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="concurrent"
                      value="concurrent"
                      checked={split_type === "concurrent"}
                      onChange={splitType}
                      name="oralfluid"
                    />
                    <label
                      htmlFor="concurrent"
                      className="ml-2 poppins-regular"
                    >
                      Concurrent
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="subdivided"
                      value="subdivided"
                      checked={split_type === "subdivided"}
                      onChange={splitType}
                      name="oralfluid"
                    />
                    <label
                      htmlFor="subdivided"
                      className="ml-2 poppins-regular"
                    >
                      Subdivided
                    </label>
                  </div>
                </div>
                <div className="grid grid-cols-12 mt-3 gap-3">
                  <div className="sm:col-span-6 col-span-12">
                    <div
                      className=" col-span-12  w-full flex items-center sm:p-5 p-[7vw] rounded-md mt-3 h-10 mb-3 bg-[#1075EC] text-white poppins-medium "
                      style={{ background: "#1db6fa" }}
                    >
                      <h1>Each Device Within Expiration Date ?</h1>
                    </div>
                    <div className="grid grid-cols-12 ">
                      <div className="sm:col-span-2 col-span-6">
                        <input
                          type="radio"
                          id="true"
                          value="true"
                          checked={expiration_date === "true"}
                          onChange={expired}
                          name="expDt"
                        />
                        <label htmlFor="true" className="ml-2 poppins-regular">
                          Yes
                        </label>
                      </div>

                      <div className="sm:col-span-4 col-span-6">
                        <input
                          type="radio"
                          id="false"
                          value="false"
                          checked={expiration_date === "false"}
                          onChange={expired}
                          name="expDt"
                        />
                        <label htmlFor="false" className="ml-2 poppins-regular">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="sm:col-span-6 col-span-12">
                    <div>
                      <div
                        className=" w-full flex items-center sm:p-3 p-[7vw] rounded-md mt-3 h-10 mb-3 bg-[#1075EC] text-white poppins-medium"
                        style={{ background: "#1db6fa" }}
                      >
                        <h1>Volume Indicator(s) Observed</h1>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="true"
                          value="true"
                          checked={volume_indicator === "true"}
                          onChange={volumeIndicator}
                          name="volumeindicator"
                        />
                        <label htmlFor="true" className="poppins-regular mr-2">
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <textarea
                  name=" "
                  id=""
                  rows="3"
                  placeholder="Remark"
                  className="w-full bg-[#E8EEF3] mt-5 rounded-md p-3 border-none focus:outline-none poppins-regular"
                  value={remark}
                  onChange={(e) => set_remark(e.target.value)}
                ></textarea>
              </div>
            )}

            <div className="grid grid-cols-1 mt-5">
              <p className="font-Poppins font-medium mb-4">
                Step 3 : Collector affixes bottle seal(s) to bottle(s).
                Collector dates seal(s). Donor initials seal(s). Donor Completes
                step5.
              </p>
              <p className="font-Poppins font-medium text-[18px] ">
                Step 4 : Chain of Custody - Initiated by Collector and Completed
                by Laboratory
              </p>
            </div>

            {/* step-4 */}
            <div className="border border-blue-200 p-4 rounded-lg mt-5">
              <p className="grid grid-cols-1 w-full mt-3">
                I certify that the specimen given to me by the donor identified
                in the certification section on copy 1 of this form was
                collected,labeled,sealed and released to the Delivery Service
                noted in accordance with application requirement
              </p>
              <div className="mt-10 grid grid-cols-12 gap-5 ">
                <div className="sm:col-span-5 col-span-12">
                  <div className="grid grid-cols-1">
                    <div>
                      <b>Signature Of The Collector</b>
                      <div className="relative">
                        <SignatureCanvas
                          ref={collectorSignRef}
                          penColor="black"
                          canvasProps={{
                            className: "signature-canvas",
                            width: 400,
                            height: 200,
                          }}
                          className="border border-solid border-black mb-4"
                        />
                        {/* Draw the line below the signature */}
                        <div className="absolute bottom-0 left-0 w-[25vw] border-b border-black"></div>
                      </div>
                      <div className="w-[25vw] flex justify-end mt-2 items-center gap-3">
                        <button
                          onClick={handleCollectorSignClear}
                          className="bg-[#E8EEF3] px-2 py-1 rounded poppins-medium hover:opacity-90"
                        >
                          clear
                        </button>
                        {/* <button
                          onClick={handleCollectorSignSave}
                          className="bg-theme-blue text-white rounded px-2 py-1 poppins-medium hover:opacity-90"
                          style={{ background: "#1db6fa" }}
                        >
                          {load ? (
                            <svg
                              aria-hidden="true"
                              class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-purple-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          ) : (
                            "save"
                          )}
                        </button> */}
                      </div>
                    </div>

                    <p className="mt-20 font-medium">
                      Collector's Name (First,Middle,Last)
                    </p>
                    <input
                      type="text"
                      placeholder="Enter Full name"
                      className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                      value={collector_name}
                      onChange={(e) => set_collector_name(e.target.value)}
                    />
                  </div>
                  <div className="grid grid-cols-1">
                    <div>
                      <p className="font-medium">Select Date</p>
                      <input
                        type="date"
                        // value={date}
                        // onChange={(e) => setDate(e.target.value)}
                        className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                        value={collection_date}
                        onChange={(e) => set_collection_date(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-12 gap-4">
                    <div className="sm:col-span-6 col-span-12">
                      <p className="font-medium">Time of collection</p>
                      <input
                        type="time"
                        // value={time}
                        // onChange={(e) => setTime(e.target.value)}
                        className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 mr-2 border-none focus:outline-none"
                        value={collection_time}
                        onChange={(e) => set_collection_time(e.target.value)}
                      />
                    </div>
                    <div
                      onClick={handleStep4Date}
                      className="sm:col-span-6 mt-3 col-span-12"
                    >
                      <p className="mt-[-8px] font-medium ">Choose Default</p>
                      <div className="mt-2.5 col-span-12 w-full">
                        <button
                          className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white"
                          style={{ background: "#1db6fa" }}
                        >
                          Today's Date & Time
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-1 sm:block hidden">
                  <div class="w-1 h-[500px] border-l-5 border-l-2 border-sienna  border-dotted"></div>
                </div>
                <div className="sm:col-span-5 col-span-12 sm:ml-[-4vw]">
                  <h1 className="font-Poppins font-medium text-[19px] mb-4">
                    Specimen Bottle(s) Release To:
                  </h1>
                  <div className="mt-5 flex mb-3">
                    <div className="flex gap-3">
                      <input
                        type="radio"
                        id="DHL"
                        name="specimenbottle"
                        value="DHL"
                        checked={released_to === "DHL"}
                        onChange={releasedTo}
                      />
                      <label htmlFor="DHL">DHL</label>
                      <br />
                    </div>
                    <div className="flex gap-3">
                      <input
                        type="radio"
                        id="Purolator"
                        name="specimenbottle"
                        value="Purolator"
                        checked={released_to === "Purolator"}
                        onChange={releasedTo}
                      />
                      <label htmlFor="Purolator">Purolator</label>
                      <br />
                    </div>
                    <div className="flex gap-3">
                      <input
                        type="radio"
                        id="FEDEX"
                        name="specimenbottle"
                        value="FEDEX"
                        checked={released_to === "FEDEX"}
                        onChange={releasedTo}
                      />
                      <label htmlFor="FEDEX">FEDEX</label>
                    </div>
                  </div>

                  <input
                    type="text"
                    placeholder="Other's"
                    className="sm:w-[38vw] w-full h-10 mt-5 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    value={otherCourier}
                    onChange={(e) => setOtherCourier(e.target.value)}
                  />
                  <h1 className="font-medium" style={{ marginTop: "2rem" }}>
                    Name of Delivery Service Transferring Specimen to Lab
                  </h1>
                </div>
              </div>

              <div className="dotted-line mt-7 text-gray-300"></div>

              {/*              <h2 className=" mt-2 sm:absolute relative  font-Poppins font-medium text-[19px]">
                Recieved At Lab :
              </h2>
              <div className="mt-16 grid grid-cols-12 sm:gap-2 gap-5">
                <div className="sm:col-span-5 col-span-12">
                  <div className="grid grid-cols-1">
                    <div>
                      <b>Signature of Accessioner</b>
                      <div className="relative">
                        <SignatureCanvas
                          ref={collectionSiteSignRef}
                          penColor="black"
                          canvasProps={{
                            className: "signature-canvas",
                            width: 400,
                            height: 200,
                          }}
                          className="border border-solid border-black mb-4"
                        />
                        <div className="absolute bottom-0 left-0 w-[25vw] border-b border-black"></div>
                      </div>
                      <div className="w-[25vw] flex justify-end mt-2 items-center gap-3">
                        <button
                          onClick={handleCollectionSiteSignClear}
                          className="bg-[#E8EEF3] px-2 py-1 rounded poppins-medium hover:opacity-90"
                        >
                          clear
                        </button>
                        
                      </div>
                    </div>
                    <p className="mt-10 font-medium">
                      Accessioner's Name(First, Middle, Last)
                    </p>
                    <input
                      type="text"
                      placeholder="Enter Full name"
                      className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                      value={r_collector_name}
                      onChange={(e) => set_r_collector_name(e.target.value)}
                    />
                  </div>

                  <div className="grid grid-cols-12 gap-4">
                    <div className="sm:col-span-6 col-span-12">
                      <p className="font-medium">Select Date</p>
                      <input
                        type="date"
                        // value={accessinerdate}
                        // onChange={(e) => setTime(e.target.value)}
                        className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 mr-2 border-none focus:outline-none"
                        value={r_date}
                        onChange={(e) => set_r_date(e.target.value)}
                      />
                    </div>
                    <div
                      onClick={handleStepLabDate}
                      className="sm:col-span-6 mt-3 col-span-12"
                    >
                      <p className="mt-[-8px] font-medium ">Choose Default</p>
                      <div className="mt-2.5 col-span-12 w-full">
                        <button
                          className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white"
                          style={{ background: "#1db6fa" }}
                        >
                          Today's Date
                        </button>
                      </div>
                    </div>
                  </div>
                 
                </div>
                <div className="col-span-1"></div>
                <div className="sm:col-span-6 col-span-12 sm:mt-[-8vh] sm:ml-[-4vw]">
                  <h1 className="font-Poppins font-medium text-[19px] mb-4">
                    Primary Specimen Bottle Seal Intact
                  </h1>
                  <div className="mt-5 flex gap-5 mb-3">
                    <div className="flex gap-3">
                      <input
                        type="radio"
                        id="true"
                        name="prmspice"
                        value="true"
                        checked={r_seal === "true"}
                        onChange={sealed}
                      />
                      <label htmlFor="true">Yes</label>
                    </div>
                    <div className="flex gap-3">
                      <input
                        type="radio"
                        id="false"
                        name="prmspice"
                        value="false"
                        checked={r_seal === "false"}
                        onChange={sealed}
                      />
                      <label htmlFor="false">No</label>
                    </div>
                  </div>
                  <textarea
                    name=""
                    id=""
                    rows="3"
                    placeholder="Remark"
                    className="w-full p-3 mt-3 rounded-lg bg-[#ECF1F6]  border-none focus:outline-none relative"
                    value={r_remark}
                    onChange={(e) => set_r_remark(e.target.value)}
                  ></textarea>
                  <h1 className="font-medium mt-5">
                    Specimen Bottles Released To :
                  </h1>
                  <input
                    type="text"
                    placeholder="Other's"
                    className="w-full h-10 mt-5 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    value={r_released_to}
                    onChange={(e) => set_r_released_to(e.target.value)}
                  />
                </div>
              </div>

              <div className="dotted-line mt-9 mb-4"></div>
              <h1 className="w-full mb-3  font-medium">
                Primary/Single Specimen Device Expiration Date :
              </h1>
              <div className="grid grid-cols-12 gap-6">
                <div className="sm:col-span-6 grid grid-cols-12 col-span-12 gap-2">
                  <div className="sm:col-span-6 col-span-11">
                    <p className="font-medium">Select Date</p>
                    <input
                      type="date"
                      // value={singlespecimendate}
                      // onChange={(e) => setTime(e.target.value)}
                      className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 mr-2 border-none focus:outline-none"
                      value={primary_specimen_expiration}
                      onChange={(e) =>
                        set_primary_specimen_expiration(e.target.value)
                      }
                    />
                  </div>
                  <div
                    onClick={handleSplitExpDate}
                    className="sm:col-span-6 mt-3 col-span-11"
                  >
                    <p className="mt-[-8px] font-medium ">Choose Default</p>
                    <div className="mt-2.5 sm:col-span-6 col-span-12">
                      <button
                        className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white"
                        style={{ background: "#1db6fa" }}
                      >
                        Today's date
                      </button>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-6 col-span-12">
                  <div>
                    <h1 className="w-full sm:mt-[-5vh] font-medium">
                      Split Specimen Device Expiration Date :
                    </h1>
                  </div>
                  <div className="grid grid-cols-12 gap-5 mt-3">
                    <div className="sm:col-span-6 col-span-11">
                      <p className="font-medium">Select Date</p>
                      <input
                        type="date"
                        // value={splitspecimendate}
                        // onChange={(e) => setDate(e.target.value)}
                        className="w-full relative h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                        value={split_specimen_expiration}
                        onChange={(e) =>
                          set_split_specimen_expiration(e.target.value)
                        }
                      />
                    </div>

                    <div
                      onClick={handleSplitExpDate}
                      className="sm:col-span-6 sm:mt-6 col-span-11  items-center"
                    >
                      <p className="sm:mt-[-3vh] font-medium  ">
                        Choose Default
                      </p>
                      <div className="mt-2.5 sm:col-span-6 col-span-12">
                        <button
                          className="p-1.5 pl-7 pr-7 rounded-lg w-full  bg-blue-600 font-normal text-white"
                          style={{ background: "#1db6fa" }}
                        >
                          Today's date
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
               </div> */}
            </div>
            <div className=" mt-7 mb-7 font-medium text-[20px] font-Poppins">
              <h1>Step 5: Complete by Donor</h1>
            </div>

            {/* step-5 */}
            <div className="border border-blue-200 p-4 rounded-lg">
              <h1 className="mb-6 grid grid-cols-1 mt-1">
                I certify that I provided my specimen to the collector; that I
                have not adulterated it in any manner; each specimen bottle used
                was sealed with a tamper-evident seal in my presence; and that
                the information and numbers provided on this form and on the
                label affixed to each specimen bottle is correct; and allow my
                sample and data to leave the country, if required.
              </h1>
              <div className="grid grid-cols-1">
                <b>Signature of Donor</b>
                <div className="relative">
                  <SignatureCanvas
                    ref={accessionerSignRef}
                    penColor="black"
                    canvasProps={{
                      className: "signature-canvas",
                      width: 400,
                      height: 200,
                    }}
                    className="border border-solid border-black mb-4"
                  />
                  {/* Draw the line below the signature */}
                  <div className="absolute bottom-0 left-0 w-[25vw] border-b border-black"></div>
                </div>
                <div className="w-[25vw] flex justify-end mt-2 items-center gap-3">
                  <button
                    onClick={handleAccessionerSignClear}
                    className="bg-[#E8EEF3] px-2 py-1 rounded poppins-medium hover:opacity-90"
                  >
                    clear
                  </button>
                  {/* <button
                    onClick={handleAccessionerSignSave}
                    className="bg-theme-blue text-white rounded px-2 py-1 poppins-medium hover:opacity-90"
                    style={{ background: "#1db6fa" }}
                  >
                    {load ? (
                      <svg
                        aria-hidden="true"
                        class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-purple-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    ) : (
                      "save"
                    )}
                  </button> */}
                </div>
              </div>
              <div className="grid grid-cols-12 mt-5 sm:gap-7 gap-3">
                <div className="sm:col-span-3 col-span-12">
                  <p className=" font-medium mb-2">Daytime phone</p>
                  <input
                    type="text"
                    placeholder="Enter day time phone number"
                    className="w-full h-10 mb-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    value={donor_day_no}
                    onChange={(e) => set_donor_day_no(e.target.value)}
                  />
                </div>
                <div className="sm:col-span-3 col-span-12">
                  <p className=" font-medium mb-2"> Evening phone</p>
                  <input
                    type="text"
                    placeholder="Evening phone number"
                    className="w-full h-10 mb-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    value={donor_eve_no}
                    onChange={(e) => set_donor_eve_no(e.target.value)}
                  />
                </div>
                <div className="sm:col-span-3 col-span-12">
                  <p className=" font-medium mb-2">Donor's First name</p>
                  <input
                    type="text"
                    placeholder="Donor's First name"
                    className="w-full h-10 mb-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    value={donor_first_name}
                    onChange={(e) => set_donor_first_name}
                  />
                </div>
                <div className="sm:col-span-3 col-span-12">
                  <p className=" font-medium mb-2"> Donor's last name </p>
                  <input
                    type="text"
                    placeholder="Donor last name"
                    className="w-full h-10 mb-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    value={donor_last_name}
                    onChange={(e) => set_donor_last_name(e.target.value)}
                  />
                </div>
                <div className="sm:col-span-3 col-span-12">
                  <p className=" font-medium mb-2">D.O.B</p>
                  <input
                    type="date"
                    // value={donordate}
                    // onChange={(e) => setDate(e.target.value)}
                    placeholder="select date"
                    className="w-full h-10 mb-4  rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    value={donor_dob}
                    onChange={(e) => set_donor_dob(e.target.value)}
                  />
                </div>
                <div className="sm:col-span-3 col-span-12">
                  <p className=" font-medium mb-2">Date</p>
                  <input
                    type="date"
                    placeholder="select date"
                    className="w-full h-10 mb-4  rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    value={donor_date}
                    onChange={(e) => set_donor_date(e.target.value)}
                  />
                </div>
                <div
                  onClick={handleDonorDate}
                  className="sm:col-span-4 col-span-12"
                >
                  <p className="font-medium mb-2">Choose Default</p>
                  <button
                    className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white"
                    style={{ background: "#1db6fa" }}
                  >
                    Today's date
                  </button>
                </div>
              </div>
            </div>

            <div className=" mt-4 gap-3 flex sm:justify-start justify-around">
              <div>
                <Cancelsmall onClick={onClose} />
              </div>
              <div>
                {/* <GreenSubmit /> */}
                <button onClick={forensicDrugFromSave} className="savebtn">
                  {load || spin ? (
                    <svg
                      aria-hidden="true"
                      class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  ) : (
                    "submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClinicForensicOrderForm;
