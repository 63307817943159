import React, { useState } from "react";

import CommonModal from "./CommonModal";
import toast from "react-hot-toast";

import { Upload } from "@mui/icons-material";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useSelector } from "react-redux";

const AddManualModal = ({ show, onClose, onSubmit }) => {
  const { user_type, _id } = useSelector(
    (state) => state.user.userInfo.data.user
  );
  const [formData, setFormData] = useState({
    title: "",
    note: "",
    doc: "",
    createdBy: _id,
  });
  const [fileName, setFileName] = useState("");
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setUploading(true);
      setFileName(file.name);

      try {
        const uploadFormData = new FormData();
        uploadFormData.append("img", file);

        const uploadResponse = await HttpClient.apiCaller(
          "upload/single-upload",
          "POST",
          uploadFormData,
          null,
          "multipart/form-data"
        );

        if (uploadResponse?.data?.img) {
          setFormData((prev) => ({ ...prev, doc: uploadResponse.data.img }));
          toast.success("File uploaded successfully");
        } else {
          throw new Error("File upload failed");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error("Failed to upload file");
        setFileName("");
      } finally {
        setUploading(false);
      }
    } else {
      toast.error("Please upload a PDF file");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.doc) {
      toast.error("Please upload a PDF file");
      return;
    }

    setLoading(true);
    try {
      await onSubmit(formData);
      toast.success("Manual added successfully");
      handleClose();
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Failed to add manual");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setFormData({
      title: "",
      note: "",
      doc: "",
      createdBy: _id,
    });
    setFileName("");
    onClose();
  };

  if (!show) return null;

  return (
    <CommonModal onClose={handleClose} heading="Add New Manual">
      <form onSubmit={handleSubmit}>
        {/* Title Input */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm mb-2">Title</label>
          <input
            type="text"
            required
            value={formData.title}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, title: e.target.value }))
            }
            className="w-full px-3 py-2 border border-gray-300 rounded text-sm focus:outline-none"
            placeholder="Enter manual title"
          />
        </div>

        {/* Note Textarea */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm mb-2">Note</label>
          <textarea
            value={formData.note}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, note: e.target.value }))
            }
            className="w-full px-3 py-2 border border-gray-300 rounded text-sm focus:outline-none resize-none"
            rows={4}
            placeholder="Enter note (optional)"
          />
        </div>

        {/* File Upload */}
        <div className="mb-6">
          <label className="block text-gray-700 text-sm mb-2">
            Document (PDF)
          </label>
          <div>
            <button
              type="button"
              onClick={() => document.getElementById("file-upload").click()}
              disabled={uploading}
              className="w-full px-3 py-2 border border-gray-300 rounded text-sm bg-white focus:outline-none hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <div className="flex items-center justify-center gap-2">
                <Upload className="h-4 w-4" />
                <span>
                  {uploading ? "Uploading..." : fileName || "Upload PDF"}
                </span>
              </div>
            </button>
            <input
              id="file-upload"
              type="file"
              accept=".pdf"
              required
              className="hidden"
              onChange={handleFileChange}
            />
          </div>
          {formData.doc && !uploading && (
            <p className="text-green-600 text-xs mt-1">
              ✓ File uploaded successfully
            </p>
          )}
        </div>

        {/* Submit Buttons */}
        <div className="flex justify-end gap-3">
          <button
            type="button"
            onClick={handleClose}
            className="px-4 py-2 border border-gray-300 rounded text-sm hover:bg-gray-50 focus:outline-none min-w-[80px]"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading || uploading || !formData.doc}
            className={`px-4 py-2 bg-blue-600 text-white rounded text-sm hover:bg-blue-700 focus:outline-none min-w-[80px] ${
              loading || uploading || !formData.doc
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
          >
            {loading ? "Saving..." : "Save"}
          </button>
        </div>
      </form>
    </CommonModal>
  );
};

export default AddManualModal;
