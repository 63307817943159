import React from "react";
import Dashboard from "../../components/Dashboard";
import card2 from "../../assets/card2.png";
import card9 from "../../assets/card9.png";
import { FaBriefcaseMedical, FaClinicMedical, FaUserAlt } from "react-icons/fa";
import { FaPersonWalking } from "react-icons/fa6";
import { PiSuitcaseSimpleLight } from "react-icons/pi";
import { FaRegRectangleList } from "react-icons/fa6";
import { RiContrastDrop2Fill } from "react-icons/ri";
import { MdReceipt } from "react-icons/md";
import { GoOrganization } from "react-icons/go";
import { FaWheelchair } from "react-icons/fa6";
import { GrOrganization } from "react-icons/gr";
// import UseSpecificUsersHook from '../../components/UseSpecificUsersHook';
import useSpecifiUsersHook from "../../components/UseSpecificUsersHook";
import { CardWithIcon } from "../../components/Card";
import { Spinner } from "@material-tailwind/react";
import { IoBagAddSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
const SDashboard = () => {
  const {
    mro,
    patient,
    collectionSite,
    collector,
    employer,
    clinic,
    organization,
    technician,
    spin,
    setSpin,
    fto,
  } = useSpecifiUsersHook();
  const cardsData = [
    // {
    //   label: "Active Medical Review Officer",
    //   icon: <FaUserAlt />,
    //   count: mro,
    //   path: "/scientist-physician",
    // },
    {
      label: "Active Forensic Test Order",
      icon: <FaUserAlt />,
      count: fto,
      path: "/scientist-forensic-test-order",
    },
    // {
    //   label: "Active Clinic",
    //   icon: <FaClinicMedical />,
    //   count: clinic,
    // },
    // {
    //   label: "Active Patient",
    //   icon: <FaWheelchair />,
    //   count: patient,
    // },
    // {
    //   label: "Active Collection Site",
    //   icon: <IoBagAddSharp />,
    //   count: collectionSite,
    // },
    // {
    //   label: "Active Organization",
    //   icon: <GrOrganization />,
    //   count: organization,
    // },
    // {
    //   label: "Active Collector",
    //   icon: <FaUserAlt />,
    //   count: collector,
    // },
  ];
  const navigate = useNavigate();
  return (
    <Dashboard>
      {spin ? (
        <div className="w-full mt-20 flex items-center justify-center">
          <Spinner className="h-12 w-12" />{" "}
        </div>
      ) : (
        <div className="grid  xs:grid-cols-2 sm:grid-cols-3 w-full gap-2 cursor-pointer">
          {cardsData?.map((item) => {
            return (
              <CardWithIcon item={item} onClick={() => navigate(item?.path)} />
            );
          })}
        </div>
      )}
    </Dashboard>
  );
};

export default SDashboard;
