import React from 'react'
import Dashboard from '../../components/Dashboard'

const CReferQr = () => {
    return (
        <Dashboard>
            <div>
                <div className="relative bg-white p-2  mt-16  xs:mb-1  h-[65vh] rounded-[10px] shadow-md grid grid-cols-12">
                    <div className='col-span-2'>
                        COLLECTOR REFER QR CODE
                    </div>
                    <div className='col-span-10 flex justify-center items-center'>
                        OR image
                    </div>
                </div>
            </div>
        </Dashboard>
    )
}

export default CReferQr
