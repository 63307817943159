import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HttpClient } from "../Redux/controllers/HttpClient";

export default function UseAllUsersHook() {
  const [admin, setAdmin] = useState([]);
  const [physician, setPhysician] = useState([]);
  const [patient, setPatient] = useState([]);
  const [clinic, setClinic] = useState([]);
  const [collectionSite, setCollectionSite] = useState([]);
  const [collector, setCollector] = useState([]);
  const [organization, setOrganization] = useState([]);
  const [technician, setTechnician] = useState([]);
  const [clinicalScientist, setClinicalScientist] = useState([]);
  const [spin, setSpin] = useState(false);
  const [fto, setFto] = useState([]);

  const token = useSelector((state) => state?.user?.token);

  useEffect(() => {
    AllUsers();
  }, []);

  const AllUsers = async () => {
    try {
      setSpin(true);

      await HttpClient.apiCaller(`admin/test`, "GET", null, token, null)
        .then((res) => {
          setFto(res?.data?.user?.length);
          // console.log("[][]", res);
        })
        .catch((err) => console.log(err));

      await HttpClient.apiCaller(
        "admin/user-list?role=admin",
        "GET",
        null,
        token,
        null
      )
        .then((res) => setAdmin(res?.data?.user?.length))
        .catch((err) => console.log(err));

      await HttpClient.apiCaller(
        "admin/user-list?role=technician",
        "GET",
        null,
        token,
        null
      )
        .then((res) => {
          setTechnician(res?.data?.user?.length);
        })
        .catch((err) => console.log(err));

      await HttpClient.apiCaller(
        "admin/user-list?role=collector",
        "GET",
        null,
        token,
        null
      )
        .then((res) => setCollector(res?.data?.user?.length))
        .catch((err) => console.log(err));

      await HttpClient.apiCaller(
        "admin/user-list?role=mro",
        "GET",
        null,
        token,
        null
      )
        .then((res) => setPhysician(res?.data?.user?.length))
        .catch((err) => console.log(err));

      await HttpClient.apiCaller(
        "admin/user-list?role=patient",
        "GET",
        null,
        token,
        null
      )
        .then((res) => setPatient(res?.data?.user?.length))
        .catch((err) => console.log(err));

      await HttpClient.apiCaller(
        "admin/user-list?role=collectionsite",
        "GET",
        null,
        token,
        null
      )
        .then((res) => setCollectionSite(res?.data?.user?.length))
        .catch((err) => console.log(err));

      await HttpClient.apiCaller(
        "admin/user-list?role=clinic",
        "GET",
        null,
        token,
        null
      )
        .then((res) => setClinic(res?.data?.user?.length))
        .catch((err) => console.log(err));

      await HttpClient.apiCaller(
        "admin/user-list?role=organization",
        "GET",
        null,
        token,
        null
      )
        .then((res) => setOrganization(res?.data?.user?.length))
        .catch((err) => console.log(err));

      await HttpClient.apiCaller(
        "admin/user-list?role=scientist",
        "GET",
        null,
        token,
        null
      )
        .then((res) => setClinicalScientist(res?.data?.user?.length))
        .catch((err) => console.log(err));
    } catch (e) {
      console.log(e);
    } finally {
      setSpin(false);
    }
  };

  return {
    admin,
    physician,
    patient,
    clinic,
    collectionSite,
    collector,
    organization,
    technician,
    clinicalScientist,
    spin,
    fto,
    setSpin,
  };
}
