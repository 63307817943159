import AddButton from "../../components/buttons/AddButton";
import Dashboard from "../../components/Dashboard";
import Table, { ActionButton } from "../../components/Table";
import { FaPlus } from "react-icons/fa6";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { toast } from "react-hot-toast";

import { useEffect, useState } from "react";
import AddCollectionSite from "../../components/forms/AddCollectionSite";
import { useForm } from "../../context/formContext";
import { useSelector } from "react-redux";
import { useModal } from "../../context/modalContext";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { Spinner } from "@material-tailwind/react";
import CollectionModal from "../../components/Modals/Collectionmodal";
import Delete from "./../../components/buttons/DeleteButton";
import DeleteUserModal from "../../components/Modals/DeleteUserModal";
import { collectionSiteTableHeadData } from "../../constants/data/collectionSiteData";

const CollectionSitePage = () => {
  const { showForm, openForm, closeForm } = useForm();
  const { showEditModal, closeDeleteModal, showDeleteModal, closeEditModal } =
    useModal();
  const [showSpin, setShowSpin] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [collectionSiteTableData, setCollectionSiteTableData] = useState([]);

  const fetchData = async () => {
    setShowSpin(true);
    try {
      const { data } = await HttpClient.apiCaller(
        "admin/user-list?role=collectionsite",
        "GET",
        null,
        token,
        null
      );
      setCollectionSiteTableData(data.user);
    } catch (error) {
      console.log(error);
    } finally {
      setShowSpin(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [closeEditModal]);

  const handleToggleUpload = async (userId, isAllowed) => {
    try {
      await HttpClient.apiCaller(
        "toggle-manual-upload",
        "PUT",
        { userId, isAllowed: !isAllowed },
        token
      );
      toast.success(
        `Manual upload ${!isAllowed ? "enabled" : "disabled"} successfully`
      );
      fetchData();
    } catch (error) {
      toast.error(
        error.response?.data?.error || "Error updating upload access"
      );
    }
  };

  const getCollectionSiteTableData = (dataSet) => {
    return dataSet?.map((item, idx) => ({
      no: idx + 1,
      uid: item?.uid,
      firstName: item?.first_name,
      email: item?.email,
      mobile: item?.mobile_number,
      upload: (
        <Toggle
          checked={item?.isManualUploadAllowed || false}
          onChange={() =>
            handleToggleUpload(item._id, item.isManualUploadAllowed)
          }
          className="custom-toggle"
        />
      ),
      action: (
        <div className="flex gap-3">
          <ActionButton
            action="edit"
            user={item}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="frn"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                />
              </svg>
            }
          />
          <Delete user={item} />
        </div>
      ),
    }));
  };

  return (
    <Dashboard>
      {showForm ? (
        <AddCollectionSite onClose={closeForm} />
      ) : (
        <>
          <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
            <div>
              <p className="poppins-medium text-lg leading-[21px] text-theme-darkBlue mb-2">
                Manage Collection Site
              </p>
              <p className="poppins-medium text-xs">
                <span className="underline text-theme-blue cursor-pointer">
                  Dashboard
                </span>{" "}
                /{" "}
                <span className="underline text-theme-blue cursor-pointer">
                  collection site
                </span>
              </p>
            </div>

            <AddButton
              onClick={openForm}
              buttonName={"Add New Collection Site"}
              icon={<FaPlus className="size-5" />}
            />
          </div>

          {showSpin ? (
            <div className="w-full mt-20 flex items-center justify-center">
              <Spinner className="h-12 w-12" />
            </div>
          ) : (
            <Table
              tableData={getCollectionSiteTableData(collectionSiteTableData)}
              tableHeadData={collectionSiteTableHeadData}
            />
          )}
        </>
      )}

      {showEditModal && (
        <CollectionModal edit={true} onClose={closeEditModal} />
      )}
      {showDeleteModal && <DeleteUserModal onClose={closeDeleteModal} />}
    </Dashboard>
  );
};

export default CollectionSitePage;
