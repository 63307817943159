import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HttpClient } from "../Redux/controllers/HttpClient";

export default function useSpecifiUsersHook() {
  const [mro, setMro] = useState([]);
  const [sMro, setSMro] = useState([]);
  const [patient, setPatient] = useState([]);
  const [collectionSite, setCollectionSite] = useState([]);
  const [collector, setCollector] = useState([]);
  const [myAssociates, setMyAssociates] = useState([]);
  const [myAssociatesType, setMyAssociatesType] = useState([]);
  const [technician, setTechnician] = useState([]);
  const [clinic, setClinic] = useState([]);
  const [organization, setOrganization] = useState([]);
  const [spin, setSpin] = useState(false);
  const [testt, setTeestt] = useState([]);

  const token = useSelector((state) => state?.user?.token);
  const userDetail = useSelector(
    (state) => state?.user?.userInfo?.data?.user?._id
  );
  const userD = useSelector((state) => state?.user?.userInfo?.data?.user);
  // console.log("{{{{{{{{{", userD);
  const userType = useSelector(
    (state) => state?.user?.userInfo?.data?.user?.user_type
  );

  useEffect(() => {
    AllUsers();
  }, []);

  const AllUsers = async () => {
    try {
      setSpin(true);

      // await HttpClient.apiCaller(
      //   `admin/get-users-createdby/${userDetail}?user_type=technician`,
      //   "GET",
      //   null,
      //   token,
      //   null
      // )
      //   .then((res) => setTechnician(res?.data?.user?.length))
      //   .catch((err) => console.log(err));
      if (userType === "mro") {
        await HttpClient.apiCaller(
          `admin/get-users-createdby/${userDetail}?user_type=clinic`,
          "GET",
          null,
          token,
          null
        )
          .then((res) => setClinic(res?.data?.user?.length))
          .catch((err) => console.log(err));

        await HttpClient.apiCaller(
          `admin/get-users-createdby/${userDetail}?user_type=organization`,
          "GET",
          null,
          token,
          null
        )
          .then((res) => setOrganization(res?.data?.user?.length))
          .catch((err) => console.log(err));
        await HttpClient.apiCaller(
          `admin/my-associates/${userDetail}`,
          "GET",
          null,
          token,
          null
        )
          .then((res) => {
            setMyAssociates(res?.data?.employers?.length);
            setMyAssociatesType(res?.data?.employers[0]?.user_type);
          })
          .catch((err) => console.log(err));
      } else {
        // await HttpClient.apiCaller(
        //   `admin/get-users-createdby/${userDetail}?user_type=collector`,
        //   "GET",
        //   null,
        //   token,
        //   null
        // )
        //   .then((res) => setCollector(res?.data?.user?.length))
        //   .catch((err) => console.log(err));

        await HttpClient.apiCaller(
          `admin/get-users-createdby/${userDetail}?user_type=patient`,
          "GET",
          null,
          token,
          null
        )
          .then((res) => setPatient(res?.data?.user?.length))
          .catch((err) => console.log(err));

        await HttpClient.apiCaller(
          `admin/get-users-createdby/${userDetail}?user_type=mro`,
          "GET",
          null,
          token,
          null
        )
          .then((res) => setMro(res?.data?.user?.length))
          .catch((err) => console.log(err));

        await HttpClient.apiCaller(
          `admin/get-mros-under/${userDetail}`,
          "GET",
          null,
          token,
          null
        )
          .then((res) => setSMro(res?.data?.users?.length))
          .catch((err) => console.log(err));

        await HttpClient.apiCaller(
          `admin/get-users-createdby/${userDetail}?user_type=collectionsite`,
          "GET",
          null,
          token,
          null
        )
          .then((res) => setCollectionSite(res?.data?.user?.length))
          .catch((err) => console.log(err));
      }

      if (userD?.user_type === "mro") {
        await HttpClient.apiCaller(
          // `admin/test`,
          `admin/get-test-completed-by/${userD?._id}`,
          "GET",
          null,
          token,
          null
        )
          .then((res) => setTeestt(res?.data?.test?.length))
          .catch((err) => console.log(err));
      } else {
        await HttpClient.apiCaller(
          // `admin/test`,
          `admin/get-test-by-user-id/${userDetail}`,
          "GET",
          null,
          token,
          null
        )
          .then((res) => setTeestt(res?.data?.test?.length))
          .catch((err) => console.log(err));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setSpin(false);
    }
  };

  return {
    mro,
    sMro,
    patient,
    collectionSite,
    collector,
    clinic,
    organization,
    technician,
    spin,
    testt,
    setSpin,
    myAssociates,
    myAssociatesType,
  };
}
