import React from "react";
import Dashboard from "../../components/Dashboard";
import { FaUserAlt } from "react-icons/fa";
import { FaWheelchair } from "react-icons/fa6";
import useSpecifiUsersHook from "../../components/UseSpecificUsersHook";
import { Spinner } from "@material-tailwind/react";
import { CardWithIcon } from "../../components/Card";
import { IoBagAddSharp } from "react-icons/io5";
import { GrOrganization } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
const TDashboard = () => {
  const {
    mro,
    patient,
    collectionSite,
    collector,
    employer,
    technician,
    organization,
    clinic,
    spin,
    setSpin,
    fto,
    fto2,
  } = useSpecifiUsersHook();
  const cardsData = [
    {
      label: "Active Medical Review Officer",
      icon: <FaUserAlt />,
      count: mro,
      path: "/technician-physician",
    },
    {
      label: "Active Forensic Test Order",
      icon: <FaUserAlt />,
      count: fto2,
      path: "/ttechnician-forensic-test-order",
    },
    {
      label: "Active Patient",
      icon: <FaWheelchair />,
      count: patient,
      path: "/technician-patients",
    },
    // {
    //   label: "Active Collection Site",
    //   icon: <IoBagAddSharp />,
    //   count: collectionSite,
    // },
    // {
    //   label: "Active Organization",
    //   icon: <GrOrganization />,
    //   count: organization,
    // },
    // {
    //   label: "Active Collector",
    //   icon: <FaUserAlt />,
    //   count: collector,
    // },
  ];
  const navigate = useNavigate();
  return (
    <Dashboard>
      {spin ? (
        <div className="w-full mt-20 flex items-center justify-center">
          <Spinner className="h-12 w-12" />{" "}
        </div>
      ) : (
        <div className="grid  xs:grid-cols-2 sm:grid-cols-3 w-full gap-2 cursor-pointer">
          {cardsData?.map((item) => {
            return (
              <CardWithIcon item={item} onClick={() => navigate(item?.path)} />
            );
          })}
        </div>
      )}
    </Dashboard>
  );
};

export default TDashboard;
