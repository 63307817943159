import React from "react";

const Cancelsmall = ({ onClick }) => {
  return (
    <div>
      <button
        onClick={onClick}
        className="px-2 py-1   text-[#8F8F8F] border-2  border-[#A3AED0] rounded poppins-medium text-center "
      >
        cancel
      </button>
    </div>
  );
};

export default Cancelsmall;
