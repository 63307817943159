import React from "react";
import Dashboard from "../../components/Dashboard";
import { FaBriefcaseMedical, FaListAlt, FaUserAlt } from "react-icons/fa";
import { FaWheelchair } from "react-icons/fa6";
import { IoBagAddSharp } from "react-icons/io5";
import { Spinner } from "@material-tailwind/react";
import useSpecifiUsersHook from "../../components/UseSpecificUserHook";
import { CardWithIcon } from "../../components/Card";
import { useNavigate } from "react-router-dom";

const OrgDashboard = () => {
  const {
    mro,
    sMro,
    patient,
    collectionSite,
    collector,
    technician,
    spin,
    setSpin,
    testt,
  } = useSpecifiUsersHook();
  const cardsData = [
    {
      label: "Active Medical Review Officer",
      icon: <FaUserAlt />,
      count: mro + sMro,
      path: "/clinic-manage-mro",
    },
    {
      label: "Active Patient",
      icon: <FaWheelchair />,
      count: patient,
      path: "/organization-manage-patient",
    },
    {
      label: "Active Collection Site",
      icon: <IoBagAddSharp />,
      count: collectionSite,
      path: "/organization-manage-site",
    },
    // {
    //   label: "Active Collector",
    //   icon: <FaUserAlt />,
    //   count: collector,
    //   path: "/organization-manage-collector",
    // },
    {
      label: "Forensic Test Order",
      icon: <FaUserAlt />,
      count: testt,
      path: "/organization-forensic-test-order",
    },

    // {
    //   label: "Active Forensic Test Order",
    //   icon: <IoBagAddSharp />,
    //   count: 0,
    // },
  ];
  const navigate = useNavigate();
  return (
    <Dashboard>
      {spin ? (
        <div className="w-full mt-20 flex items-center justify-center">
          <Spinner className="h-12 w-12" />{" "}
        </div>
      ) : (
        <div className="grid  xs:grid-cols-2 sm:grid-cols-3 w-full gap-2 cursor-pointer">
          {cardsData?.map((item) => {
            return (
              <CardWithIcon item={item} onClick={() => navigate(item?.path)} />
            );
          })}
        </div>
      )}
    </Dashboard>
  );
};

export default OrgDashboard;
