import React from "react";
import CommonModal from "./CommonModal";
import QRCode from "react-qr-code";

const Qrmodal = ({ onClose, item }) => {
  console.log(">>>>", item);
  return (
    <CommonModal heading={"QR Code"} onClose={onClose}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <QRCode value={item} />
      </div>
    </CommonModal>
  );
};

export default Qrmodal;
