import AddButton from "../../components/buttons/AddButton";
import Dashboard from "../../components/Dashboard";
import Table, { ActionButton } from "../../components/Table";
import { FaFileDownload } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import ForensicOrderForm from "../../components/forms/ForensicOrderForm";
import { useForm } from "../../context/formContext";
import { useSelector } from "react-redux";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useEffect, useState } from "react";
import eyeIcon from "../../assets/table/eyeIcon.png";
import crossIcon from "../../assets/table/crossIcon.png";
import pdfIcon from "../../assets/table/pdficon.png";
import penIcon from "../../assets/table/penIcon.png";
import qrIcon from "../../assets/table/qrIcon.png";
import ForensicOrderFormEdit from "../../components/forms/ForensicOrderFormEdit";
import SiteEditForeinsic from "./SiteEditForeinsic";
import SiteForensic from "./SiteForensic";
import ClinicForensicOrderForm from "../../components/forms/ClinicForensicOrderForm";
import ClinicalScientistForensicTestOrderForm from "../../components/forms/ClinicalScientistForensicTestOrderForm";
import Qrmodal from "../../components/Modals/Qrmodal";
import FederalClinicalScientistForensicTestOrderForm from "../../components/forms/FederalClinicalScientistForensicTestOrderForm";

const FederalSiteForensicTestOrder = ({ onClose }) => {
  const {
    showForm,
    openForm,
    closeForm,
    showEditForm,
    openEditForm,
    closeEditForm,
  } = useForm();
  const [forensicTestDetails, setForensicTestDetails] = useState([]);

  const [showQr, setShowQr] = useState(false);
  const [qrItem, setQrItem] = useState([]);

  const token = useSelector((state) => state?.user?.token);
  const userInfo = useSelector((state) => state?.user?.userInfo?.data?.user);

  useEffect(() => {
    getTestData();
  }, [closeForm, closeEditForm]);
  const getTestData = async () => {
    await HttpClient.apiCaller(
      // "admin/test",
      `admin/federal-get-test-by-user-id/${userInfo?._id}`,
      "GET",
      null,
      token,
      null
    )
      .then((res) => {
        // console.log("//////", res);
        // setForensicTestDetails(res?.data?.user?.reverse());
        setForensicTestDetails(res?.data?.test?.reverse());
      })
      .catch((err) => console.log(err));
  };

  const forensicTestTableHeadData = [
    {
      accessorKey: "slno",
      header: "Sl No",
      size: 100,
    },
    {
      accessorKey: "reqno",
      header: "Req No",
      size: 100,
    },
    {
      accessorKey: "donorName",
      header: "Donor Name",
      size: 100,
    },
    {
      accessorKey: "clinic",
      header: "Clinic",
      size: 150,
    },
    {
      accessorKey: "physician",
      header: "Physician",
      size: 150,
    },

    {
      accessorKey: "action",
      header: "Action",
      size: 300,
    },
  ];

  const forensicTestTableData = forensicTestDetails.map((item, idx) => {
    return {
      slno: idx + 1,
      reqno: item?.specimen_id,
      donorName: `${item?.donor_first_name} ${item?.donor_last_name}`,
      clinic: item?.site_details?.name,
      physician: item?.mro?.name,
      // archive: (
      //   <div className="text-white poppins-medium px-2 py-1 bg-[#0CAF60] w-fit rounded">
      //     {item.archive}
      //   </div>
      // ),
      action: (
        <div className="flex gap-1">
          {/* <ActionButton icon={eyeIcon} />
          <ActionButton icon={qrIcon} />
          <ActionButton icon={pdfIcon} />
          <ActionButton icon={eyeIcon} />
          <ActionButton icon={penIcon} action="edit" user={item} />
          <ActionButton icon={crossIcon} /> */}
          {/* <ActionButton
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="frn"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
            }
          /> */}
          {/* qr */}
          <ActionButton
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="frn"
                onClick={() => {
                  setShowQr(true);
                  setQrItem(item?.test_created_by_data?.pdf);
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 3.75 9.375v-4.5ZM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 0 1-1.125-1.125v-4.5ZM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 13.5 9.375v-4.5Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 6.75h.75v.75h-.75v-.75ZM6.75 16.5h.75v.75h-.75v-.75ZM16.5 6.75h.75v.75h-.75v-.75ZM13.5 13.5h.75v.75h-.75v-.75ZM13.5 19.5h.75v.75h-.75v-.75ZM19.5 13.5h.75v.75h-.75v-.75ZM19.5 19.5h.75v.75h-.75v-.75ZM16.5 16.5h.75v.75h-.75v-.75Z"
                />
              </svg>
            }
          />
          {/* pdf */}
          <ActionButton
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="frn"
                onClick={() =>
                  window.open(item?.test_created_by_data?.pdf, "_blank")
                }
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                />
              </svg>
            }
          />
          {/* pen */}
          {/* <ActionButton
            action="edit"
            user={item}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="frn"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                />
              </svg>
            }
          /> */}
          {/* cross */}
          {/* <ActionButton
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="frn"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            }
          /> */}
        </div>
      ),
    };
  });

  return (
    <Dashboard>
      {showForm ? (
        // <ForensicOrderForm onClose={closeForm} />

        // <SiteForensic onClose={closeForm} />
        // <ClinicForensicOrderForm onClose={closeForm} />
        <FederalClinicalScientistForensicTestOrderForm onClose={closeForm} />
      ) : (
        // ) : showEditForm ? (
        //   // <ForensicOrderFormEdit onClose={closeEditForm} />
        //   <SiteEditForeinsic onClose={closeEditForm} />
        // )
        <>
          {showQr ? (
            <Qrmodal item={qrItem} onClose={() => setShowQr(false)} />
          ) : (
            ""
          )}
          <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
            <div>
              <p className="poppins-medium text-lg leading-[21px]  text-theme-darkBlue mb-2">
                Manage Federal Forensic Form{" "}
              </p>
              <p className="poppins-medium text-xs  ">
                <span className="underline text-theme-blue cursor-pointer">
                  Test E-order
                </span>{" "}
                /{" "}
                <span className="underline text-theme-blue cursor-pointer">
                  Manage forensic form
                </span>
              </p>
            </div>
            {/*  */}
            <div className="flex gap-5 xs:flex-col md:flex-row">
              {/* show archive records */}
              <button
                className="poppins-medium ml-auto text-sm text-theme-blue border-theme-blue border-2 p-2  rounded-md flex items-center gap-2 hover:opacity-90"
                // onClick={onClick}
              >
                <FaFileDownload className="size-5 " />
                show archive records
              </button>
              <AddButton
                onClick={openForm}
                buttonName={"Add Forensic Test"}
                icon={<FaPlus className="size-5" />}
              />
            </div>
          </div>
          {/* admins table */}
          <Table
            tableData={forensicTestTableData}
            tableHeadData={forensicTestTableHeadData}
          />
        </>
      )}
    </Dashboard>
  );
};

export default FederalSiteForensicTestOrder;
