import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SlEnvolope } from "react-icons/sl";
import image1 from "../../assets/image1.png";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa6";
import { login } from "../../Redux/Slice/userSlice";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Utility from "../../Utils/Utility";
import { Spinner } from "@material-tailwind/react";
// import ResetPage from "./ResetPage"
import genpace from "../../assets/genpace.png";

const ResetPage = () => {
  const [input, setInput] = useState({
    email: "",
    password: "",
    conformpassword: " ",
  });
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(false);
  const [resetselected, setresetSelected] = useState(false);
  const [emailValid, setEmailValid] = useState("");
  const [passwordValid, setPasswordValid] = useState("");
  const [conformpasswordValidation, setconformpasswordValidation] =
    useState("");
  const [spin, setSpin] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let isValid = true;
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (!input.email) {
      setEmailValid("Email is required");
      isValid = false;
    } else if (!regex.test(input.email)) {
      setEmailValid("Invalid email address");
      isValid = false;
    } else {
      setEmailValid("");
    }
    if (!input.password) {
      setPasswordValid("Password is required");
      isValid = false;
    } else if (input.password.length < 6) {
      setPasswordValid("Password length should be at least 6 characters");
      isValid = false;
    } else if (input.password.length > 14) {
      setPasswordValid("Password length should be less than 14 characters");
      isValid = false;
    } else if (!/[A-Z]/.test(input.password)) {
      setPasswordValid("Password must contain at least one uppercase letter.");
      isValid = false;
    } else if (!/[a-z]/.test(input.password)) {
      setPasswordValid("Password must contain at least one lowercase letter.");
      isValid = false;
    } else if (!/[$&+,:;=?@#|'<>.^*()%!-]/.test(input.password)) {
      setPasswordValid("Password must contain at least one special character.");
      isValid = false;
    } else if (!/[0-9]/.test(input.password)) {
      setPasswordValid("Password must be contain number's");
      isValid = false;
    } else {
      setPasswordValid("");
    }

    if (!input.conformpassword) {
      setconformpasswordValidation("Field can not be empty");
      isValid = false;
    } else if (input.password !== input.conformpassword) {
      isValid = false;
      setconformpasswordValidation("Password does not match");
    } else {
      setconformpasswordValidation("");
    }

    if (isValid) {
      // setSpin(true);
      const newData = { email: input.email, password: input.password };
      setData({ ...data, newData });
      setInput({ email: "", password: "", conformpassword: "" });
      alert("password updated succesfully");
      console.log(data);
    }
  };

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };
  console.log(data);
  return (
    <>
      <div className="flex items-center  sm:mt-[9vh] justify-center h-full mt-28">
        <div className="md:w-[452px] w-full mx-auto p-6 bg-theme-white rounded-[5%] shadow-md bg-white">
          <div className="w-full items-center">
            <img className="h-6 sm:h-10   mx-auto" src={genpace} alt="logo" />
          </div>
          <h2 className="text-center text-[170%] font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-b mt-[1rem] from-blue-600 to-cyan-500">
            Reset Password
          </h2>

          <form className="relative mt-[10%]" onSubmit={handleFormSubmit}>
            <div className="mb-4 relative">
              <label
                className="block text-sm font-semibold mb-1 poppins-medium text-theme-darkBlue"
                htmlFor="email"
              >
                Email:
              </label>
              <div className="input-container">
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={input.email}
                  onChange={handleChange}
                  className="w-full px-2 py-2 border border-gray-300 rounded"
                />
                <SlEnvolope className="absolute ml-[91%] mt-[-7%] text-gray-500" />
              </div>
              <span className="text-red-700">{emailValid}</span>
            </div>

            {/* password */}

            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1 poppins-medium text-theme-darkBlue"
                htmlFor="password"
              >
                Password:
              </label>
              <input
                type={selected ? "text" : "password"}
                id="password"
                name="password"
                value={input.password}
                onChange={handleChange}
                className="w-full px-2 py-2 border border-gray-300 rounded"
              />
              {selected ? (
                <IoEyeOutline
                  onClick={() => setSelected(!selected)}
                  className="relative ml-[91%] mt-[-7%] text-gray-500"
                />
              ) : (
                <FaRegEyeSlash
                  onClick={() => setSelected(!selected)}
                  className="relative ml-[91%] mt-[-7%] text-gray-500"
                />
              )}
            </div>
            <span className="text-red-700">{passwordValid}</span>

            {/* Conform password */}

            <div className="mb-4 mt-6">
              <label
                className="block text-sm font-semibold mb-1 poppins-medium text-theme-darkBlue"
                htmlFor="cpassword"
              >
                Conform Password:
              </label>
              <input
                type={resetselected ? "text" : "password"}
                id="conformpassword"
                name="conformpassword"
                value={input.conformpassword}
                onChange={handleChange}
                className="w-full px-2 py-2 border border-gray-300 rounded"
              />
              {resetselected ? (
                <IoEyeOutline
                  onClick={() => setresetSelected(!resetselected)}
                  className="relative ml-[91%] mt-[-7%] text-gray-500"
                />
              ) : (
                <FaRegEyeSlash
                  onClick={() => setresetSelected(!resetselected)}
                  className="relative ml-[91%] mt-[-7%] text-gray-500"
                />
              )}
            </div>
            <span className="text-red-700">{conformpasswordValidation}</span>

            <button
              type="submit"
              className="bg-blue-500 mt-10 text-white px-4 py-2 rounded hover:bg-blue-600 w-full poppins-medium flex justify-center items-center"
            >
              Reset
              {/* {spin ? <Spinner color="purple" /> : "Login"} */}
            </button>
          </form>
        </div>
      </div>
      <div className="mt-12">
        <img
          className="h-6 sm:h-10  mb-2-[30%] mx-auto"
          src={image1}
          alt="logo"
        />
      </div>
      <p className="text-center text-theme-grey poppins-medium">
        Copyright reserved by USTOXLAB pvt. ltd
      </p>
    </>
  );
};

export default ResetPage;
