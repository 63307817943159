// import { Dashboard } from '@mui/icons-material'
import Dashboard from "../../components/Dashboard";
// import React from 'react'
// import card2 from '../../assets/card2.png'
// import card9 from '../../assets/card9.png'
import React from "react";
import useSpecifiUsersHook from "../../components/UseSpecificUsersHook";
import { Spinner } from "@material-tailwind/react";
import { CardWithIcon } from "../../components/Card";
import { FaUserAlt } from "react-icons/fa";
import { IoBagAddSharp } from "react-icons/io5";
import { FaWheelchair } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const CDashboard = () => {
  const {
    mro,
    patient,
    collectionSite,
    collector,
    employer,
    technician,
    spin,
    setSpin,
    fto,
  } = useSpecifiUsersHook();
  const cardsData = [
    // {
    //   label: "Active Medical Review Officer",
    //   icon: <FaUserAlt />,
    //   count: mro,
    // },
    // {
    //   label: "Active Patient",
    //   icon: <FaWheelchair />,
    //   count: patient,
    // },
    {
      label: "Active Collection Site",
      icon: <IoBagAddSharp />,
      count: collectionSite,
      path: "/collector-collection-site",
    },
    {
      label: "Active Forensic Test Order",
      icon: <IoBagAddSharp />,
      count: fto,
      path: "/collector-foreignsic-test-order",
    },
    // {
    //   label: "Active Collector",
    //   icon: <FaUserAlt />,
    //   count: collector,
    // },
  ];
  const navigate = useNavigate();
  return (
    <>
      <Dashboard>
        {spin ? (
          <div className="w-full mt-20 flex items-center justify-center">
            <Spinner className="h-12 w-12" />{" "}
          </div>
        ) : (
          <div className="grid  xs:grid-cols-2 sm:grid-cols-3 w-full gap-2 cursor-pointer">
            {cardsData?.map((item) => {
              return (
                <CardWithIcon
                  item={item}
                  onClick={() => navigate(item?.path)}
                />
              );
            })}
          </div>
        )}
      </Dashboard>
    </>
  );
};

export default CDashboard;
