import { FaPlus } from "react-icons/fa6";
import AddButton from "../../components/buttons/AddButton";
import Dashboard from "../../components/Dashboard";
import Table from "../../components/Table";
import {
  testCodeTableHeadData,
  getTestCodeTableData,
} from "../../constants/data/testCodeData";
import Testcode from "../../components/Modals/Testcode";
import { useModal } from "../../context/modalContext";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { Spinner } from "@material-tailwind/react";

const TestCodePage = () => {
  const { showEditModal, showModal, openModal, closeModal, closeEditModal } =
    useModal();
  const { token } = useSelector((state) => state.user);
  const [testCodeTableData, setTestCodeTableData] = useState([]);
  const [showSpin, setShowSpin] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setShowSpin(true);
      try {
        const { data } = await HttpClient.apiCaller(
          "admin/code?type=SPECIMEN",
          "GET",
          null,
          token,
          null
        );
        console.log(data?.code);
        setTestCodeTableData(data?.code);
      } catch (error) {
        console.log(error);
      } finally {
        setShowSpin(false);
      }
    };

    fetchData();

    return () => { };
  }, []);
  // [closeModal, closeEditModal]
  return (
    <Dashboard>
      <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
        <div>
          <p className="poppins-medium text-lg leading-[21px]  text-theme-darkBlue mb-2">
            Manage Collection Site{" "}
          </p>
          <p className="poppins-medium text-xs  ">
            <span className="underline  text-theme-blue cursor-pointer">
              Dashboard
            </span>{" "}
            /{" "}
            <span className="underline  text-theme-blue cursor-pointer">
              Manage Test Code
            </span>
          </p>
        </div>
        {/*  */}

        <AddButton
          onClick={openModal}
          buttonName={"Add Test Code"}
          icon={<FaPlus className="size-5" />}
        />
      </div>

      {/*  table */}
      {showSpin ? (
        <div className="w-full mt-20 flex items-center justify-center">
          <Spinner className="h-12 w-12" />{" "}
        </div>
      ) : (
        <Table
          tableData={getTestCodeTableData(testCodeTableData)}
          tableHeadData={testCodeTableHeadData}
        />
      )}
      {showModal ? <Testcode onClose={closeModal} /> : <></>}
      {showEditModal ? (
        <Testcode edit={true} onClose={closeEditModal} />
      ) : (
        <></>
      )}
    </Dashboard>
  );
};

export default TestCodePage;