import React from "react";

const Card = ({ img, label, count, onClick }) => {
  return (
    <div
      className="flex items-center gap-3 bg-white py-6 px-2    xs:mb-1   rounded-[10px] shadow-md cursor-pointer"
      onClick={onClick}
    >
      <img src={img} alt="icon" className="w-16 h-16 ml-5" />
      <div>
        <p className="text-gray-400 poppins-medium ">{label}</p>
        <p className="text-gray-900 poppins-bold text-lg">{count}</p>
      </div>
    </div>
  );
};

export default Card;

export function CardWithIcon({ item, onClick }) {
  return (
    <>
      <div
        className="pl-9 flex items-center gap-3 bg-white py-6 px-2    xs:mb-1   rounded-[10px] shadow-md"
        onClick={onClick}
      >
        <div className="p-4 text-white bg-theme-sidebar  w-fit rounded-full ">
          {item?.icon}
        </div>{" "}
        <div>
          <p className="text-gray-400 poppins-medium ">{item?.label}</p>
          <p className="text-gray-900 poppins-bold text-lg">{item?.count}</p>
        </div>
      </div>
    </>
  );
}
