import { useEffect, useState } from "react";
import { MdPersonAddAlt1 } from "react-icons/md";
import AddButton from "../../components/buttons/AddButton";
import Dashboard from "../../components/Dashboard";
import Table from "../../components/Table";
import Technician from "../../components/Modals/Technician";
import { useModal } from "../../context/modalContext";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import {
  getTechnicianTableData,
  technicianTableHeadData,
} from "../../constants/data/techniciansData";
import { Spinner } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import { useForm } from "../../context/formContext";
import AddTechnician from "../../components/forms/AddTechnician";

const PhysicianTechnicianPage = () => {
  const { showEditModal, showModal, openModal, closeModal, closeEditModal } =
    useModal();
  const [showSpin, setShowSpin] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [technicianTableData, setTechnicianTableData] = useState([]);
  const { showForm, openForm, closeForm } = useForm();

  const userDetail = useSelector(
    (state) => state?.user?.userInfo?.data?.user?._id
  );

  useEffect(() => {
    const fetchData = async () => {
      setShowSpin(true);
      try {
        const { data } = await HttpClient.apiCaller(
          `admin/get-users-createdby/${userDetail}?user_type=technician`,
          "GET",
          null,
          token,
          null
        );

        setTechnicianTableData(data.user);
      } catch (error) {
        console.log(error);
      } finally {
        setShowSpin(false);
      }
    };

    fetchData();

    return () => {};
  }, [closeForm, closeEditModal]);
  return (
    <Dashboard>
      {showForm ? (
        <AddTechnician onClose={closeForm} />
      ) : (
        <>
          <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
            <div>
              <p className="poppins-medium  leading-[21px]  text-gray-700 mb-2">
                List of Technicians
              </p>
              <p className="poppins-regular text-xs text-[#B5B5C3] ">
                {technicianTableData?.length} available technicians
              </p>
            </div>
            {/*  */}
            <AddButton
              onClick={openForm}
              buttonName={"Add New Technician"}
              icon={<MdPersonAddAlt1 className="size-5" />}
            />
          </div>
          {showSpin ? (
            <div className="w-full mt-20 flex items-center justify-center">
              <Spinner className="h-12 w-12" />{" "}
            </div>
          ) : (
            <Table
              tableData={getTechnicianTableData(technicianTableData)}
              tableHeadData={technicianTableHeadData}
            />
          )}

          {/* {showModal ? <Technician edit={false} onClose={closeModal} /> : <></>} */}
          {showEditModal ? (
            <Technician edit={true} onClose={closeEditModal} />
          ) : (
            <></>
          )}
        </>
      )}
    </Dashboard>
  );
};

export default PhysicianTechnicianPage;
