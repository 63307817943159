import { Badge } from "../../components/Table"
import Edit from "../../components/buttons/Edit";

export const CollectorTestOrederTableHeadData = [
    {
        accessorKey: "no",
        header: "No",
        size: 50,
    },
    {
        accessorKey: "firstName",
        header: "Patient First Name",
        size: 150,
    },
    {
        accessorKey: "lastName",
        header: "Patient Last Name",
        size: 150,
    },
    {
        accessorKey: "email",
        header: "Email",
        size: 250,
    },
    {
        accessorKey: "status",
        header: "Status",
        size: 150,
    },
    {
        accessorKey: "action",
        header: "Action",
        size: 150,
    },
];

export const getCollectorTestOrederTableData = (dataSet) => {
    const tableData = dataSet?.map((item, idx) => {
        return {
            no: idx + 1,
            firstName: item?.first_name || "N/A",
            lastName: item?.last_name || "N/A",
            email: item?.email || "N/A",
            status: <Badge status={item?.status} />,
            action: <Edit user={item} />,
        };
    });
    return tableData;
};
